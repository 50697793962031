// Customizable Area Start
import React from "react";

import { ThemeProvider, Typography, createTheme, Button, styled, Container, Grid, Box, Input } from "@material-ui/core";
const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
    }
})


import ForgotPasswordController2, {
    Props,
    configJSON,
} from "./ForgotPasswordController2";
import { backgroundImg, featureImg, logo } from "./assets";
import { errorToast } from "./NewPassword.web";

export default class ForgotPassword extends ForgotPasswordController2 {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <ThemeProvider theme={theme}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item md={6} sm={12} style={{ ...styles.flexBox, width: "100%", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img src={logo} alt="" style={{ marginTop: 50 }} />
                        </Box>
                        <ContentBox>
                            {errorToast(this.state.error)}
                            <Title>Password Recovery</Title>
                            <Title className="sub">{configJSON.forgotPassword}</Title>
                            <TypographyContent>{configJSON.noWorries}</TypographyContent>
                            <Box>
                                <Title className="sub email">{configJSON.email}</Title>
                                <Input
                                    data-test-id="txtInputEmail"
                                    placeholder={"Your email"}
                                    fullWidth={true}
                                    value={this.state.email}
                                    onChange={(e) => { this.handleChangeEmail(e.target.value) }}
                                    style={{ ...styles.inputStyle, borderColor: this.state.invalidEmail ? configJSON.colorError : "#CBD5E1" }}
                                    disableUnderline
                                />
                                {this.state.invalidEmail && <Typography style={styles.errorText}>{configJSON.invalidEmail}</Typography>}
                            </Box>
                            <CustomButton disabled={this.state.email.length === 0 || this.state.isLoading} data-test-id="btn-send" onClick={this.sendEmail}>{configJSON.sendRecoveryLink}</CustomButton>
                            <TypographyContent component={"span"}>{configJSON.backTo}
                                <Typography onClick={this.gotoLogin} component={"span"} style={styles.login}>{configJSON.login}</Typography>
                            </TypographyContent>
                        </ContentBox>
                    </Grid>
                    <Grid item md={6} sm={12} style={{ ...styles.container }}>
                        <Box
                            sx={{
                                ...styles.flexBox,
                                flexDirection: "column",
                                width: "100%"
                            }}
                        >
                            <BackgroundImg>
                                <img style={{ maxWidth: "80%" }} src={featureImg} alt="" />
                            </BackgroundImg>

                        </Box>
                    </Grid>
                </Grid>

            </Container>
        </ThemeProvider>

    }
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    container: {
        display: "flex",
        height: "auto",
        minHeight: "100vh"
    },
    inputStyle: {
        border: "1px solid #CBD5E1",
        padding: 10,
        borderRadius: 8,
        marginTop: 10
    },
    login: {
        fontSize: 16,
        color: "#1C3485",
        fontWeight: 700,
        cursor: "pointer"
    },
    errorText: {
        color: configJSON.colorError,
        fontSize: 14,
        marginTop: 5
    },
    toastContainer: {
        backgroundColor: "#d1d7ed",
        marginTop: 10,
        position: "relative",
        borderRadius: 8
    },
    toastContent: {
        width: 5,
        height: "100%",
        background: "#1C3485",
        position: "absolute",
        borderRadius: "8px 0 0 8px"
    },
    toastText: {
        color: "#1C3485",
        fontSize: 14,
    }
}

const CustomButton = styled(Button)({
    width: "100%",
    borderRadius: 8,
    padding: "10px 16px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    color: "#FFFFFF",
    "&:disabled": {
        background: "#A9A9A9",
        color: "#ffffff",
        cursor: "not-allowed",
    },
    "@media (max-width: 576px)": {
        fontSize: 14,
    }
})

const BackgroundImg = styled(Box)({
    width: "100%", height: "100%", backgroundImage: `url(${backgroundImg})`,
    backgroundColor: "rgb(55, 107, 174)",
    backgroundRepeat: " no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
})

const ContentBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
    margin: "30px auto",
    width: "52%",
    gap: 20,
    "@media (max-width: 576px)": {
        width: "80%",
    },
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography

const Title = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32x",
    '&.sub': {
        fontSize: 18,
        lineHeight: "26px",
        '&.email': {
            fontSize: 16,
        }
    },
    "@media (max-width: 960px)": {
        fontSize: 24,
        '&.sub': {
            fontSize: 14,
            '&.email': {
                fontSize: 14,
            }
        }
    },
    "@media (max-width: 576px)": {
        fontSize: 20,
    }
})
export { BackgroundImg, ContentBox, Title, TypographyContent, styles }
// Customizable Area End
