// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    // Customizable Area Start
    password: string
    retypePassword: string
    invalidPassword: boolean
    invalidCapitalLetter: boolean,
    invalidLowercaseLetter: boolean,
    invalidNumber: boolean,
    invalidLength: boolean,
    invalidSymbol: boolean,
    visiblePassword: boolean
    isSignupSuccess: boolean,
    error: string | null
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    setNewPasswordCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            retypePassword: "",
            password: "",
            invalidPassword: false,
            invalidCapitalLetter: false,
            invalidLowercaseLetter: false,
            invalidNumber: false,
            invalidLength: false,
            invalidSymbol: false,
            visiblePassword: false,
            isSignupSuccess: false,
            error: null
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.setNewPasswordCallId) {
                if (responseJson.message) {
                    this.setState({ isSignupSuccess: true })
                } else if(responseJson.errors) {
                    this.setState({error: this.getErrorFromRes(responseJson.errors)})
                } 
            }
        }
    }

    getErrorFromRes = (errs: Array<string>) => {
        let message = '';
        errs.forEach((object: string) => {
          const error = JSON.stringify(object);
          JSON.parse(error, (key, value) => {
            if (value.length > 0) {
              if (message.length <= 0) {
                message = value;
              } else {
                message = `${message}{\n}${value}`;
              }
            }
          });
        });
        return message
      }

    gotoLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    handleChangePassword = (password: string) => {
        if (password) {
            const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            const validLength = password.length >= 8;
            const validNumber = /\d/.test(password);
            const validCapital = /[A-Z]/.test(password);
            const validLowerCase = /[a-z]/.test(password);
            const validSymbol = regex.test(password);

            this.setState({
                invalidCapitalLetter: !validCapital,
                invalidLowercaseLetter: !validLowerCase,
                invalidNumber: !validNumber,
                invalidLength: !validLength,
                invalidSymbol: !validSymbol
            });
        }
        this.setState({ password, invalidPassword: false, error: null })
    }
    handleChangeRetypePassword = (retypePassword: string) => {
        this.setState({ retypePassword, invalidPassword: false, error: null })
    }
    showHidePassword = () => {
        this.setState({ visiblePassword: !this.state.visiblePassword })
    }
    setNewPassword = () => {
        if (this.state.password !== this.state.retypePassword) {
            this.setState({ invalidPassword: true });
            return false
        }
        const body = {
            data: {
                type: "email_account",
                attributes: {
                    password: this.state.password,
                    password_confirmation: this.state.retypePassword
                },
            },
        };

        const urlSearchParams = new URLSearchParams(window.location.search)
        const token = urlSearchParams.get('token') || '';

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.setNewPasswordCallId = reqMsg.messageId;
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.setNewPasswordEndpoint}?token=${token}`
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.setNewPasswordContentType,
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.setNewPasswordMethod
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    handleToggleDialogSuccess = () => {
        this.setState({ isSignupSuccess: !this.state.isSignupSuccess })
        this.gotoLogin()
    }
    isEnableButtonSet = () => {
        const { password,
            retypePassword,
            invalidCapitalLetter,
            invalidLength,
            invalidLowercaseLetter,
            invalidNumber,
            invalidSymbol } = this.state
        return password.length > 0 
        && retypePassword.length > 0 
        && !invalidCapitalLetter 
        && !invalidLength 
        && !invalidLowercaseLetter 
        && !invalidNumber 
        && !invalidSymbol
    }
    // Customizable Area End
}
// Customizable Area End
