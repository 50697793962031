// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const exampleImg1 = require("../assets/img_example_1.png")
export const exampleImg2 = require("../assets/img_example_2.png")
export const exampleImg3 = require("../assets/img_example_3.png")
export const icUpload = require("../assets/ic-upload.svg")
export const icSortAsc = require("../assets/sort_arrow_ascending.png")
export const icSortDesc = require("../assets/sort_arrow_descending.png")
export const icSort = require("../assets/sort_arrow.png")
// Customizable Area End