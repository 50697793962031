// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Typography,
    Switch,
    Grid,
    Select,
    MenuItem,
    CircularProgress,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const theme = createTheme({
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '8px 8px 32px 8px'
            },
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            },
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiTypography: {
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            },
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
        },
        MuiSwitch: {
            root: {
                width: "auto",
                height: 'auto'
            },
            track: {
                width: 48,
                height: 28,
                borderRadius: 40
            },
            switchBase: {
                color: '#fafafa !important',
                top: 7,
                left: 7,
                '&$checked': {
                    transform: 'translateX(20px)',
                    color: "#fff",
                    '& + $track': {
                        opacity: 1,
                        backgroundColor: "#152764 !important",
                        borderColor: "#152764",
                    },
                }
            }
        }
    }
});

import NotificationSettingsController, {
    Props,
    configJSON,
} from "./NotificationSettingsController";

export default class NotificationSettings extends NotificationSettingsController {
    constructor(props: Props) {
        super(props);
    }

    SettingItem = (key: string, title: string, subTitle: string, value: boolean, onChange: () => void) => {
        return <Box key={key} style={styles.flexBox}>
            <Box style={{ marginRight: 20 }}>
                <CustomTypo className="title">{title}:</CustomTypo>
                <CustomTypo className="sub-title">{subTitle}</CustomTypo>
            </Box>
            <Switch checked={value} onChange={onChange} data-test-id={"test-" + key} />
        </Box>
    }

    SelectDay = (testId: string, value: string, handleChange: (params: string) => void) => {
        return <CustomSelect
            labelId="demo-customized-select-label"
            id={testId}
            data-test-id={testId}
            disableUnderline
            MenuProps={{
                TransitionProps: { style: { padding: "0 4px", marginTop: 20, borderRadius: 8, height: 250 } },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                getContentAnchorEl: null
            }}
            displayEmpty
            renderValue={(value: any) => { return value.length > 0 || value ? this.showDays(value) : 'Select here' }}
            value={value}
            IconComponent={KeyboardArrowDownIcon}
        >
            {([...Array(10).keys()]).map((item: any) =>
                <MenuItem data-test-id={"item-" + testId} key={item} value={this.showDays(item + 1)} onClick={() => handleChange(item + 1)}>
                    {this.showDays(item + 1)}
                </MenuItem>)}
        </CustomSelect>
    }

    ClientSettingSite = () => {
        const { capNoti1, capNoti2, capNoti3 } = this.state
        return <Box className="content-container">
            <Grid container>
                <Grid item md={9} xs={12}>
                    <CustomTypo className="title" style={{ marginBottom: 20 }}>{configJSON.incompleteCapWillbeNotifiedToGeneralManager}</CustomTypo>
                    <CustomTypo className="label">{configJSON.enterDaysHere}</CustomTypo>
                    {this.SelectDay("select-email-day", this.state.emailNotiDays, this.onChangeEmailNotidays)}
                </Grid>
                <Grid item md={3} className="space"></Grid>
            </Grid>
            <BreakLine />
            <Grid container>
                <Grid item md={9} xs={12}>
                    {this.SettingItem('cap-noti-1',
                        configJSON.capNoti1, "Duis aute irure dolor in reprehenderit",
                        capNoti1, this.onChangeCapNotif1)}
                    <CustomTypo className="title" style={{ marginBottom: 20 }}>{configJSON.incompleteCapWillbeNotifiedToAreaManager}</CustomTypo>
                    <CustomTypo className="label">{configJSON.enterDaysHere}</CustomTypo>
                    {this.SelectDay("select-cap1-day", this.state.cap1NotiDays, this.onChangeCap1Notidays)}
                </Grid>
                <Grid item md={3} className="space"></Grid>
            </Grid>
            <BreakLine />
            <Grid container>
                <Grid item md={9} xs={12}>
                    {this.SettingItem('cap-noti-2', configJSON.capNoti2, "Duis aute irure dolor in reprehenderit",
                        capNoti2, this.onChangeCapNotif2
                    )}
                    <CustomTypo className="title" style={{ marginBottom: 20 }}>{configJSON.incompleteCapWillbeNotifiedToRegionalManager}</CustomTypo>
                    <CustomTypo className="label">{configJSON.enterDaysHere}</CustomTypo>
                    {this.SelectDay("select-cap2-day", this.state.cap2NotiDays, this.onChangeCap2Notidays)}
                </Grid>
                <Grid item md={3} className="space"></Grid>
            </Grid>
            <BreakLine />
            <Grid container>
                <Grid item md={9} xs={12}>
                    {this.SettingItem('cap-noti-3', configJSON.capNoti3, "Duis aute irure dolor in reprehenderit",
                        capNoti3, this.onChangeCapNotif3
                    )}
                    <CustomTypo className="title" style={{ marginBottom: 20 }}>{configJSON.incompleteCapWillbeNotifiedToSuperUser}</CustomTypo>
                    <CustomTypo className="label">{configJSON.enterDaysHere}</CustomTypo>
                    {this.SelectDay("select-cap3-day", this.state.cap3NotiDays, this.onChangeCap3Notidays)}
                </Grid>
                <Grid item md={3} className="space"></Grid>
            </Grid>
        </Box>

    }

    CommonSettings = () => {
        const { draftNoti } = this.state
        return <Box className="content-container">
            <Typography className="heading">{configJSON.notificationSettings}</Typography>
            <Grid container>
                <Grid item md={9} xs={12}>
                    {this.SettingItem('send-draft',
                        configJSON.sendDraft, "Send draft audit to client(GM) after auditor completion/ before TL submission",
                        draftNoti,
                        this.onChangeDraftNoti
                    )}
                </Grid>
                <Grid item md={3} className="space"></Grid>

            </Grid>
        </Box>
    }

    CheckPermissionUI = () => {
        const { role } = this.state.user
        if (role === 'All Access User') return this.CommonSettings()
        if (role === 'Super User') return this.ClientSettingSite()
        return <Typography style={{textAlign: 'center'}}>{configJSON.noPermission}</Typography>
    }

    render() {
        return (
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <NotificationContainer maxWidth={false}>
                            {this.state.isLoading ? <CircularProgress style={{ margin: 30 }} /> : this.CheckPermissionUI()}
                    </NotificationContainer>
                </ThemeProvider>
            </Header>
        );
    }
}

const styles = {
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30
    },
}

const CustomSelect = styled(Select)({
    border: '1px solid #CBD5E1',
    padding: '10px 8px',
    borderRadius: 8,
    minWidth: "50%",
    "@media (max-width: 600px)": {
        width: '100%',
        minWidth: '100%'
    }
})

const CustomTypo = styled(Typography)({
    color: '#0F172A',

    "&.title": {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '28px'
    },
    "&.sub-title": {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '30px'
    },
    '&.label': {
        color: '#334155',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: "22px",
        marginBottom: 10
    },
    "@media (max-width: 600px)": {
        "&.title": {
            fontSize: 18,
            lineHeight: '26px'
        },
        "&.sub-title": {
            fontSize: 16,
            lineHeight: '28px'
        },
    },
    "@media (max-width: 475px)": {
        "&.title": {
            fontSize: 16,
            lineHeight: '22px'
        },
        "&.sub-title": {
            fontSize: 14,
            lineHeight: '24px'
        },
    }
})

const BreakLine = styled(Box)({
    width: '100%',
    height: 1,
    background: "#D9D9D9"
})

const NotificationContainer = styled(Container)({
    background: "#F1F5F9",
    overflowY: 'auto',
    height: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    "& .content-container": {
        background: '#FFFFFF',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        border: "1px solid #CBD5E1",
        minHeight: '100%',
        height: 'auto',
        padding: "25px 20px",
        boxSizing: 'border-box',
    },
    "& .heading": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 700,
        color: '#0F172A',
    },
    "@media (max-width: 960px)": {
        '& .space': {
            display: 'none'
        }
    },
    "@media (max-width: 600px)": {
        "& .content-container": {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    "@media (max-width: 475px)": {
        "& .heading": {
            fontSize: 20,
        }
    }
})
// Customizable Area End
