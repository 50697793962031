// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, CircularProgress } from "@material-ui/core";
import Header from "../../navigationmenu/src/Header.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
    }
})
import StatusTrackerController, {
    Props
} from "./StatusTrackerController";
import { configJSON } from "./TaskController";

// Customizable Area End

export default class StatusTracker extends StatusTrackerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    NoAccessUI = () => {
        return <Typography style={{ marginTop: 30, textAlign: 'center', width: "100%" }}>{configJSON.noAccess}</Typography>
    }

    CheckPermissionUI = () => {
        const {group} = this.state.userRole;
        const underReviewOrInProgress =  configJSON.underReview
        return group === "evermore" ?
            <ContentBox>
                <TitleGroup>
                    <Title className="title">{configJSON.auditInfo}: #{this.state.auditId}</Title>
                    <GroupTab>
                        <TabButton data-test-id={"section-audit-info"}
                            onClick={() => this.onChangeTab('audit-info')}
                            className={this.isActiveTab('audit-info')}>
                            <Typography className="section">{configJSON.auditInfo}</Typography>
                        </TabButton>
                        <TabButton data-test-id={"section-status-tracker"}
                            onClick={() => this.onChangeTab('status-tracker')}
                            className={this.isActiveTab('status-tracker')}>
                            <Typography className="section">{configJSON.statusTracker}</Typography>
                        </TabButton>
                        <TabButton data-test-id={"section-cap"}
                            onClick={() => this.onChangeTab('cap')}
                            className={this.isActiveTab('cap')}>
                            <Typography className="section">{configJSON.CAP}</Typography>
                        </TabButton>
                    </GroupTab>
                </TitleGroup>
                {this.state.isLoading ?
                    <CircularProgress /> :
                    <React.Fragment>
                        <StatusBox>
                            {this.StatusItem('1', configJSON.assigned, this.state.statusData.audit === 'assigned')}
                            {this.StatusItem('2', underReviewOrInProgress, this.state.statusData.audit === 'under_review')}
                            {this.StatusItem('3', configJSON.completed, this.state.statusData.audit === 'completed')}
                        </StatusBox>
                        <Title>{configJSON.capStatus}</Title>
                        <StatusBox>
                            {this.StatusItem('1', configJSON.assigned, this.state.statusData.corrective_action_plan === 'assigned')}
                            {this.StatusItem('2', configJSON.completed, this.state.statusData.corrective_action_plan === 'completed')}
                        </StatusBox>
                    </React.Fragment>}

            </ContentBox>
            : this.NoAccessUI()
    }

    StatusItem = (number: string, typo: string, isActive: boolean) => {
        return <StatusItem className={isActive ? 'active' : ''}>
            {isActive ?
                <CheckCircleIcon style={{ color: '#34D399', width: 32, height: 32 }} /> :
                <Number>
                    <Typography>{number}</Typography>
                </Number>}
            <Typography>{typo}</Typography>
        </StatusItem>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <CustomContainer maxWidth={false}>
                        {this.state.isPageLoading ? <CircularProgress style={{margin: 30}} /> : this.CheckPermissionUI()}
                    </CustomContainer>
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StatusItem = styled(Box)({
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    '& p': {
        color: '#64748B',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px"
    },
    '&.active': {
        '& p': {
            color: '#07172A'
        }
    }
})
const Number = styled(Box)({
    borderRadius: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    backgroundColor: '#94A3B8',
    '& p': {
        color: '#FFFFFF'
    }
})
const StatusBox = styled(Box)({
    padding: 20,
    border: '1px dashed #9747FF',
    width: 'fit-content',
    borderRadius: 8,
    display: 'flex',
    gap: 20,
    maxWidth: '100%',
    flexWrap: 'wrap',
    "@media (max-width: 652px)": {
        width: '100%',
        boxSizing: 'border-box',
        flexDirection: 'column'
    },
})
const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    flexDirection: 'column',
})
const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#0F172A',
    textTransform: 'capitalize'
})
const ContentBox = styled(Box)({
    width: "100%",
    display: "flex",
    gap: 20,
    position: 'relative',
    borderRadius: 8,
    flexDirection: "column",
    margin: "30px",
    padding: 30,
    border: '1px solid #CBD5E1',
    minHeight: 'calc(100vh - 205px)',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    "@media (max-width: 576px)": {
        padding: "20px 10px",
        margin: 20
    }
})
const CustomContainer = styled(Container)({
    minHeight: "calc(100vh - 80px)",
    height: "auto",
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    display: 'flex'
})
const GroupTab = styled(Box)({
    boxShadow: "0px 4px 4px 0px #00000040",
    width: "fit-content",
    maxWidth: '100%',
    gap: 10,
    borderRadius: 50,
    padding: 5,
    display: 'flex',
    marginBottom: 20,
    "@media (max-width: 576px)": {
        flexWrap: 'wrap',
        boxShadow: 'none'
    }
})
const TabButton = styled(Box)({
    border: "1px solid black",
    padding: "10px 12px",
    color: "#647488",
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '& .section': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        textTransform: 'capitalize'
    },
    "&.active": {
        backgroundColor: "#A0A2FF",
        color: "#152764",
        border: "1px solid #A0A2FF"
    },
    "@media (max-width: 576px)": {
        '& .section': {
            fontSize: 14,
        }
    }
})
// Customizable Area End
