// Customizable Area Start
import React, { CSSProperties } from "react";

import {
    ThemeProvider,
    Typography,
    createTheme,
    Button,
    styled,
    Container,
    Grid,
    Box,
    Input,
    InputAdornment,
    IconButton,
    Dialog,
    DialogContent
} from "@material-ui/core";
import VisibilityOff from "@material-ui/icons/VisibilityOffOutlined";
import Visibility from "@material-ui/icons/VisibilityOutlined";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative"
            }
        }
    }
})


import NewPasswordController, {
    Props,
    configJSON,
} from "./NewPasswordController";
import { backgroundImg, featureImg, imgInvalid, imgNeutral, imgValid, logo } from "./assets";

export default class NewPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
    }

    getVisibilityImg = (show: boolean) => {
        return show ? <VisibilityOff /> : <Visibility />
    }

    getValidationImg = (type: boolean, password: string) => {
        if (password.length === 0) return imgNeutral;
        return type ? imgValid : imgInvalid
    }

    DialogSuccess = () => {
        return <Dialog fullWidth open={this.state.isSignupSuccess} onClose={this.handleToggleDialogSuccess}>
            <DialogContent dividers>
                <CloseIcon onClick={this.handleToggleDialogSuccess} style={styles.iconClose as CSSProperties} />
                <Box style={{ ...styles.flexBox, flexDirection: "column", marginTop: 20, marginBottom: 30 }}>
                    <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
                        {configJSON.successMsg}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }

    PasswordShowHideIcon = () => {
        return <InputAdornment position="end">
            <IconButton
                aria-label="toggle password visibility"
                data-test-id={"btn-show-hide-password"}
                onClick={this.showHidePassword}
                edge="end"
            >
                {this.getVisibilityImg(
                    this.state.visiblePassword
                )}
            </IconButton>
        </InputAdornment>
    }

    InvalidPassword = () => {
        return this.state.invalidPassword && <Typography style={styles.errorText}>Passwords do not match</Typography>
    }

    PasswordValidation = () => {
        return <Box>
            <TypographyContent style={{ marginBottom: 8 }}>Your password must contain</TypographyContent>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImg(!this.state.invalidCapitalLetter, this.state.password)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.capitalLetter}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImg(!this.state.invalidLowercaseLetter, this.state.password)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.invalidLowercaseLetter}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImg(!this.state.invalidNumber, this.state.password)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.invalidNumber}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImg(!this.state.invalidLength, this.state.password)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.invalidLength}</Typography>
            </PasswordValidationItem>
            <PasswordValidationItem>
                <img
                    style={styles.icon}
                    src={this.getValidationImg(!this.state.invalidSymbol, this.state.password)}
                    alt="icon"
                />
                <Typography className="text">{configJSON.oneSym}</Typography>
            </PasswordValidationItem>
        </Box>
    }

    render() {
        return <ThemeProvider theme={theme}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item md={6} sm={12} style={{ ...styles.flexBox, width: "100%", flexDirection: "column" }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img src={logo} alt="" style={{ marginTop: 50 }} />
                        </Box>
                        <ContentBox>
                            {errorToast(this.state.error)}
                            <Title>{configJSON.newPassword}</Title>
                            <TypographyContent>{configJSON.enterNewPassword}</TypographyContent>
                            <Box>
                                <Title className="sub password">{configJSON.newPassword}</Title>
                                <Input
                                    data-test-id="txtInputPassword"
                                    placeholder={"Your new password"}
                                    type={this.state.visiblePassword ? "text" : "password"}
                                    fullWidth={true}
                                    style={{ ...styles.inputStyle, borderColor: this.state.invalidPassword ? configJSON.colorError : "#CBD5E1" }}
                                    value={this.state.password}
                                    onChange={(event) => { this.handleChangePassword(event.target.value) }}
                                    disableUnderline
                                    endAdornment={this.PasswordShowHideIcon()}
                                />
                                {this.InvalidPassword()}
                            </Box>
                            <Box>
                                <Title className="sub password">{configJSON.retypePassword}</Title>
                                <Input
                                    data-test-id="txtInputRetypePassword"
                                    placeholder={"Confirm new password"}
                                    type={this.state.visiblePassword ? "text" : "password"}
                                    fullWidth={true}
                                    value={this.state.retypePassword}
                                    onChange={(event) => { this.handleChangeRetypePassword(event.target.value) }}
                                    style={{ ...styles.inputStyle, borderColor: this.state.invalidPassword ? configJSON.colorError : "#CBD5E1" }}
                                    endAdornment={this.PasswordShowHideIcon()}
                                    disableUnderline
                                />
                                {this.InvalidPassword()}

                            </Box>
                            {this.PasswordValidation()}
                            <CustomButton data-test-id="btn-send"
                                disabled={!this.isEnableButtonSet()}
                                onClick={this.setNewPassword}>
                                {configJSON.setNewPassword}</CustomButton>
                            <TypographyContent component={"span"}>{configJSON.backTo}
                                <Typography data-test-id="btn-login" onClick={this.gotoLogin} component={"span"} style={styles.login}>{configJSON.login}</Typography>
                            </TypographyContent>
                        </ContentBox>
                    </Grid>
                    <Grid item style={{ ...styles.container }} md={6} sm={12} >
                        <Box
                            sx={{
                                ...styles.flexBox,
                                width: "100%",
                                flexDirection: "column",
                            }}
                        >
                            <BackgroundImg>
                                <img src={featureImg} style={{ maxWidth: "80%" }} alt="" />
                            </BackgroundImg>
                        </Box>
                    </Grid>
                </Grid>
                {this.DialogSuccess()}
            </Container>
        </ThemeProvider>

    }
}

export const errorToast = (error: string | null) => {
    return error && 
    <Box sx={{ width: "100%" }} style={styles.errorContainer as React.CSSProperties}>
        <Box style={styles.errorContent as React.CSSProperties}></Box>
        <Typography style={{ ...styles.toastText, padding: "12px 16px" }}>
            {error}
        </Typography>
    </Box>
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    errorContainer: {
        marginTop: 10,
        position: "relative",
        borderRadius: 8,
        backgroundColor: "#FEE2E2",
    },
    errorContent: {
        width: 5,
        height: "100%",
        background: "#DC2626",
        position: "absolute",
        borderRadius: "8px 0 0 8px"
    },
    toastText: {
        color: "#DC2626",
        fontSize: 14,
    },
    inputStyle: {
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        marginTop: 10,
        padding: 10,
    },
    container: {
        display: "flex",
        minHeight: "100vh",
        height: "auto",
    },
    login: {
        fontSize: 16,
        fontWeight: 700,
        cursor: "pointer",
        color: "#1C3485",
    },
    errorText: {
        fontSize: 14,
        color: configJSON.colorError,
        marginTop: 5
    },
    icon: {
        width: "12px",
        height: "12px",
        marginRight: "6px",
    },
    successText: {
        fontWeight: 700,
        fontSize: 24,
        lineHeight: "32px",
        color: "#0F172A"
    },
    iconClose: {
        position: "absolute",
        top: 10,
        right: 10,
        color: "#334155",
        cursor: "pointer"
    }
}

const CustomButton = styled(Button)({
    width: "100%",
    padding: 16,
    fontFamily: "'Montserrat', sans-serif",
    borderRadius: 8,
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 700,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    color: "#FFFFFF",
    "&:disabled": {
        background: "#A9A9A9",
        cursor: "not-allowed",
        color: "#FFFFFF",
    },
    "@media (max-width: 576px)": {
        fontSize: 14,
    }
})

const BackgroundImg = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", height: "100%", backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundColor: "rgb(55, 107, 174)",
    backgroundRepeat: " no-repeat",
})

const ContentBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "30px auto",
    width: "52%",
    padding: "10px 0px",
    gap: 20,
    "@media (max-width: 576px)": {
        width: "80%",
    },
})

const PasswordValidationItem = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: 8,
    "& .text": {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "20px"
    }
})

const Title = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 24,
    lineHeight: "32x",
    fontWeight: 700,
    '&.sub': {
        lineHeight: "26px",
        fontSize: 18,
        '&.password': {
            fontSize: 16,
        }
    },
    "@media (max-width: 960px)": {
        fontSize: 24,
        '&.sub': {
            fontSize: 14,
            '&.password': {
                fontSize: 14,
            }
        }
    },
    "@media (max-width: 576px)": {
        fontSize: 20,
    }
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography
// Customizable Area End
