// Customizable Area Start
import React from "react";

import { ThemeProvider, Typography, createTheme, Container, Grid, Box, ButtonBase } from "@material-ui/core";
const newtheme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
    }
})


import ForgotPasswordConfirmationController, {
    Props,
    configJSON,
} from "./ForgotPasswordConfirmationController";
import {  featureImg, logo } from "./assets";
import { BackgroundImg, ContentBox, Title, TypographyContent, styles } from "./ForgotPassword.web";

export default class ForgotPasswordConfirmation extends ForgotPasswordConfirmationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <ThemeProvider theme={newtheme}>
            <Container maxWidth={false}>
                <Grid container>
                    <Grid item sm={12} md={6} style={{ ...styles.flexBox, flexDirection: "column", width: "100%"  }}>
                        <Box sx={{ ...styles.flexBox, width: "100%", justifyContent: "center" }}>
                            <img style={{ marginTop: 50 }} src={logo} alt="" />
                        </Box>
                        <ContentBox>
                            {this.state.isSent && 
                            <Box sx={{ width: "100%" }} style={styles.toastContainer as React.CSSProperties}>
                                <Box style={styles.toastContent as React.CSSProperties}></Box>
                                <Typography style={{ ...styles.toastText, padding: "12px 16px" }}>
                                    Resend email successfully
                                </Typography>
                            </Box>}
                            <Title>Password Recovery</Title>
                            <Title className="sub">{configJSON.checkYourMail}</Title>
                            <TypographyContent>{configJSON.weHaveSent} {this.state.email}</TypographyContent>
                            <Box style={{ ...styles.flexBox, justifyContent: "space-between" }}>
                                <ButtonBase data-test-id="btn-resend" onClick={this.resend} disabled={this.state.isLoading}>
                                    <Typography style={{...styles.login, color: this.state.isLoading? '#d1d7ed' : '#1C3485'}}>{configJSON.resendEmail}</Typography>
                                </ButtonBase>
                                
                                <TypographyContent component={"span"}>{configJSON.backTo}
                                    <Typography data-test-id="btn-login" onClick={this.gotoLogin} component={"span"} style={styles.login}>{configJSON.login}</Typography>
                                </TypographyContent>
                            </Box>
                        </ContentBox>
                    </Grid>
                    <Grid item sm={12} md={6} style={{ ...styles.container }}>
                        <Box
                            sx={{
                                ...styles.flexBox,
                                width: "100%",
                                flexDirection: "column",
                            }}
                        >
                            <BackgroundImg>
                                <img src={featureImg} style={{ maxWidth: "80%" }} alt="" />
                            </BackgroundImg>

                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>

    }
}
// Customizable Area End
