import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  created_at: string;
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isOpen: boolean
  onClose: () => void
  type: "termsConds" | "privacyPolicy"
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsConds: ITermsConds | null;
  isLoading: boolean
  isAdminUser: boolean;
  isTermsCondsAccepted: boolean;
  termsCondsList: ITermsConds[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getPrivacyPolicyCallId: string = "";
  getAccountGroupsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      termsConds: null,
      isLoading: true,
      isAdminUser: false,
      isTermsCondsAccepted: false,
      termsCondsList: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(this.props.type==='privacyPolicy') this.getPrivacyAndPolicy()
    else this.getTermsConds()
  }

  handleCheckBoxChange = () => {
    this.setState({isAdminUser: true})
  }

  navigateToTermsCondsDetail = (id: string) => {
    this.props.navigation.navigate("TermsConditionsDetail", {id})
  }
  navigateToTermsCondsEdit = () => {
    this.props.navigation.navigate("TermsConditionsUsers")
  }

  apiCall = async (data: any) => {
    const {endpoint, method, contentType} = data
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getTermsConds = async () => {
    this.getTermsCondsCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endpoint: configJSON.getTermsCondsApiEndPoint
    })
  };

  getPrivacyAndPolicy = async () => {
    this.getPrivacyPolicyCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      endpoint: configJSON.getPrivacyPolicyApiEndPoint,
      method: configJSON.getApiMethod,
    })
  };

  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if(apiRequestCallId === this.getTermsCondsCallId){
      this.setState({
        termsConds: responseJson?.data?.[0]?.attributes,
        isLoading: false
      })
    }

    if(apiRequestCallId === this.getPrivacyPolicyCallId){
      this.setState({
        termsConds: responseJson?.data?.[0],
        isLoading: false
      })
    }
    // Customizable Area End
  }
}
