Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.oneSym = 'At least one special symbol';
exports.capitalLetter = 'At least one capital letter';
exports.lowercaseLetter = 'At least one lowercase letter';
exports.oneNumber = 'At least one number';
exports.signUp = "Sign up";
exports.labelFullName = 'Full Name';
exports.labelInvalidName = 'Invalid Name';
exports.labelInvalidEmail = 'Invalid Email';
exports.labelTypePassword = 'Type Password';
exports.labelPasswordsNotMatch = 'Passwords do not match';
exports.labelConfirmPassword = 'Confirm Password';
exports.minimumChar = 'Minimum character length is 8 characters';
exports.invalidEmailAddres = 'Invalid email address';
exports.haveAccount = 'Already have an account! ';
exports.logIn = 'Login';
exports.agreeText = "I agree our "
exports.termAndCondition = "Terms and Conditions"
exports.policyAndPrivacy = "Privacy Policy"
exports.capitalLetter = 'At least one capital letter';
exports.pleaseFill = "Please fill out all required fields before submitting the form"
exports.successMessage = "Your account has been successfully created! Please proceed to log in with your new credentials."
exports.createAccountSuccess = "Account Activation"
exports.checkYourEmail = "Check your email"
exports.haveSent = "We have sent an activate link to "
exports.resend = "Resend e-mail"
exports.backTo = "Back to "
exports.resendSuccess = "Resend email successfully!"
// Customizable Area End
