// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, isEmail } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    openContactUs: boolean
    toggleContactUs: () => void
}

interface S {
    name: string
    email: string
    message: string
    isInvalidEmail: boolean
    subject: string
    isSuccess: boolean
}

interface SS {
    navigation: any;
}

export default class NewPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    submitCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


        this.state = {
            email: '',
            message: '',
            name: '',
            isInvalidEmail: false,
            subject: "",
            isSuccess: false
        };
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(apiRequestCallId === this.submitCallId) {
                if(responseJson.data) {
                    this.setState({isSuccess: true})
                }
                else if (responseJson.errors) {
                    this.getErrorFromRes(responseJson.errors)
                }
            }
        }
    }
    getErrorFromRes = (errs: Array<string>) => {
          const list: { [key: string]: string } = {};
          Array.isArray(errs) && errs.forEach((error: Object) => {
            list[Object.keys(error)[0]] = Object.values(error)[0]
          });
          if (list['token']) this.gotoLogin()
      }

    gotoLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    onChangeEmail = (email: string) => {
        this.setState({email, isInvalidEmail: false})
    }

    onChangeName = (name: string) => {
        this.setState({name})
    }

    onChangeMessage = (message: string) => {
        this.setState({message})
    }

    onChangeSubject = (subject: string) => {
        this.setState({subject})
    }

    apiCall = async (params: {body?: object, endpoint: string, method: string}) => {
        const token = await getStorageData('authToken')
        const {body, endpoint, method} = params
        const reqMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": 'application/json',
            token
          })
        );
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body && reqMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    isDisableButton = () => {
        const {email, name, message} = this.state 
        return email.length === 0 || name.length === 0 || message.length === 0
    }

    handleSubmit = async () => {
        const {email, name, message, subject} = this.state 
        const body = {
            data: {
                email,
                full_name: name, 
                description: message,
                subject
            }
        }

        const isInvalidEmail = !isEmail("", email).status
        if(isInvalidEmail) {
            this.setState({isInvalidEmail})
            return;
        }
        this.submitCallId = await this.apiCall({
            method: configJSON.methodPost,
            body,
            endpoint: configJSON.submitContactUsEndpoint
        })
    }
}
// Customizable Area End
