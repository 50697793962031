Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.dashboard = "Dashboard";
exports.auditorAssignments = "Auditor Assignments"
exports.reporting = "Reporting Analytics"
exports.settings = "Settings";
exports.logout = "Logout";
exports.auditAllocator = "Auditor Allocator";
exports.auditList = "Audit List"
exports.notificationSettings = "Notification Settings"
exports.accountSettings = "Account Settings"
exports.changePassword = "Change Password"
exports.privacy = "Privacy Policy"
exports.terms = "Terms & Conditions"
exports.confirmLogoutMessage = "Are you sure you want to logout of your account?"
exports.cancel = "Cancel"
exports.logout = 'Log out'
exports.methodGet = "GET"
exports.getUserInfoEndpoint = "account_block/accounts/show"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.contactUs = "Contact Us"
// Customizable Area End