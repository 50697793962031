// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    toggleOpenDrawer: () => void
    isOpenDrawer: boolean
    userInfo: {id: string, fullName: string, role: string}
}

interface S {
    // Customizable Area Start
    activeTab: number,
    openAuditor: boolean,
    activeSubAuditor: 'audit-allocator' | 'audit-list' | '',
    isOpenDrawer: boolean
    activeSubSetting: SubSettingType,
    isOpenConfirmDialog: boolean
    role: string
    user: {id: string, role: string, group: 'evermore' | 'client' | ''}
    openContactUs: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
export type SubSettingType = 'notification' | 'account' | 'privacy' | 'terms' | 'change-password'
// Customizable Area End

export default class HeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserRoleId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            activeTab: 0,
            openAuditor: false,
            activeSubAuditor: '',
            isOpenDrawer: true, 
            activeSubSetting: 'account',
            isOpenConfirmDialog: false, 
            role: '',
            user: {
                id: '',
                role: '',
                group: ''
            },
            openContactUs: false
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        this.onChangeActiveTab()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              if(apiCallId === this.getUserRoleId) {
                if(responseJson.meta) {
                    const role = responseJson.meta.role?.toLowerCase();
                    const evermore = ['all access user', 'account manager', 'team leader', 'auditor']
                    const group = evermore.includes(role) ? 'evermore' : 'client'
                    this.setState({user: {...responseJson.meta, group}})
                }
              }     
            
        }
    }

    checkActiveTab = (path: string) => {
        const splitPath = path.split('/')

        if(path === '/dashboard') return 0;
        if(splitPath.includes('audit-allocator') || splitPath.includes('audit-list')) return 1;
        if(path === '/reporting') return 2;
        if(splitPath.includes('settings')) return 3;
        return 5;
    }

    onChangeActiveTab = () => {
        let openAuditor = false;
        let activeSubSetting: SubSettingType = "account"
        const pathname = window.location.pathname;
        const tab = this.checkActiveTab(pathname)
        const splitPath = pathname.split('/')
        let {activeSubAuditor} = this.state

        if (tab === 1) {
            openAuditor = true
            activeSubAuditor = splitPath.includes('audit-allocator') ? 'audit-allocator' : 'audit-list'
        }
        if(splitPath.includes('notification')) activeSubSetting = "notification"
        if(splitPath.includes('account')) activeSubSetting = "account"
        if(splitPath.includes('privacy-policy')) activeSubSetting = "privacy"
        if(splitPath.includes('terms-condition')) activeSubSetting = "terms"
        if(splitPath.includes('change-password')) activeSubSetting = "change-password"

        this.setState({activeTab: tab, openAuditor, activeSubSetting, activeSubAuditor})
    }

    gotoDashboard = () => {
        this.props.navigation.navigate("DashBoard")
    }
    openAuditor = () => {
        this.setState({openAuditor: !this.state.openAuditor})
    }
    gotoAuditor = () => {
        this.props.navigation.navigate("AuditAllocator")
    }
    gotoAuditList = () => {
        this.props.navigation.navigate("AuditList")
    }
    gotoAuditAllocator = () => {
        this.props.navigation.navigate("AuditAllocator")
    }
    gotoReporting = () => {
        this.props.navigation.navigate("Reporting")
    }
    gotoSettings = () => {
        this.props.navigation.navigate("Settings")
    }
    gotoNotification = () => {
        this.props.navigation.navigate("Notification")
    }
    gotoPrivacy = () => {
        this.props.navigation.navigate("PrivacyPolicy")
    }
    gotoTermsCondition = () => {
        this.props.navigation.navigate("TermsConditionSettings")
    }
    gotoChangePassword = () => {
        this.props.navigation.navigate("ChangePassword")
    }
    onChangeSubAuditor = (subAuditor: 'audit-allocator' | 'audit-list') => {
        this.setState({activeSubAuditor: subAuditor})
        if(subAuditor === 'audit-list') {
            this.props.navigation.navigate('AuditList');
        }

    }
    checkActiveSubmenu = (activeMenu: any, subMenu: any, subchild?: any) => {
        return activeMenu === subMenu || activeMenu === subchild ? 'active' : ''
    }
    onToggleConfirmDialog = () => {
        this.setState({isOpenConfirmDialog: !this.state.isOpenConfirmDialog})
    }
    onLogout = async () => {
        await removeStorageData("authToken");
        await removeStorageData("role")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    toggleContactUs = () => {
        this.setState({openContactUs: !this.state.openContactUs})
    }
    // Customizable Area End
}
// Customizable Area End
