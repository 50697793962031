// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    id: string
    navigation: any;
}

interface S {
    emailNotiDays: string
    cap1NotiDays: string
    cap2NotiDays: string
    cap3NotiDays: string
    user: {id: string, role: string, group: 'evermore' | 'client' | ''}
    isLoading: boolean
    capNoti1: boolean
    capNoti2: boolean
    capNoti3: boolean
    draftNoti: boolean
    
}

interface SS {
    navigation: any;
}

export default class NotificationSettingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    getUserRoleCallId: string = ""
    getNotificationCallId: string=""
    updateNotificationCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            emailNotiDays: '',
            cap1NotiDays: '',
            cap2NotiDays: '',
            cap3NotiDays: '',
            user: {
                id: "", 
                role: "", 
                group: ""
            },
            isLoading: true,
            capNoti1: false,
            capNoti2: false,
            capNoti3: false,
            draftNoti: true
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserRole()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              if(apiCallId === this.getUserRoleCallId){
                this.handleUserRoleResponse(responseJson)
              } 
              if(apiCallId === this.getNotificationCallId) {
                this.handleNotificationResponse(responseJson)
              }     
              if(apiCallId === this.updateNotificationCallId) {
                if(responseJson.errors) {
                    this.handleErrors(responseJson.errors)
                }
              }
        }
    }

    handleNotificationResponse = (responseJson: any) => {
        if(responseJson.data){
            const {
                email_notification,
                draft_audit,
                cap_notification_1,
                cap_notification_2,
                cap_notification_3,
                notify_gm_after,
                notify_am_after,
                notify_rm_after,
                notify_su_after } = responseJson.data
            this.setState({
               isLoading: false,
               emailNotiDays: notify_gm_after ?? 0, 
               draftNoti: draft_audit,
               cap1NotiDays: notify_am_after ?? 0, 
               cap2NotiDays: notify_rm_after ?? 0,
               cap3NotiDays: notify_su_after ?? 0,
               capNoti1: cap_notification_1,
               capNoti2: cap_notification_2,
               capNoti3: cap_notification_3
            })
        } else if(responseJson.errors) {
            this.handleErrors(responseJson.errors)
        }
    }

    handleUserRoleResponse = async (responseJson: any) => {
        if(responseJson.meta){
            const evermoreGroup = ['all access user', 'account manager', 'team leader', 'auditor']
            const role = responseJson.meta.role?.toLowerCase()
            const group = evermoreGroup.includes(role) ? 'evermore' : 'client'
            this.setState({
                user: {...responseJson.meta, group},
                isLoading: role === 'all access user' || role === 'super user'
            })
            if(role === 'all access user' || role === 'super user') await this.getNotification()
        } else if(responseJson.errors) {
            this.handleErrors(responseJson.errors)
        }
    }

    handleErrors = (errors: Array<object>) => {
        const errList: { [key: string]: string } = {}; errors.forEach((error: Object) => {
            errList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if (errList['token']) this.gotoLogin()
    }

    gotoLogin = async () => {
        await setStorageData("name", "Notification")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    apiCallReq = async (params: {endpoint: string, body?: object, method: string}) => {
        const token = await getStorageData("authToken");
        const {endpoint, body, method} = params
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    updateNotification = async () => {
        let body = {}
        const {emailNotiDays,
            draftNoti, cap1NotiDays, 
            cap2NotiDays, cap3NotiDays, 
            capNoti1, capNoti2, capNoti3} = this.state
        const {role, group} = this.state.user
        if(group === 'client') {
            body = {
                cap_notification_1: capNoti1,
                cap_notification_2: capNoti2,
                cap_notification_3: capNoti3,
                notify_gm_after: emailNotiDays,
                notify_am_after: cap1NotiDays,
                notify_rm_after: cap2NotiDays,
                notify_su_after: cap3NotiDays
            }
        }
        else if(role === 'All Access User') {
            body = {
                draft_audit: draftNoti
            }
        } 

        this.updateNotificationCallId = await this.apiCallReq({
            endpoint: configJSON.updateNotificationEndpoint, 
            method: configJSON.PATCH,
            body: {
                notifications_setting: body
            }
        })
    }

    getUserRole = async () => {
        this.getUserRoleCallId = await this.apiCallReq({
            endpoint: configJSON.getUserRoleEndpoint,
            method: configJSON.GET 
        });
    }

    getNotification = async () => {
        this.getNotificationCallId = await this.apiCallReq({
            endpoint: configJSON.getNotificationEndpoint,
            method: configJSON.GET
        })
    }

    onChangeCapNotif1 = () => {
        this.setState({capNoti1: !this.state.capNoti1}, () => {
            this.updateNotification()
        })
    }
    onChangeCapNotif2 = () => {
        this.setState({capNoti2: !this.state.capNoti2}, () => {
            this.updateNotification()
        })
    }
    onChangeCapNotif3 = () => {
        this.setState({capNoti3: !this.state.capNoti3}, () => {
            this.updateNotification()
        })
    }
    onChangeDraftNoti = () => {
        this.setState({draftNoti: !this.state.draftNoti}, () => {
            this.updateNotification()
        })
    }

    onChangeEmailNotidays = (value: string) => {
        this.setState({emailNotiDays: value}, () => {
            this.updateNotification()
        })
    }

    onChangeCap1Notidays = (value: string) => {
        this.setState({cap1NotiDays: value}, () => {
            this.updateNotification()
        })
    }
    onChangeCap2Notidays = (value: string) => {
        this.setState({cap2NotiDays: value}, () => {
            this.updateNotification()
        })
    }
    onChangeCap3Notidays = (value: string) => {
        this.setState({cap3NotiDays: value}, () => {
            this.updateNotification()
        })
    }

    showDays = (item: number) => {
        return item > 1 ? item + ' days' : item + ' day'
    }
}
// Customizable Area End
