Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.foodSafety = 'Food Safety Inspection Report'
exports.critical = "Critical"
exports.nonCritical = "Non-critical"
exports.safetyCulture = "Safety Culture"
exports.callSupervior = "Call supervisor"
exports.uploadPhoto = "Upload Photo"
exports.takePhoto = "Take Photo"
exports.cancel = "Cancel"
exports.save = "Save"
exports.options = {
  IN: 'IN',
  OUT: 'OUT', 
  NO: 'N/O',
  COS: 'COS'
}
exports.getSectionFormEndpoint = "bx_block_custom_forms/section_form"
exports.getAllFormEndpoint = "bx_block_custom_forms/new_form"
exports.totalPoints = "Points Total"
exports.givenPoints = "Points Given"
exports.applicablePoints = "Points Applicable"
exports.done = "Done"
exports.notes = "Notes"
exports.uploadImageEndpoint = "bx_block_custom_forms/custom_forms"
exports.methodPost = "POST"
exports.saveFormDataEndpoint = "bx_block_custom_forms/custom_forms"
exports.methodPut = "PUT"
exports.saveFormSuccessMsg = "Your answers saved successfully"
exports.methodDelete = "DELETE"
exports.taskAuditEndpoint = "bx_block_tasks/audits"
exports.cancel = "Clear"
exports.cancelFormEndpoint = "bx_block_custom_forms/custom_forms"
exports.getStatusTrackerEndpoint = "bx_block_tasks/audits"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.isLoadingMessage = "Loading...."
// Customizable Area End