import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, isEmail, setStorageData } from "../../../framework/src/Utilities";

export interface AccountInfo {
  fullName: string
  email: string
  phone: string
  location: string
  street: string
}


const initAccountInfo = {
  fullName: '', 
  email: '', 
  phone: '', 
  location: '', 
  street: ''
}
export type InfoType = 'fullName' | 'email' | 'phone' | 'location' | 'street'
type UserGroupType =  'client' | 'regular' | 'evermore'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  accountInfo: AccountInfo
  isInvalidEmail: boolean
  isInvalidPhone: boolean
  isOpenToast: boolean
  activeButton: boolean
  originAccountInfo: AccountInfo
  userInfoSaved: boolean
  userGroup: UserGroupType
  isOpenDialogConfirmPassword: boolean
  password: string
  showPassword: boolean
  errorMessage: string
  isOpenErrorToast: boolean
  invalidPassword: boolean
  passwordError: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  phoneRegex: RegExp
  saveChangeCallId: string = ""
  getUserInfoCallId: string = ""
  getUserRoleCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      accountInfo: {...initAccountInfo}, 
      originAccountInfo: {...initAccountInfo},
      isInvalidEmail: false,
      isInvalidPhone: false,
      isOpenToast: false,
      activeButton: false,
      userInfoSaved: false,
      userGroup: 'regular',
      isOpenDialogConfirmPassword: false,
      password: "",
      showPassword: false,
      errorMessage: "",
      isOpenErrorToast: false,
      invalidPassword: false,
      passwordError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.phoneRegex = new RegExp(/^\+?(\d{1,4})?[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiCallId === this.saveChangeCallId) {
        if (responseJson.data) {
          this.handleToggleDialogSuccess()
          this.getUserInfo()
          this.setState({activeButton: false, userInfoSaved: true, password: ""})
        } else if(responseJson.errors) {
          this.handleError(responseJson.errors, apiCallId)
        }
      }

      if(apiCallId === this.getUserInfoCallId){
        if(responseJson.data) {
          const {attributes} = responseJson.data
          const userInfo = {
            fullName: attributes.full_name,
            email: attributes.email,
            phone: attributes.full_phone_number,
            location: attributes.location_name,
            street: attributes.street_address
          }
          this.setState({accountInfo: {...userInfo}, originAccountInfo: {...userInfo}})
        } else if(responseJson.errors) {
          this.handleError(responseJson.errors)
        }
      }
      this.handleUserRoleResponse(responseJson)
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    await this.getUserRole()
    await this.getUserInfo()
  }
  handleUserRoleResponse = async (responseJson: any) => {
    if(responseJson.meta){
        const evermoreGroup = ['all access user', 'account manager', 'team leader', 'auditor']
        const clientUserGroup = ['super user', 'regional manager', 'area manager', 'general manager']
        const role = responseJson.meta.role?.toLowerCase()
        let group: UserGroupType = 'regular'
        if(evermoreGroup.includes(role)) group = 'evermore'
        if(clientUserGroup.includes(role)) group = "client"
        this.setState({
            userGroup: group
        })
    } else if(responseJson.errors) {
        this.handleError(responseJson.errors)
    }
}
  handleError = (errList: Array<object | string>, apiCallId?: string) => {
    const listErr: { [key: string]: string } = {};
    errList.forEach((error: Object) => {
      listErr[Object.keys(error)[0]] = Object.values(error)[0]
    });
    if (listErr['token']) this.gotoLogin()
    else if (apiCallId === this.saveChangeCallId) {
      this.setState({ errorMessage: configJSON.errorWhileUpdate }, () => {
        this.setState({ isOpenErrorToast: true })
        setTimeout(() => { this.setState({ isOpenErrorToast: false }) }, 2000)
      })
    }
  }
  gotoLogin = async () => {
    await setStorageData("name", "Settings")
    this.props.navigation.navigate("EmailAccountLoginBlock")
}
  apiCall = async (params: {body?: object, endpoint: string, method: string}) => {
    const token = await getStorageData('authToken')
    const {body, endpoint, method} = params
    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": 'application/json',
        token
      })
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(reqMsg.id, reqMsg);
    return reqMsg.messageId
  }
  getUserRole = async () => {
    this.getUserRoleCallId = await this.apiCall({
      endpoint: configJSON.getUserRoleEndpoint,
      method: configJSON.GET
    });
  }
  getUserInfo = async () => {
    this.getUserInfoCallId = await this.apiCall({
      method: configJSON.GET,
      endpoint: configJSON.getUserInfoEndpoint
    })
  }
  validateField = () => {
    const {accountInfo, password} = this.state;
    const invalidPassword = password.length > 0
    
    const isInvalidEmail = accountInfo.email.length > 0 ? !isEmail("", accountInfo.email).status : false
    const isInvalidPhone = accountInfo.phone.length > 0 ? !this.phoneRegex.test(accountInfo.phone) : false
  
    if(!invalidPassword) {
      this.setState({passwordError: true})
      return false
    }
    this.setState({isOpenDialogConfirmPassword: false}, () => {
      this.setState({ isInvalidEmail, isInvalidPhone })
    })
    if(isInvalidEmail || isInvalidPhone) return false; 
    return true;
  }
  onSaveChanges = async () => {
    const {accountInfo, password} = this.state;
    const isValidData = this.validateField();
   
    if(!isValidData) return;
    const {fullName, email, phone, location, street} = accountInfo
    const body = {
      data: {
        attributes: {
          full_name: fullName, 
          email, 
          full_phone_number: phone, 
          location_name: location, 
          street_address: street,
          password
        }
      }
    }
    
    this.saveChangeCallId = await this.apiCall({
      body, 
      method: configJSON.PATCH, 
      endpoint: configJSON.saveChangeEndpoint
    })
  }

  onChangeInputValue = (key: InfoType, value: string) => {
    const {accountInfo, originAccountInfo} = this.state;
    let {isInvalidEmail, isInvalidPhone} = this.state
    accountInfo[key] = value;
    if(key === 'email') isInvalidEmail = false
    if(key === 'phone') isInvalidPhone = false
    this.setState({accountInfo, isInvalidEmail, isInvalidPhone, activeButton: originAccountInfo[key] !== value, userInfoSaved: false})
  }

  handleToggleDialogSuccess = () => {
    this.setState({isOpenToast: !this.state.isOpenToast})
  }

  handleToggleDialogConfirmPassword = () => {
    this.setState({isOpenDialogConfirmPassword: false, passwordError: false, password: ""})
  }

  handleChangePassword = (password: string) => {
    this.setState({password, passwordError: false})
  }

  handleShowPassword = () => {
    this.setState({showPassword: !this.state.showPassword})
  }

  handleOpenConfirmPassword = () => {
    this.setState({isOpenDialogConfirmPassword: true})
  }

  closeErrorToast = () => {
    this.setState({isOpenErrorToast: false})
  }
  // Customizable Area End
}
