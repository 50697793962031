// Customizable Area Start
import React from "react";

import {
    ThemeProvider,
    Typography,
    createTheme,
    Button,
    styled,
    Box,
    Input,
    Dialog,
    DialogContent
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative",
                paddingTop: 20,
                paddingBottom: 20
            }
        }
    }
})


import CustomContactUsController, {
    Props,
    configJSON,
} from "./CustomContactUsController";

export default class CustomContactUs extends CustomContactUsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={this.props.openContactUs} onClose={this.props.toggleContactUs}>
                    {this.state.isSuccess ?
                        <DialogContent >
                            <CloseIcon data-test-id="btn-close-dialog" onClick={this.props.toggleContactUs} style={styles.iconClose as React.CSSProperties} />
                            <Box style={{ textAlign: 'center', marginTop: 20, marginBottom: 30 } as React.CSSProperties}>
                                <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                                <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
                                    {configJSON.successMsg}</Typography>
                            </Box>
                        </DialogContent> :
                        <DialogContent>
                            <CloseIcon onClick={this.props.toggleContactUs} style={styles.iconClose as React.CSSProperties} />

                            <Title>{configJSON.contactUs}</Title>
                            <TypographyContent>{configJSON.contactDescription}</TypographyContent>
                            <FormContainer>
                                <InputContainer>
                                    <Typography className="input-label">{configJSON.name}</Typography>
                                    <MyInput
                                        value={this.state.name}
                                        onChange={(event) => this.onChangeName(event.target.value)}
                                        data-test-id="input-name"
                                        placeholder={"Name"}
                                        fullWidth={true}
                                        disableUnderline />
                                </InputContainer>
                                <InputContainer>
                                    <Typography className="input-label">{configJSON.email}</Typography>
                                    <MyInput
                                        value={this.state.email}
                                        onChange={(event) => this.onChangeEmail(event.target.value)}
                                        data-test-id="input-email"
                                        placeholder={"Email"}
                                        fullWidth={true}
                                        disableUnderline />
                                    {this.state.isInvalidEmail && <Typography style={{ ...styles.errorText }}>{configJSON.invalidEmail}</Typography>}
                                </InputContainer>
                                <InputContainer>
                                    <Typography className="input-label">{configJSON.subject}</Typography>
                                    <MyInput
                                        value={this.state.subject}
                                        onChange={(event) => this.onChangeSubject(event.target.value)}
                                        data-test-id="input-subject"
                                        placeholder={"Subject"}
                                        fullWidth={true}
                                        disableUnderline />
                                </InputContainer>
                                <InputContainer>
                                    <Typography className="input-label">{configJSON.message}</Typography>
                                    <MyInput
                                        value={this.state.message}
                                        onChange={(event) => this.onChangeMessage(event.target.value)}
                                        data-test-id="input-message"
                                        placeholder={configJSON.messagePlaceholder}
                                        fullWidth={true}
                                        multiline
                                        rows={4}
                                        disableUnderline />
                                </InputContainer>
                            </FormContainer>
                            <CustomButton data-test-id="btn-submit" className="btn-submit" onClick={this.handleSubmit} disabled={this.isDisableButton()}>
                                <Typography>{configJSON.submit}</Typography>
                            </CustomButton>
                        </DialogContent>}
                </Dialog>
            </ThemeProvider>
        );
    }
}

const styles = {
    errorText: {
        color: "#DC2626",
        fontSize: 12,
        marginTop: 5,
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "column",
        marginTop: 20,
        marginBottom: 30
    },
    successText: {
        lineHeight: "32px",
        color: "#0F172A",
        fontWeight: 700,
        fontSize: 24,
    },
    iconClose: {
        color: "#334155",
        cursor: "pointer",
        position: "absolute",
        top: 10,
        right: 10,
    }
}

const Title = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 24,
    lineHeight: "32x",
    fontWeight: 700,
    '&.sub': {
        lineHeight: "26px",
        fontSize: 18,
        '&.password': {
            fontSize: 16,
        }
    },
    "@media (max-width: 960px)": {
        fontSize: 24,
        '&.sub': {
            fontSize: 14,
            '&.password': {
                fontSize: 14,
            }
        }
    },
    "@media (max-width: 576px)": {
        fontSize: 20,
    }
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography

const FormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    margin: '20px auto'
})

const InputContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    '& .input-label': {
        color: '#334155',
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "22px"
    }
})

const MyInput = styled(Input)({
    border: "1px solid #CBD5E1",
    borderRadius: 8,
    marginTop: 5,
    padding: 10,
})

const CustomButton = styled(Button)({
    padding: 16,
    borderRadius: 8,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",

    '&.btn-submit': {
        width: '100%'
    },

    "& p": {
        color: "#FFFFFF",
        fontSize: 16,
        fontWeight: 700,
        lineHeight: "24px",
    },
    "&:disabled": {
        background: "#A9A9A9 !important",
        cursor: "not-allowed",
        color: "#FFFFFF",
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})
// Customizable Area End
