// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { isEmail, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    // Customizable Area Start
    email: string
    invalidEmail: boolean
    isLoading: boolean
    error: string | null
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController2 extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    sendRecoveryLinkCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            email: "",
            invalidEmail: false,
            isLoading: false,
            error: null
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responsJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

            if (apiRequestCallId === this.sendRecoveryLinkCallId) {
                if (responsJson.message) {
                    this.setState({ isLoading: false })
                    this.props.navigation.navigate("ForgotPasswordConfirmation")
                } else if (responsJson.error) {
                    this.setState({error: this.handleErr(responsJson.error)})
                }
            }
        }
    }

    handleErr = (errorList: Array<string>) => {
        let errMessage = '';
        errorList.forEach((object: string) => {
          const error = JSON.stringify(object);
          JSON.parse(error, (key, value) => {
            if (value.length > 0) {
              if (errMessage.length <= 0) {
                errMessage = value;
              } else {
                errMessage = `${errMessage}{\n}${value}`;
              }
            }
          });
        });
        return errMessage
      }

    handleChangeEmail = (email: string) => {
        this.setState({ email, invalidEmail: false, isLoading: false, error: null })
    }
    gotoLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    sendEmail = () => {
        if (!isEmail("", this.state.email).status) {
            this.setState({ invalidEmail: true})
            return false;
        }
        this.setState({isLoading: true})
        setStorageData("email", this.state.email)
        const body = {
            data: {
                type: "email_account",
                attributes: {
                    email: this.state.email,
                },
            },
        };

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendRecoveryLinkCallId = reqMsg.messageId;
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendRecoveryEndpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.resendContentType,
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.resendMethod
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    // Customizable Area End
}
// Customizable Area End
