Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.contactUs = "Contact Support"
exports.email = "Email"
exports.name = "Name"
exports.message = "Message"
exports.messagePlaceholder = "Please include any information that will help us with your request."
exports.submit = "Submit"
exports.methodPost = "POST"
exports.submitContactUsEndpoint = "bx_block_contact_us/contacts"
exports.invalidEmail = "Email is invalid"
exports.subject = "Subject"
exports.successMsg = "Thank you for contacting us. We'll get back to you as soon as possible"
exports.contactDescription = "If you have any questions or need assistance, our support team is here to help. Please fill out the form below, and we'll get back to you as soon as possible."
// Customizable Area End