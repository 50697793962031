// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Typography,
    Button,
    CircularProgress
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";

const theme = createTheme({
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '8px 8px 32px 8px'
            },
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            }, 
        },
        MuiTypography: {
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            },
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
        },
    }
});

import TermsConditionController, {
    Props,
    configJSON,
} from "./TermsConditionController";

export default class TermsCondition extends TermsConditionController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Header navigation={this.props.navigation} previousPath="TermsConditionSettings">
                <ThemeProvider theme={theme}>
                    <TermsConditionContainer maxWidth={false}>
                        <Box className="content-container">
                            <Typography className="heading">{configJSON.TermsCondition}</Typography>
                            <Typography className="content">
                                {this.state.isLoading && <CircularProgress />}
                                {this.state.termsAndConditions}
                            </Typography>
                        </Box>
                    </TermsConditionContainer>
                </ThemeProvider>
            </Header>
        );
    }
}

const CustomButton = styled(Button)({
    borderRadius: 8,
    padding: 16,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    width: 'fit-content',
  
    "& p": {
        color: "#FFFFFF",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: 16,
    },
    "@media (max-width: 600px)": {
        alignSelf :'end',
        "& p": {
            fontSize: 14
        }
    }
  })

const TermsConditionContainer = styled(Container)({
    background: "#F1F5F9",
    overflowY: 'auto',
    height: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    "& .heading": {
        fontSize: 24,
        lineHeight: "32px",
        fontWeight: 700,
        color: '#0F172A',
    },
    "& .content-container": {
        background: '#FFFFFF',
        borderRadius: 8,
        gap: 20,
        height: 'auto',
        padding: "25px 20px",
        border: "1px solid #CBD5E1",
        minHeight: '100%', 
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        
    },
    "& .content": {
        fontSize: 18,
        lineHeight: "30px",
        fontWeight: 400,
        color: '#000000',
        textAlign: 'justify'
    },
    "@media (max-width: 600px)": {
        "& .content-container": {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    "@media (max-width: 475px)": {
        "& .heading": {
            fontSize: 20,
        },
        "& .conentent": {
            fontSize: 16,
        }
    }
})
// Customizable Area End
