// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, Grid, Select, MenuItem, Input, Button, Dialog, DialogContent, CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Header from "../../navigationmenu/src/Header.web";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/Cancel';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "0px 0px 20px 0px"
            }
        },
        MuiDialog: {
            paper: {
                position: 'absolute',
                bottom: 0
            }
        },
        MuiDialogContent: {
            root: {
                background: '#FFFFFF',
                display: 'flex',
                padding: '20px !important'
            }
        },
        MuiMenuItem: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            }
        }
    }
})
import AuditAllocatorController, {
    Props,
    infoType,
    configJSON
} from "./AuditAllocatorController";

// Customizable Area End

export default class AuditAllocator extends AuditAllocatorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    CheckPermissionUI = () => {
        return this.state.userRole.role !== "All Access User" ?
            this.NoAccessUI() :
            <Box className="content-container">
                <Heading>{configJSON.auditAllocator}</Heading>
                <Box className="select-group">
                    <Grid container spacing={2} style={{ marginBottom: 20 }}>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.typeOfAudit}:</SelectTitle>
                            {this.Select('at', this.state.listFormName, 'auditType')}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.auditorName}:</SelectTitle>
                            {this.AutoSelectName('an', this.state.listAuditorName, 'auditorName')}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.accountName}:</SelectTitle>
                            {this.AutoSelectName('ac', this.state.listAccount, 'accountName')}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.regionName}:</SelectTitle>
                            {this.Select('rn', this.state.listRegionName, 'regionName')}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.locationName}:</SelectTitle>
                            {this.Select('ln', this.state.listLocationName, 'locationName')}
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SelectTitle>{configJSON.departmentName}:</SelectTitle>
                            {this.Select('dn', this.state.listDepartmentName, 'departmentName')}
                        </Grid>
                    </Grid>
                </Box>
                <NewButton disabled={this.state.disableButton || this.state.isLoading} data-test-id="btn-assign-audit" onClick={this.onClickAssignAudit}>
                    <Typography>Assign Audit</Typography>
                    {this.state.isLoading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
                </NewButton>
            </Box>
    }
    NoAccessUI = () => {
        return <Typography style={{marginTop: 30, alignSelf: 'center'}}>{configJSON.noAccess}</Typography>
    }
    SuccessToast = () => {
        return <Dialog open={this.state.isSuccessDialog} onClose={this.toggleSuccessDialog}>
            {this.state.error ? <DialogContent>
                <CancelIcon style={{ marginRight: 10, color: '#F87171' }} />
                <Typography>Error while creating Audit</Typography>
            </DialogContent>
                :
                <DialogContent>
                    <CheckCircleRoundedIcon style={{ marginRight: 10, color: '#34D399' }} />
                    <Typography>Audit Assigned Successfully</Typography>
                </DialogContent>
            }
        </Dialog>
    }

    Select = (id: string, listItem: Array<any>, key: infoType) => {
        return <CustomSelect
            labelId="demo-customized-select-label"
            id={id}
            data-test-id={'select-' + id}
            placeholder="Select here"
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: { vertical: "bottom", horizontal: "right" },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
                TransitionProps: { style: { marginTop: 20, borderRadius: 8 } }
            }}
            disabled={this.state.isLoading}
            displayEmpty
            SelectDisplayProps={{ style: { padding: 10 } }}
            IconComponent={KeyboardArrowDownRoundedIcon}
            onChange={(event) => this.onChangeInfoData(key, event.target.value as string)}
            value={this.state.infoData[key]}
            renderValue={(value: any) => { return value ? value : 'Select here' }}
            input={<Input disableUnderline placeholder="Select here" />}
        >
            {listItem.map((item, itemidx) => <MenuItem key={item+itemidx} value={item}>{item}</MenuItem>)}
        </CustomSelect>
    }

    AutoSelectName = (id: string, listItem: Array<any>, key: infoType) => {
        const isVisibleName = (item: {attributes: {email: string, full_name: string}}) => {
                return item.attributes?.full_name?.length > 0 ? item.attributes?.full_name : item.attributes?.email
        }
        
        return <Autocomplete
            key={id}
            options={listItem}
            getOptionLabel={(value: any) => { return value ? isVisibleName(value) : 'Select here' }}
            renderInput={(params) => <TextField {...params} placeholder="Select here" variant="outlined" />}
            id={id}
            data-test-id={'select-' + id}
            disabled={this.state.isLoading}
            onChange={(event, newValue) => {
                this.onChangeInfoData(key, newValue)
            }}
            value={this.state.infoData[key]}
        />
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <DashboardContainer maxWidth={false}>
                        {this.state.isPageLoading? <CircularProgress /> : this.CheckPermissionUI()}
                    </DashboardContainer>
                    {this.SuccessToast()}
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const CustomSelect = styled(Select)({
    width: '100%',
    padding: "5px 0",
    border: "1px solid #CBD5E1",
    borderRadius: 8
})

const SelectTitle = styled(Typography)({
    color: '#334155',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '22px',
    marginBottom: 10
})

const NewButton = styled(Button)({
    padding: "10px 16px",
    borderRadius: 8,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",

    "& p": {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 16,
        fontWeight: 700,
        color: "#FFFFFF",
        lineHeight: "24px",
    },
    "&:disabled": {
        cursor: "not-allowed",
        background: "#A9A9A9 !important",
        color: "#FFFFFF",
    },
    "@media (max-width: 600px)": {
        alignSelf :'center',
        "& p": {
            fontSize: 14
        }
    }
})
const DashboardContainer = styled(Container)({
    background: "#F1F5F9",
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    overflowY: 'auto',
    "& .content-container": {
        background: '#FFFFFF',
        borderRadius: 8,
        padding: "25px 20px",
        height: "100%",
        border: "1px solid #CBD5E1",
        "& .select-group": {
            marginTop: 40,
            marginBottom: 40
        }
    },
    "@media (max-width: 600px)": {
        "& .content-container": {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto'
        }
    }
})

const Heading = styled(Typography)({
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 700,
    color: '#0F172A',
})
// Customizable Area End
