// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    id: string
    navigation: any;
}

interface S {
    termsAndConditions: string
    isLoading: boolean
}

interface SS {
    navigation: any;
}

export default class TermsConditionController extends BlockComponent<
    Props,
    S,
    SS
> {
    getTermsAndConditionsId: string = ""

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
            termsAndConditions: '',
            isLoading: true
        }
    }

    async componentDidMount() {
        super.componentDidMount();

        this.getTermsAndConditions();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              
              if(apiCallId === this.getTermsAndConditionsId){
                if(responseJson?.data) {
                    this.setState({
                        termsAndConditions: responseJson?.data?.[0]?.attributes?.description,
                        isLoading: false,
                    })
                } else {
                    this.handleResponseErr(responseJson.errors)
                }
              }
        }
    }

    handleResponseErr = (errors: Array<any>) => {
        const errorList: {[key: string] : string} = {};
  
        Array.isArray(errors) && errors.forEach((error: Object) => {
            errorList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        
        if(errorList['token']) {
            removeStorageData("authToken"); 
            this.goLogin()
        }
    }
    
    goLogin = async () => {
        await setStorageData("name", "TermsConditionSettings")
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }

    apiCall = async (reqData: {method: string, endpoint: string, body?: {}}) => {
        const token = await getStorageData('authToken')
        const {endpoint, method, body} = reqData
        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
  
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": 'application/json',
                token
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }

    getTermsAndConditions = async () => {
        this.getTermsAndConditionsId = await this.apiCall({
            method: configJSON.validationApiMethodType, 
            endpoint: `${configJSON.getTermsAndConditionsApiEndpoint}`
        })
    }
}
// Customizable Area End
