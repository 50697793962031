// Customizable Area Start
import React from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  withStyles,
  Button,
  TextField,
  Tooltip,
  Input,
  Modal,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import Header from "../../navigationmenu/src/Header.web";

import AuditListController, { Props, configJSON } from "./AuditListController.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import CloseIcon from '@material-ui/icons/Close';
import { successCheckIcon, errorCheckIcon } from "./assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const StyledTableContainer = styled(TableContainer)({
  overflowX: "hidden",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: '20px',
  "@media only screen and (max-width: 600px)": {
    padding: "0 0.75rem"
  },
});

const TitleText = styled(Typography)({
  fontSize: "24px",
  lineHeight: "32px",
  fontWeight: 700,
  fontFamily: "Montserrat",
  padding: "10px 20px",
  "@media only screen and (max-width: 1440px)": {
    // paddingLeft: "2px",
    fontSize: "21px"
  },
  "@media only screen and (max-width: 600px)": {
    padding: "10px",
  },
});

const BodyBox = styled(Box)({
  backgroundColor: "#E2E8F0",
  width: '100%',
  // minWidth: "87.5vw",
  overflow: 'auto',
  "@media only screen and (max-width: 1440px)": {
    // minWidth: "86vw",
    backgroundColor: "#E2E8F0",
    // minHeight: "100vh",
  },
});

const HeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "10px",
  marginTop: "15px",
  // paddingLeft: "30px",
  "@media only screen and (max-width: 600px)": {
    width: '100%',
    display: 'flex',
    marginTop: '15px',
    marginBottom: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'flex-start',
    // paddingLeft: '15px',
    gap: "5px"
  },
  "& .dateInput":{
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    textDecoration: "none",
    backgroundColor: "white",
    height: "34px",
    marginTop: "10px",
    padding: "0px 6px 0px 6px",
    width: "135px",
    minWidth: "135px",
    fontSize: "1rem"
  },
  "& .react-datepicker__close-icon": {
    top: '10%'
  },
  "& .react-datepicker__close-icon::after": {
    width: '18px',
    height: '22px',
    fontSize: '17px'
  }
})

const StyledTableHead = styled(TableHead)({
  backgroundColor: "#E5E5FF",
  marginBottom: "10px",
});

const LoaderBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  height: '331px',
  borderRadius: '6px',
})

const DropdownOut = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  // width: "98%",
  // paddingLeft: "0px",
  // marginLeft: "-10px",

  "@media only screen and (max-width: 1024px)": {
    // width: "100%",
  },
  "@media only screen and (max-width: 1440px)": {
    // width: "93%",
  },
});

const DropdownMenu = styled(Box)({
  display: "flex",
  flexDirection: "row",
  // width: "95%",
  margin: "0px 10px",
  overflowX: "auto",
  overflowY: "hidden",
  whiteSpace: "nowrap",

  "@media only screen and (max-width: 1440px)": {
    display: "flex",
    height: "51px",
    width: "107%",
    gap:"9px",
    alignItems:"center"

  },
  "@media only screen and (max-width: 600px)": {
    display: "flow",
  },

  "& > button": {
    flexShrink: 0,
    minWidth: "auto",
    whiteSpace: "nowrap",
  },

  "@media only screen and (max-width: 1024px)": {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    // marginLeft: "auto",
    // marginRight: "auto",
    width: "100%",
  },
});

const StyledBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // width:"96%",
  // paddingLeft: "1rem"
});

const BoldTableCell = styled(TableCell)({
  fontWeight: "bold",
});

const StyledTable = styled(Table)({
  marginTop: "20px",
  width: "100%",
});

const BoxAuditNumber = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  gap: "14%"
});

const ToolTixTextShow = styled(Box)({
  textAlign: 'center',
  fontSize: "11px",
  cursor: "pointer"
});

const BoxAuditMouseHoverStyle = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "1px"

});

const TextInput = styled(Input)({
  borderRadius: '10px',
  backgroundColor: 'white',
  height: '32px',
  textTransform: 'none',
  marginLeft: '10px',
  overflow: 'hidden',
  border: 'none',
  padding: '9px',
  outline: 'none',
  fontSize: '15px',
  width: "157px",
  minWidth: "157px",
})

const StyledStatusSelect = withStyles({
  root: {
    "& .MuiSelect-icon": {
      borderBottom: "none",
    },
    width: "108px",
    borderRadius: "10px !important",
    backgroundColor: "white !important",
    height: "32px",
    textTransform: "none",
    marginLeft: "10px",
    overflow: "hidden",
    padding: '0 9px',
    display: 'flex',
    alignItems: 'center',
   
    "& .MuiSelect-select:focus ": {
      width: "108px",
      borderRadius: "10px",
      backgroundColor: "white !important",
      height: "32px",
      textTransform: "none",
      marginLeft: "10px",
      overflow: "hidden",
      padding: '0 9px',
      display: 'flex',
      alignItems: 'center',

    }
  },
  popoverPaper: {
    marginTop: '32px',
  },
})(Select);

const CustomPagination = styled(Box)({
  padding: '0px',
  margin: '15px 0px 0px 0px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '11px',
  listStyle: 'none',
  "& button":{
    color: "rgba(71, 85, 105, 1)",
    width: "20px",
    height: "20px",
    minWidth: "20px"
  },
  "& p":{
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "30px",
    textAlign: "left"
  },
  "& .paginationArrow" :{
    color: "rgba(71, 85, 105, 1)",
    width: "20px",
    height: "20px"
  },
  "& .paginationDotArrow":{
    marginBottom: "-9px",
    color: "rgba(71, 85, 105, 1)",
    width: "20px",
    height: "20px"
  }
});

const modalStyle = {
  position: "absolute" as "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  p: 4,
  borderRadisu:"5px",
  width: "600px",
  height: "445px",
  top: "286px",
  /* left: 420px; */
  padding: "37px 0px 0px",
  borderRadius: "8px 8px 32px 8px",
  opacity: "0px",
  boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)',
  "@media only screen and (max-width: 600px)": {
    width: "316px",
    minWidth: "325px",
    heigt:"414px"
  }
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#334155",
  fontSize: "14px",
}));

const Heading = styled(Typography)({
  fontSize: 24,
  lineHeight: "32px",
  fontWeight: 700,
  color: '#0F172A',
  paddingLeft: "44px",
  paddingBottom: "24px"
})
const ModalCloseBox = styled(Box)({
  position: 'absolute',
  top: "-29px", 
  right: "10px",  
  cursor: "pointer"
})

const ModalContent =  styled(Box)({
    marginBottom: "16px",
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '44px',
    gap: "20px",
    "@media only screen and (max-width: 600px)": {
      paddingRight: '40px',
      marginBottom: "0px"
    }
})

const  ModalButtonContent =  styled(Box)({
  marginBottom: 20,
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '33px 24px 33px 24px',
  gap: "20px",
  "@media only screen and (max-width: 600px)": {
    paddingRight: '40px',
    justifyContent:"center"
  }
})
const  EditBox =  styled(Box)({
  "& svg":{
    width: "18px",
    height: "18px",
    cursor:"pointer"
  }
})

const SelectTitle = styled(Typography)({
  color: '#334155',
  fontWeight: 700,
  fontSize: 14,
  lineHeight: '22px',
  marginBottom: 10
})
const CustomSelect = styled(Select)({
  width: '100%',
  padding: "5px 0",
  border: "1px solid #CBD5E1",
  borderRadius: 8
})

const NewButton = styled(Button)({
  padding: "10px 16px 10px 16px",
  borderRadius: 8,
  background: "rgba(229, 229, 255, 1)",
  width:"158px",
  height:"44px",
  marginLeft: "0px",
  "& p": {
      fontFamily: "'Montserrat', sans-serif",
      fontSize: 16,
      fontWeight: 700,
      color:" rgba(0, 0, 0, 1)",
      lineHeight: "24px",
  },
  "&:disabled": {
      cursor: "not-allowed",
      background: "#A9A9A9 !important",
      color: "#FFFFFF",
  },
  "@media (max-width: 600px)": {
      alignSelf :'center',
      "& p": {
          fontSize: 14
      }
  }
})
const DialogBox = styled(Dialog)({
  maxWidth: "377px",
  margin: "auto",
  "& .MuiDialog-scrollPaper": {
    display: 'flex',
    justifyContent: 'center', 
    width: '113%',
    height: '100vh', 
    alignItems: "flex-end"
  },
  "& .MuiDialog-paperScrollPaper": {
    height: "68px",
    borderRadius: "0px 0px 20px 0px",
    "& .MuiDialogContent-root > img":{
      width: "20.8px",
      height: "20.8px"
    },
    "& .MuiDialogContent-root > p":{
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px', 
      fontWeight: 700, 
      lineHeight: '24px',
      textAlign: 'left', 
    },
    "& .MuiDialogContent-root:first-child": {
      paddingTop: "8px"
  }
  },
  "& .MuiDialogContent-root":{
    display:"flex",
    alignItems:"center",
    gap: "16px"
  }
});

export default class AuditList extends AuditListController {
  constructor(props: Props) {
    super(props);

  };

  Select = (id: string, listItem:string, key: string) => {
    const { auditEditStatus  } = this.state 
    let value :string = ""
    let renderMenuItem = configJSON.statusMenuItems
    value = auditEditStatus ? auditEditStatus : listItem
    return <CustomSelect
        labelId="demo-customized-select-label"
        id={id}
        data-test-id={'select-' + id}
        placeholder="Select here"
        MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
            TransitionProps: { style: { marginTop: 20, borderRadius: 8 } }
        }}
        displayEmpty
        SelectDisplayProps={{ style: { padding: 10 } }}
        IconComponent={KeyboardArrowDownRoundedIcon}
        onChange={(event) => this.onChangSelectEdit( event.target.value as string)}
        value={value}
        renderValue={(value: any) => { return value ? this.humanize(value, false) : 'Select' }}
        input={<Input disableUnderline placeholder="Select" />}
    >
        <MenuItem value="" disabled>  <span style={{ color: '#aaa' }}>Select</span></MenuItem>
        { renderMenuItem?.map((data:any,index:number)=> {
          return (
            <MenuItem key={index} value={data.value}>{data.label}</MenuItem>
          )
        })}
    </CustomSelect>
  }

  SelectAuditorName = (id: string, listItem: Array<any>, key: string) => {
    return <Autocomplete
        key={id}
        options={listItem}
        getOptionLabel={(option) => option?.full_name ?? option.email}
        renderInput={(params) => <TextField {...params} placeholder="Select here" variant="outlined" />}
        id={id}
        data-test-id={'select-' + id}
        disabled={this.state.isLoading}
        onChange={(event, newValue) => {
            this.onChangSelectEdit1(newValue)
        }}
        value={this.state.auditEditAssignTo}
    />
  }

  getStatusColor(status: any) {
    switch (status) {
      case "completed":
        return { color: "#059669", backgroundColor: "#D1FAE5" }; 
      case "in_progress":
        return { color: "#D97706", backgroundColor: "#FEF3C7" };
      case "assigned":
        return { color: "#DC2626", backgroundColor: "#FEE2E2" };
      default:
        return { color: "inherit", backgroundColor: "inherit" };
    }
  }

  humanize = (str: string, onlyFirstChar: boolean) => {
    if(!str || str?.length === 0){
      return '';
    }

    if(onlyFirstChar) {
      return str.charAt(0).toUpperCase()
    }

    let i, frags = str.split('_');
    for (i=0; i<frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  }

  getPrefixCharOfStatus(status: any) {
    if (status) return status.charAt(0).toUpperCase()
  }

  renderFilters() {
    return (
      <DropdownOut>
        <DropdownMenu style={{ backgroundColor: "#E2E8F0" }}>
          <TextInput
            data-test-id="txtAuditNumber"
            placeholder={"Audit Number"}
            disableUnderline
            onChange={this.handleChange('audit_number')}
            value={this.state.auditNumber}
            type="text"
          />
          <TextInput data-test-id="txtAccountNumber"
            placeholder={"Account Name"}
            disableUnderline
            onChange={this.handleTextChange('account_name')}
            value={this.state.accountNumber}
            type="text"
          />
          <StyledStatusSelect
            value={this.state.auditStatus}
            onChange={this.handleGetStatusChange('status')}
            data-test-id="dropdown"
            disableUnderline
            displayEmpty
            renderValue={(value: any) => {
              if (value === "") {
                return <span style={{ color: '#aaa' }}>Status</span>;
              }
              return this.humanize(value, false)
            }}
          >
            <MenuItem value="">
              <span style={{ color: '#aaa' }}>Select here</span>
            </MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="assigned">Assigned</MenuItem>
          </StyledStatusSelect>

          <Button
            style={{
              borderRadius: "10px",
              backgroundColor: "white",
              height: "32px",
              textTransform: "none",
              marginLeft: "10px",
              overflow: "hidden",
            }}
            endIcon={<img style={{
              height: "19px",
              paddingLeft: "1px",
              paddingRight: "13px",
            }}
            src={this.handleGetSortIconAssingto()} alt="Sort Arrow" />}
            onClick={() => this.handleGetSortAssignTo(this.state.assignedTo === 'assigned_to_asc' ? 'assigned_to_desc' : 'assigned_to_asc')}
            data-test-id="SortByAssignedTo"
            type="button"
          >
            Assigned To
          </Button>

          <Button
            style={{
              borderRadius: "10px",
              backgroundColor: "white",
              height: "32px",
              textTransform: "none",
              marginLeft: "10px",
              overflow: "hidden",
            }}
            endIcon={<img style={{
              height: "19px",
              paddingLeft: "1px",
              paddingRight: "13px",
            }}
            src={this.handleGetSortIcon()} alt="Sort Arrow" />}
            onClick={() => this.handleSortByDate(this.state.createdDateSort === 'date_created_asc' ? 'date_created_desc' : 'date_created_asc',"dateCreated")}
            type="button"
            data-test-id="SortByCreatedDate"
          >
            Date Created
          </Button>
          <Button
            style={{
              borderRadius: "10px",
              backgroundColor: "white",
              height: "32px",
              textTransform: "none",
              marginLeft: "10px",
              overflow: "hidden",
            }}
            endIcon={<img style={{
              height: "19px",
              paddingLeft: "1px",
              paddingRight: "13px",
            }}
            src={this.handleGetSortIcon2()} alt="Sort Arrow" />}
            onClick={() => this.handleSortByDate(this.state.completedDateSort === 'date_completed_asc' ? 'date_completed_desc' : 'date_completed_asc',"")}
            data-test-id="SortByCompDate"
          >
            Date Completed
          </Button>
        </DropdownMenu>
      </DropdownOut>
    )
  }

  renderPagination() {
    const { audits, currentPage, todosPerPage, isLoading, paginationObj } = this.state;
    const totalPages = Math.ceil(paginationObj?.totalRecords / todosPerPage);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number, index) => {
      if (totalPages <= 4) {
        return (
          <Typography
            key={index}
            onClick={() => this.paginationButton("currentPage", number)}
            style={{ cursor: 'pointer', fontWeight: currentPage === number ? '700' : '400' }}
            data-test-id="paginationButton"
          >
            {number}
          </Typography>
        );
      } else {
        const lastPage = Math.min(currentPage + 3, totalPages);
        const firstPage = Math.max(lastPage - 3, 1);
        if (number >= firstPage && number <= lastPage) {
          return (
            <Typography
              key={index}
              onClick={() => this.paginationButton("currentPage", number)}
              style={{ cursor: 'pointer', fontWeight: currentPage === number ? '700' : '400' }}
              data-test-id="paginationButton"
            >
              {number}
            </Typography>
          );
        } else if (number === firstPage - 1 && firstPage > 1) {
          return <MoreHorizIcon className="paginationDotArrow" />
        } else if (number === lastPage + 1 && lastPage < totalPages) {
          return <MoreHorizIcon className="paginationDotArrow" />
        }
      }
    });

    return !isLoading 
      && audits?.length > 0 
      && <CustomPagination>
        <Button 
          onClick={()=>{ this.paginationButton("prev", 0) }}
          disabled={currentPage === 1}
          data-test-id="btnPrevPanigation">
          <ChevronLeftIcon />
        </Button>
          {renderPageNumbers} 
        <Button
          onClick={()=>{ this.paginationButton("next", 0) }}
          disabled={currentPage === totalPages }
          data-test-id="btnNextPanigation">
          <ChevronRightIcon  />
        </Button>
      </CustomPagination>
  }

  renderLoader() {
    return this.state.isLoading 
    && <LoaderBox >
      <CircularProgress />
    </LoaderBox>
  }

  renderNoRecordsFound() {
    return !this.state.isLoading && this.state.audits?.length === 0
    && <LoaderBox >
      No records found
    </LoaderBox>
  }
  modalDialogConfirm = (isDialogBox:boolean,id:string,dailogMessage:string) => {
    let iCon =  !dailogMessage ? errorCheckIcon :  successCheckIcon
    return (
      <DialogBox fullWidth open={isDialogBox}>
        <DialogContent>
          <img src={iCon} />      
          <Typography align="center">
            Audit #{ id || ""} { dailogMessage || "Something error" }
          </Typography>
        </DialogContent>
      </DialogBox>
    );
  }

  NoAccessUI = () => {
    return <Typography style={{ marginTop: 30, textAlign: 'center', width: "100%" }}>{configJSON.noAccess}</Typography>
  }

  EvermoreUI = () => {
    const { audits, isMobile, isLoading, searchTerm, isSearched } = this.state;
    return <BodyBox>
    <HeadingBox>
      <TitleText> {isSearched ? searchTerm : `Audit List`} </TitleText>
      {!isSearched &&
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "0 20px 0 10px",
          }}
        >
          <StyledTypography>
            From
            <br />
            <DatePicker
              selected={this.state.fromDateFilter1}
              onChange={(date: Date | null) => this.handleDateFilterChange(date, 'from')}
              dateFormat="dd/MM/yyyy"
              className="dateInput"
              data-test-id="dateFilterFrom"
              maxDate={this.state.toDateFilter1}
              popperPlacement="bottom-start"
              placeholderText="dd/mm/yyyy"
              isClearable
            />
          </StyledTypography>
          <StyledTypography style={{ marginLeft: '10px' }}>
            To
            <br />
            <DatePicker
              selected={this.state.toDateFilter1}
              onChange={(date: Date | null) => this.handleDateFilterChange(date, 'to')}
              dateFormat="dd/MM/yyyy"
              className="dateInput"
              data-test-id="dateFilterTo"
              minDate={this.state.fromDateFilter1}
              popperPlacement="bottom-end"
              placeholderText="dd/mm/yyyy"
              isClearable
            />
          </StyledTypography>
        </Box>
      }
    </HeadingBox>
    {!isSearched && this.renderFilters()}
    <StyledBox>
      <StyledTableContainer>
        <>
          <StyledTable>
            <StyledTableHead>
              <TableRow>
                <BoldTableCell
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Audit Number &nbsp; &#9656;
                </BoldTableCell>
                <BoldTableCell
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                >
                  Account Name &nbsp; &#9656;
                </BoldTableCell>
                {!isMobile &&
                  <>
                    <BoldTableCell
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Status &nbsp; &#9656;
                    </BoldTableCell><BoldTableCell
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Assigned To &nbsp; &#9656;
                    </BoldTableCell><BoldTableCell
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Date Created &nbsp; &#9656;
                    </BoldTableCell><BoldTableCell
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                    >
                      Date Completed &nbsp; &#9656;
                    </BoldTableCell>
                  </>
                }
                <BoldTableCell
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                ></BoldTableCell>
              </TableRow>
            </StyledTableHead>
            {!isLoading && audits?.length > 0 &&
              <TableBody style={{
                backgroundColor: "white",
                boxShadow: "0px 4px 4px 0px #00000040"
              }}>
                {audits.map((row, index) => (
                  <TableRow key={index} style={{ cursor: 'pointer' }} onClick={() => this.gotoAuditInfo(row.id)}>
                    {!isMobile &&
                      <TableCell>{row?.attributes?.audit_number || ""}</TableCell>
                    }
                    {isMobile &&
                      <TableCell>
                        <BoxAuditNumber>
                          <Tooltip title={this.humanize(row?.attributes?.status, false)} placement="right-start">
                            <Box
                              style={{
                                color: this.getStatusColor(row?.attributes?.status).color,
                                backgroundColor: this.getStatusColor(row?.attributes?.status).backgroundColor,
                                border: `1px solid ${this.getStatusColor(row?.attributes?.status).backgroundColor}`,
                                padding: "2px 8px 2px 8px",
                                borderRadius: "50%",
                                fontWeight: "bold",
                                fontFamily: "Montserrat",
                                textAlign: "center",
                                width: "18px",
                                height: "18px",
                                display: "inline-block",
                                position: "relative",
                              }}
                            >
                              <BoxAuditMouseHoverStyle>
                                <ToolTixTextShow
                                  style={{
                                    color: this.getStatusColor(row?.attributes?.status).color,
                                  }}>
                                  {this.humanize(row?.attributes?.status, true)}
                                </ToolTixTextShow>
                              </BoxAuditMouseHoverStyle>
                            </Box>
                          </Tooltip>
                          {row?.attributes?.audit_number || ""}
                        </BoxAuditNumber>
                      </TableCell>
                    }
                    <TableCell>{this.capitalizeFirstCharacter(row?.attributes?.account_name) || ""}</TableCell>
                    {!isMobile &&
                      <>
                        <TableCell>
                          <Box
                            style={{
                              color: this.getStatusColor(row?.attributes?.status).color,
                              backgroundColor: this.getStatusColor(row?.attributes?.status)
                                .backgroundColor,
                              border: `1px solid ${this.getStatusColor(row?.attributes?.status)
                                .backgroundColor}`,
                              padding: "2px 8px 2px 8px",
                              borderRadius: "12px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat",
                              textAlign: "center",
                            }}
                          >
                            {this.humanize(row?.attributes?.status, false)}
                          </Box>
                        </TableCell>
                        <TableCell>
                          {row?.attributes?.assigned_to || ""}
                        </TableCell>
                        <TableCell>
                          {this.formatDateToDDMMYYYY(row?.attributes?.date_created) || ""}
                        </TableCell>
                        <TableCell>
                          {this.formatDateToDDMMYYYY(row?.attributes?.date_completed) || ""}
                        </TableCell>
                      </>}
                    <TableCell>
                      <EditBox
                        data-test-id="editAuditPopup"
                        onClick={(event) => { this.showAddModal(event, row) }}
                      >
                        <Edit />
                      </EditBox>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }
          </StyledTable>
          {this.renderLoader()}
          {this.renderNoRecordsFound()}
          {this.renderPagination()}
          {this.modalDialogConfirm(this.state.isDialogBox, this.state.auditRecord.id, configJSON.updateItemSuccess)}
        </>
      </StyledTableContainer>
    </StyledBox>
  </BodyBox>
  }

  CheckPermission = () => {
    const { group } = this.state.userRole
    return group === 'evermore' ? this.EvermoreUI() : this.NoAccessUI()

  }
  render() {
    const { searchTerm } = this.state;

    return (
      <>
        <Header navigation={this.props.navigation} searchTerm={searchTerm} searchAudits={(search: string | undefined) => this.searchAudits(search)}>
          {this.state.isRoleLoading? <CircularProgress style={{margin: 30}} /> : this.CheckPermission()}
        </Header>
        <Modal
          open={this.state.isVisibleModal}
          onClose={this.hideModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={modalStyle}>
            <Box sx={{ minHeight: 250, position: 'relative' }}>
              <Box>
                <ModalCloseBox>
                  <CloseIcon  onClick={this.hideModal} />
                </ModalCloseBox>
                <Heading>Audit - { this.state.auditRecord?.id }</Heading>
                < ModalContent >
                  <Grid item md={4} sm={6} xs={12} style={{ maxWidth: "408px"}}>
                    <SelectTitle>Assigned to</SelectTitle>
                    {this.SelectAuditorName('at', this.state.listAuditorName, 'Assignedto')}
                  </Grid>
                  <Grid item md={4} sm={6} xs={12} style={{ maxWidth: "408px"}}>
                    <SelectTitle>Status </SelectTitle>  
                    {this.Select('an', this.state.auditRecord?.attributes?.status, 'Status')}
                  </Grid>
                  <NewButton data-test-id="btn-deleteAudit"
                    onClick={()=>{this.handleDeleteAudit()}}>
                    <Typography>Delete Audit</Typography>
                  </NewButton>
                </ ModalContent>
                <ModalButtonContent >
                  <NewButton data-test-id="btn-closeModal"   onClick={this.hideModal}
                    style={{ width: "120px", background: "rgba(229, 229, 255, 1)" }}>
                    <Typography  >Cancel</Typography>
                  </NewButton>
                  <NewButton 
                    data-test-id="btn-editSave" 
                    style={{ width: "77px",
                    background: "linear-gradient(99.09deg, #767BD7 2.64%, #111F50 100%)", 
                    color: "white" }}
                    onClick={()=>{this.handleEditSave()}}
                    >
                    <Typography style={{ color: "white" }}>Save</Typography>
                  </NewButton>
                </ModalButtonContent>
              </Box>
            </Box>
          </Box>
        </Modal>
              
      </>
    );
  }
}

// Customizable Area End
