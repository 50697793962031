// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
export interface Props {
    id: string
    navigation: any;
}

interface S {
    isOpenToast: boolean
    isOpenConfirmDialog : boolean
}

interface SS {
    navigation: any;
}

export default class PrivacyPolicyController extends BlockComponent<
    Props,
    S,
    SS
> {
    deleteAccountCallId: string = ""
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



        this.state = {
            isOpenToast: false,
            isOpenConfirmDialog: false
        }
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
               
              if(apiCallId === this.deleteAccountCallId) {
                this.setState({isOpenConfirmDialog: false, isOpenToast: responseJson})
              }     
        }
    }
}
// Customizable Area End
