import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import CancelIcon from '@material-ui/icons/Cancel';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "'Montserrat', sans-serif"
      },
      body1: {
        fontFamily: "'Montserrat', sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "'Montserrat', sans-serif !important"
      }
    },
    MuiPaper: {
      root: {
        fontFamily: "'Montserrat', sans-serif !important"
      },
      rounded: {
        borderRadius: '8px 8px 32px 8px'
      }
    },
    MuiDialogActions: {
      root: {
        '&.dialog-confirm': {
          padding: "10px 30px"
        }
      }
    },
  }
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DialogSuccess = () => {
    return <Dialog fullWidth open={this.state.isOpenToast} onClose={this.handleToggleDialogSuccess}>
      <DialogContent dividers>
        <CloseIcon onClick={this.handleToggleDialogSuccess} style={styles.iconClose as React.CSSProperties} />
        <Box style={styles.dialogContent as React.CSSProperties}>
          <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
          <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
            {configJSON.successMsg}</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  }

  DialogConfirmPassword = () => {
    return <Dialog fullWidth open={this.state.isOpenDialogConfirmPassword} onClose={this.handleToggleDialogConfirmPassword}>
      <DialogTitle>
        <Typography style={{ ...styles.successText }}>
          {configJSON.confirmUpdate}
        </Typography>
      </DialogTitle>
      <DialogContent className="dialog-confirm" dividers>
        <CloseIcon onClick={this.handleToggleDialogConfirmPassword} style={styles.iconClose as React.CSSProperties} />
        <Box style={{...styles.dialogContent, alignItems: "start"} as React.CSSProperties}>
          <Typography>
            {configJSON.typeYourPassword}
          </Typography>
          <CustomInput
            type={!this.state.showPassword ? "password" : "text"}
            data-test-id="input-password"
            placeholder={"Your password"}
            fullWidth={true}
            value={this.state.password}
            onChange={(event) => { this.handleChangePassword(event.target.value) }}
            name="old-password"
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  data-test-id="btn-show-hide-password"
                  aria-label="toggle password visibility"
                  onClick={this.handleShowPassword}
                  edge="end"
                >
                  {!this.state.showPassword ? (
                    <VisibilityOutlined style={{ color: "#94A3B8" }} />
                  ) : (
                    <VisibilityOffOutlined style={{ color: "#94A3B8" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
            disableUnderline />
          {this.state.passwordError && <Typography style={{ ...styles.errorText }}>{configJSON.emptyPassword}</Typography>}
        </Box>

      </DialogContent>
      <DialogActions className="dialog-confirm">
        <CustomButton data-test-id="btn-confirm-cancel" className="btn-cancel" onClick={this.handleToggleDialogConfirmPassword}>
          <Typography>{configJSON.cancel}</Typography>
        </CustomButton>

        <CustomButton data-test-id="btn-confirm-okay" onClick={this.onSaveChanges} className="btn-okay">
          <Typography>{configJSON.okay}</Typography>
        </CustomButton>
      </DialogActions>
    </Dialog>
  }

  errorToast = () => {
    return <Dialog PaperProps={{style: {position: 'absolute', bottom: 0, borderRadius: '0px 0px 20px 0px'}}} className="noti-toast" 
    open={this.state.isOpenErrorToast} onClose={this.closeErrorToast}>
         <DialogContent style={{display: 'flex', paddingBottom: 20}}>
            <CancelIcon style={{ marginRight: 10, color: '#F87171' }} />
            <Typography style={{fontWeight: 700}}>{this.state.errorMessage}</Typography>
        </DialogContent>
    </Dialog>
}

  CheckPermissionUI = () => {
    const { userGroup } = this.state
    return userGroup === 'regular' ? <Typography style={{ margin: '30px auto' }}>{configJSON.noPermission}</Typography> :
      <SettingContainer maxWidth={false}>
        <Box className="content-container">
          <Typography className="heading">{configJSON.accountSettings}</Typography>
          <Typography className="description">{configJSON.updateYourPhoto}</Typography>
          <Grid style={{ margin: "20px auto" }} container>
            <Grid item container md={8} sm={12} spacing={2}>
              <InputContainer item sm={6} xs={12}>
                <Typography className="input-label">{configJSON.fullName}</Typography>
                <MyInput
                  data-test-id="input-full-name"
                  placeholder={"Full Name"}
                  fullWidth={true}
                  value={this.state.accountInfo.fullName}
                  onChange={(event) => { this.onChangeInputValue("fullName", event.target.value) }}
                  disableUnderline />
              </InputContainer>
              <InputContainer item sm={6} xs={12}>
                <Typography className="input-label">{configJSON.email}</Typography>
                <MyInput
                  data-test-id="input-email"
                  placeholder={"Email"}
                  fullWidth={true}
                  value={this.state.accountInfo.email}
                  onChange={(event) => { this.onChangeInputValue("email", event.target.value) }}
                  disableUnderline />
                {this.state.isInvalidEmail && <Typography style={{ ...styles.errorText }}>{configJSON.invalidEmail}</Typography>}
              </InputContainer>
              <InputContainer item sm={6} xs={12}>
                <Typography className="input-label">{configJSON.phoneNumber}</Typography>
                <MyInput
                  data-test-id="input-phone"
                  placeholder={"Phone Number"}
                  fullWidth={true}
                  value={this.state.accountInfo.phone}
                  onChange={(event) => { this.onChangeInputValue("phone", event.target.value) }}
                  disableUnderline />
                {this.state.isInvalidPhone && <Typography style={{ ...styles.errorText }}>{configJSON.invalidPhone}</Typography>}
              </InputContainer>
              <InputContainer item sm={6} xs={12}>
                <Typography className="input-label">{configJSON.location}</Typography>
                <MyInput
                  data-test-id="input-location"
                  placeholder={"Location"}
                  fullWidth={true}
                  value={this.state.accountInfo.location}
                  onChange={(event) => { this.onChangeInputValue("location", event.target.value) }}
                  disableUnderline />
              </InputContainer>
              <InputContainer item sm={6} xs={12}>
                <Typography className="input-label">{configJSON.street}</Typography>
                <MyInput
                  data-test-id="input-street"
                  placeholder={"Street Address"}
                  fullWidth={true}
                  value={this.state.accountInfo.street}
                  onChange={(event) => { this.onChangeInputValue("street", event.target.value) }}
                  disableUnderline />
              </InputContainer>
            </Grid>
            <Grid item md={4} className="space"></Grid>
          </Grid>
          <ButtonGroup>
            <CustomButton data-test-id="btn-save" onClick={this.handleOpenConfirmPassword} disabled={!this.state.activeButton}>
              <Typography>{configJSON.saveChanges}</Typography>
            </CustomButton>
          </ButtonGroup>
        </Box>
      </SettingContainer>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Header navigation={this.props.navigation} needGetUserInfo={this.state.userInfoSaved}>
        <ThemeProvider theme={theme}>
          {this.CheckPermissionUI()}
          {this.DialogSuccess()}
          {this.DialogConfirmPassword()}
          {this.errorToast()}
        </ThemeProvider>
      </Header>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  errorText: {
    color: "#DC2626",
    fontSize: 12, 
    marginTop: 5,
  },
  dialogContent: { 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexDirection: "column", 
    marginTop: 20, 
    marginBottom: 30 
  },
  successText: {
    lineHeight: "32px",
    color: "#0F172A",
    fontWeight: 700,
    fontSize: 24,
  },
  iconClose: {
    color: "#334155",
    cursor: "pointer",
    position: "absolute",
    top: 10,
    right: 10,
} 
}
const InputContainer = styled(Grid)({
  display: 'flex', 
  flexDirection: 'column', 
  '& .input-label': {
    color: '#334155', 
    fontSize: 14, 
    fontWeight: 700, 
    lineHeight: "22px"
  }
})

const MyInput = styled(Input)({
  border: "1px solid #CBD5E1",
  borderRadius: 8,
  marginTop: 5,
  padding: 10,
})

const SettingContainer = styled(Container)({
  background: "#F1F5F9",
  paddingTop: 30,
  paddingBottom: 30,
  overflowY: 'auto',
  height: '100%',
  "& .content-container": {
      background: '#FFFFFF',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      border: "1px solid #CBD5E1",
      padding: "25px 20px",
      boxSizing: 'border-box',
      minHeight: '100%', 
      height: 'auto',
      "& .select-group": {
          marginTop: 40,
          marginBottom: 40
      }
  },
  "& .heading": {
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 700,
    color: '#0F172A',
  },
  "& .description": {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    color: '#0F172A',
  },
  "@media (max-width: 600px)": {
      "& .content-container": {
          display: 'flex',
          flexDirection: 'column',
      },
      "& .space": {
        display: 'none'
    }
  }
})

const CustomButton = styled(Button)({
  padding: 16,
  borderRadius: 8,
  background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
  '&.btn-cancel': {
    background: '#F87171'
  },
  '&.btn-okay': {
    background: '#34D399'
  },

  "& p": {
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: 700,
      lineHeight: "24px",
  },
  "&:disabled": {
      background: "#A9A9A9 !important",
      cursor: "not-allowed",
      color: "#FFFFFF",
  },
  "@media (max-width: 600px)": {
      alignSelf :'center',
      "& p": {
          fontSize: 14
      }
  }
})

const ButtonGroup = styled(Box)({
  display: 'flex', 
  gap: 20
})

const CustomInput = styled(Input)({
  border: "1px solid #CBD5E1",
  padding: 10,
  borderRadius: 8,
  marginTop: 10
})
// Customizable Area End
