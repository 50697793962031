// Customizable Area Start
import React from "react";
import { Box, ThemeProvider, createTheme, Container, styled, Typography, Input, Button, Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import Header from "../../navigationmenu/src/Header.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative"
            }
        }
    }
})
import CorrectiveActionPlanController, {
    CapType,
    Props,
    userClient,
    userEvermore
} from "./CorrectiveActionPlanController";
import { configJSON } from "./TaskController";

// Customizable Area End

export default class CorrectiveActionPlan extends CorrectiveActionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    DialogSuccess = () => {
        return <Dialog fullWidth open={this.state.isOpenDialogSuccess} onClose={this.onCloseDialog}>
            <DialogContent dividers>
                <CloseIcon onClick={this.onCloseDialog} style={styles.close as React.CSSProperties} />
                <Box style={styles.dialogContent as React.CSSProperties}>
                    <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                    <Typography align="center" style={{ ...styles.scsText, marginTop: 10 }}>
                        {configJSON.createCapSuccess}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    }
    CapItem = (capItem: CapType) => {
        const {group} = this.state.user
        const isReadOnly = group === userEvermore
        return <CapItemContainer key={capItem.id}>
            <Box className="section">
                <Typography className="title">#{capItem.id} {capItem.question}</Typography>
            </Box>
            <Box>
                <Typography className="title-small">Notes:</Typography>
                <CustomInput
                    data-test-id="input-note"
                    placeholder={"Placeholder"}
                    fullWidth={true}
                    multiline
                    className="multiline-input"
                    value={capItem.notes ?? ""}
                    disableUnderline
                    readOnly />
            </Box>
            <Box>
                <Typography className="title-small">Action:</Typography>
                <CustomInput
                    data-test-id="input-action"
                    placeholder={"Placeholder"}
                    fullWidth={true}
                    multiline
                    className="multiline-input"
                    value={capItem.action ?? ""}
                    onChange={(event) => { this.onChangeInputValue(capItem.id, 'action', event.target.value) }}
                    onBlur={(event) => this.onSaveForm(event.target.value)}
                    disableUnderline
                    readOnly={isReadOnly} />
            </Box>
            <Box>
                <Typography className="title-small">Person Responsible:</Typography>
                <CustomInput
                    className="person-responsible"
                    data-test-id="input-person"
                    placeholder={"Placeholder"}
                    fullWidth={true}
                    value={capItem.person_responsible ?? ""}
                    onBlur={(event) => this.onSaveForm(event.target.value)}
                    onChange={(event) => { this.onChangeInputValue(capItem.id, 'person_responsible', event.target.value) }}
                    disableUnderline
                    readOnly={isReadOnly} />
            </Box>
        </CapItemContainer>
    }
    showCap = () => {
        const {group} = this.state.user
        const showEmpty = () => {
            return this.state.auditStatus !== "completed" ? <Typography>{configJSON.dataEmpty}</Typography>
            : <Typography>{configJSON.dataEmptyForComplete}</Typography>
        }
        return this.state.listCap.length > 0 ?
            <Box>
                {this.state.listCap.map(item => this.CapItem(item))}
                {group === userClient &&
                 <GradientButton data-test-id="btn-submit" onClick={this.handleSaveAllForm}>
                    <Typography>{configJSON.submitCap}</Typography>
                </GradientButton>}
            </Box> :
            showEmpty()
    }

    CheckPermissionUI = () => {
        const { group } = this.state.user
        return group === 'regular' ? <Typography style={{margin: '30px auto'}}>{configJSON.noPermision}</Typography> :
            <ContentBox>
                <TitleGroup>
                    <Title className="title">{configJSON.auditInfo}: #{this.state.auditId}</Title>
                    <GroupTab>
                        <TabButton data-test-id={"section-audit-info"}
                            onClick={() => this.onChangeTab('audit-info')}
                            className={this.isActiveTab('audit-info')}>
                            <Typography className="section">{configJSON.auditInfo}</Typography>
                        </TabButton>
                        <TabButton data-test-id={"section-status-tracker"}
                            onClick={() => this.onChangeTab('status-tracker')}
                            className={this.isActiveTab('status-tracker')}>
                            <Typography className="section">{configJSON.statusTracker}</Typography>
                        </TabButton>
                        <TabButton data-test-id={"section-cap"}
                            onClick={() => this.onChangeTab('cap')}
                            className={this.isActiveTab('cap')}>
                            <Typography className="section">{configJSON.CAP}</Typography>
                        </TabButton>
                    </GroupTab>
                </TitleGroup>
                {this.state.isLoading ? <CircularProgress /> : this.showCap()}

            </ContentBox>

    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            //Merge Engine DefaultContainer
            <Header searchAudits={() => { }} searchTerm="" navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <CustomContainer maxWidth={false}>
                        {this.CheckPermissionUI()}
                    </CustomContainer>
                    {this.DialogSuccess()}
                </ThemeProvider>
            </Header>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: "column",
        marginTop: 20,
        marginBottom: 30
    },
    scsText: {
        fontWeight: 700,
        fontSize: 24,
        color: "#0F172A",
        lineHeight: "32px",
    },
    close: {
        position: "absolute",
        top: 10,
        right: 10,
        cursor: "pointer",
        color: "#334155",
    }
}
const CapItemContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    marginBottom: 10,
    '& .section': {
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        padding: '10px 8px'
    },
    '& .title': {
        color: '#0A122F',
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
    },
    '& .title-small': {
        fontSize: 14,
        lineHeight: '22px',
        color: '#334155',
        fontWeight: 700
    },
    '& .person-responsible': {
        width: '40%'
    },
    "@media (max-width: 800px)": {
        '& .person-responsible': {
            width: '50%'
        }
    },
    "@media (max-width: 576px)": {
        '& .person-responsible': {
            width: '100%'
        }
    }
})
const CustomInput = styled(Input)({
    border: "1px solid #CBD5E1",
    padding: 10,
    borderRadius: 8,
    marginTop: 5,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    "&.multiline-input": {
        padding: '16px 10px'
    }
})
const GradientButton = styled(Button)({
    padding: 16,
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    borderRadius: 8,
    width: 'fit-content',

    "& p": {
        color: "#FFFFFF",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "24px",
    },
    "&:disabled": {
        color: "#FFFFFF",
        background: "#A9A9A9 !important",
        cursor: "not-allowed",
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})
const TitleGroup = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    flexDirection: 'column',
})
const Title = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '32px',
    color: '#0F172A',
    textTransform: 'capitalize'
})
const ContentBox = styled(Box)({
    width: "100%",
    display: "flex",
    gap: 20,
    position: 'relative',
    borderRadius: 8,
    flexDirection: "column",
    margin: "30px",
    padding: 30,
    border: '1px solid #CBD5E1',
    minHeight: 'calc(100vh - 205px)',
    height: 'fit-content',
    backgroundColor: '#FFFFFF',
    "@media (max-width: 576px)": {
        margin: 20
    }
})
const CustomContainer = styled(Container)({
    minHeight: "calc(100vh - 80px)",
    height: "auto",
    position: 'relative',
    padding: 0,
    overflowX: 'hidden',
    display: 'flex'
})
const GroupTab = styled(Box)({
    boxShadow: "0px 4px 4px 0px #00000040",
    width: "fit-content",
    maxWidth: '100%',
    gap: 10,
    borderRadius: 50,
    padding: 5,
    display: 'flex',
    marginBottom: 20,
    "@media (max-width: 630px)": {
        flexWrap: 'wrap',
        boxShadow: 'none'
    }
})
const TabButton = styled(Box)({
    border: "1px solid black",
    padding: "10px 12px",
    color: "#647488",
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '& .section': {
        fontWeight: 700,
        fontSize: 16,
        lineHeight: '24px',
        textTransform: 'capitalize'
    },
    "&.active": {
        backgroundColor: "#A0A2FF",
        color: "#152764",
        border: "1px solid #A0A2FF"
    },
    "@media (max-width: 576px)": {
        '& .section': {
            fontSize: 14,
        }
    }
})
// Customizable Area End
