// Customizable Area Start
import React from "react";

import { ThemeProvider, Typography, createTheme, Container, Grid, Box, styled } from "@material-ui/core";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            },
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
        },
    }
})


import AccountActivationController, {
    Props,
    configJSON,
} from "./AccountActivationController";
import { imgBackground, featureImg, imgLogo } from "./assets";

export default class ForgotPassword extends AccountActivationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
            <Grid container>
                <Grid item sm={12} md={6} style={{ ...styles.flexBox, width: "100%" , flexDirection: "column",  }}>
                    <Box sx={{ ...styles.flexBox, justifyContent: "center", width: "100%", }}>
                        <img style={{ marginTop: 50 }} src={imgLogo} alt="" />
                    </Box>
                    <ContentBox>
                        <Title>{configJSON.createAccountSuccess}</Title>
                        <Title className="sub">{configJSON.checkYourEmail}</Title>
                        <TypographyContent>{configJSON.haveSent} {this.state.email}</TypographyContent>
                        <Box style={{ ...styles.flexBox, justifyContent: "end" }}>
                            <TypographyContent component={"span"}>{configJSON.backTo}
                                <Typography data-test-id="btn-login" onClick={this.gotoLogin} component={"span"} style={styles.login}>{configJSON.logIn}</Typography>
                            </TypographyContent>
                        </Box>
                    </ContentBox>
                </Grid>
                <Grid item sm={12} md={6} style={{ ...styles.container }}>
                    <Box
                        sx={{
                            ...styles.flexBox,
                            width: "100%",
                            flexDirection: "column",
                        }}
                    >
                        <BackgroundImg>
                            <img src={featureImg} style={{ maxWidth: "80%" }} alt="" />
                        </BackgroundImg>

                    </Box>
                </Grid>
            </Grid>
        </Container>
    </ThemeProvider>

    }
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    container: {
        display: "flex",
        height: "auto",
        minHeight: "100vh"
    },
    login: {
        fontSize: 16,
        color: "#1C3485",
        fontWeight: 700,
        cursor: "pointer"
    },
}

const BackgroundImg = styled(Box)({
    width: "100%", height: "100%", backgroundImage: `url(${imgBackground})`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundColor: "rgb(55, 107, 174)",
    backgroundRepeat: " no-repeat",
    backgroundPosition: "center center",
})

const ContentBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    width: "52%",
    gap: 20,
    padding: "10px 0px",
    margin: "30px auto",
    "@media (max-width: 576px)": {
        width: "80%",
    },
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography

const Title = styled(Typography)({
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 24,
    lineHeight: "32x",
    fontWeight: 700,
    
    '&.sub': {
        lineHeight: "26px",
        fontSize: 18,
        '&.email': {
            fontSize: 16,
        }
    },
    "@media (max-width: 960px)": {
        fontSize: 24,
        '&.sub': {
            fontSize: 14,
            '&.email': {
                fontSize: 14,
            }
        }
    },
    "@media (max-width: 576px)": {
        fontSize: 20,
    }
})
// Customizable Area End
