Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.accountSettings = "Account Settings:"
exports.updateYourPhoto = "Update your photo and personal details here."
exports.firstName = "First Name"
exports.lastName = "Last Name"
exports.fullName = "Full Name"
exports.email = "Email Address"
exports.phoneNumber = "Phone Number"
exports.location = "Location"
exports.street = "Street Address"
exports.cancel = "Cancel"
exports.saveChanges = "Save Changes"
exports.invalidEmail = "Email is invalid"
exports.invalidPhone = "Phone number is invalid. Spaces and special characters are not allowed"
exports.saveChangeEndpoint = "account_block/accounts/update"
exports.POST = "POST"
exports.PUT = "PUT"
exports.GET = "GET"
exports.PATCH = "PATCH"
exports.successMsg = "Your account settings saved successfully"
exports.notificationSettings = "Notification Settings: "
exports.emailNoti = "Email Notifications"
exports.sendDraft = "Send draft audit to client"
exports.TermsCondition = "Terms and Conditions:"
exports.update = "Update"
exports.PrivacyPolicy = "Privacy Policy:"
exports.security = "Security:";
exports.twoFactor = "Two-factor Authentication"
exports.secondaryVerification = "Secondary Verification"
exports.backupCode = "Backup Codes"
exports.purchaseNotif = "Desktop, email, chat, purchase notifications"
exports.deleteAccount = "Delete This Account:"
exports.sampleText = "Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker"
exports.closeAndDelete = "close and delete this account"
exports.deleteAccountEndpoint = ""
exports.confirmDeleteMessage = "Are you sure you want to delete this account?"
exports.deleteSuccess ="Account deleted successfully!"
exports.yes = "YES"
exports.ChangePassword = "Change Password";
exports.oldPassword = "Old Password"
exports.newPassword = "New Password"
exports.retypePassword = "Confirm New Password"
exports.ruleCapitalLetter = "At least one capital letter"
exports.oneLowercase = "At least one lowercase letter"
exports.oneNumber = "At least one number"
exports.minimumLength = "Minimum character length is 8 characters"
exports.oneSymbol = 'At least one special symbol'
exports.savePasswordChangeEndpoint = "bx_block_forgot_password/change_password"
exports.incompleteCapWillbeNotifiedToGeneralManager = "Incomplete CAP will be notified to General Manager after:"
exports.incompleteCapWillbeNotifiedToAreaManager = "Incomplete CAP will be notified to Area Manager after:"
exports.incompleteCapWillbeNotifiedToRegionalManager = "Incomplete CAP will be notified to Regional Manager after:"
exports.incompleteCapWillbeNotifiedToSuperUser = "Incomplete CAP will be notified to Super User after:"


exports.enterDaysHere = "Enter days here:"
exports.capNoti1 = "CAP Notification 1"
exports.capNoti2 = "CAP Notification 2"
exports.capNoti3 = "CAP Notification 3"
exports.colorError = "#DC2626"
exports.getUserInfoEndpoint = "account_block/accounts/show"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.getTermsAndConditionsApiEndpoint = "bx_block_terms_and_conditions/terms_and_conditions"
exports.getNotificationEndpoint = "bx_block_notifications/notification_settings/show"
exports.updateNotificationEndpoint = "bx_block_notifications/notification_settings/update"
exports.noPermission = "You don't have permission to access this page. Please contact admin!"
exports.confirmUpdate = "Confirm update"
exports.typeYourPassword = "Type your password to update"
exports.errorWhileUpdate = "Error while updating account. Please try again!"
exports.okay = "Okay"
exports.emptyPassword = "Please fill your password!"
// Customizable Area End