import React from "react";

// Customizable Area Start
import { Dialog, DialogActions, DialogContent, DialogTitle, ThemeProvider, Typography, createTheme, Button, styled, CircularProgress } from "@material-ui/core";
const theme = createTheme({
  overrides: {
    MuiDialogActions: {
      root: {
        padding: 30,
        justifyContent: "flex-start",
        "@media (max-width: 568px)": {
          padding: "15px 20px",
          justifyContent: "end"
        },
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiDialogContent: {
      root: {
        padding: "10px 30px",
        "@media (max-width: 568px)": {
          padding: "10px 20px"
        },
      }
    },
    MuiDialogTitle: {
      root: {
        padding: "20px 30px",
        "@media (max-width: 568px)": {
          padding: "15px 20px"
        },
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "Montserrat, sans-serif !important",
        color: "#0F172A"
      },
    }
  }
})
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <ThemeProvider theme={theme}>
      <Dialog fullWidth maxWidth={"xl"} open={this.props.isOpen} onClose={this.props.onClose}>
        <DialogTitle>
          <Title>{this.props.type==="privacyPolicy"? configJSON.privacy : configJSON.termsAndConditions}</Title>
        </DialogTitle>
        <DialogContent>
          <TypographyContent>
         {this.state.isLoading? <CircularProgress /> : this.state.termsConds?.description}
          </TypographyContent>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={this.props.onClose} className="accept">
            {configJSON.close}
          </CustomButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>

    // Customizable Area End
  }
}

// Customizable Area Start
const CustomButton = styled(Button)({
  width: 78, 
  height: 44,
  borderRadius: 8,
  fontFamily: "'Montserrat', sans-serif", 
  fontWeight: 700, 
  fontSize: 16, 
  lineHeight: "24px", 
  textTransform: "none",

  "&.accept": {
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    color: "#FFFFFF"
  }

})

const TypographyContent = styled(Typography)({
  fontSize: 18, 
  fontWeight: 400, 
  lineHeight: "30px",
})
const Title = styled(Typography)({
  fontFamily: "'Montserrat', sans-serif",
  fontWeight: 700, 
  fontSize: 30,
  lineHeight: "40px",
  color: "#0F172A",
  "@media (max-width: 960px)": {
    fontSize: 24
  },
})
// Customizable Area End
