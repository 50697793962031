// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    children: any
    searchTerm?: string;
    searchAudits?: (search: string | undefined) => void
    needGetUserInfo?: boolean
    previousPath?: string
}

interface S {
    // Customizable Area Start
    isOpenDrawer: boolean
    search: string | undefined;
    userInfo: {id: string, fullName: string, role: string}
    showLongText: boolean
    isLoading: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class HeaderController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getUserInfoId: string = ""
    getUserRoleId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            isOpenDrawer: false,
            search: this.props.searchTerm,
            userInfo: {
                id: "", 
                fullName: "", 
                role: ""
            },
            showLongText: false,
            isLoading: true
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        await this.getUserInfo()
        await this.getUserRole()
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if(prevProps.needGetUserInfo !== this.props.needGetUserInfo && this.props.needGetUserInfo){
            await this.getUserInfo()
        }
        if(prevProps.searchTerm !== this.props.searchTerm) {
            this.setState({search: this.props.searchTerm})
        }
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              if(apiCallId === this.getUserInfoId){
                this.handleUserInfoResponse(responseJson)
              }

              if(apiCallId === this.getUserRoleId) {
                if(responseJson.meta) {
                    this.setState({userInfo: {...this.state.userInfo, role: responseJson.meta.role}, isLoading: false})
                } else if(responseJson.errors) {
                    this.handleError(responseJson.errors)
                }
              }
        }
    }

    handleUserInfoResponse = (responseJson: any) => {
        if(responseJson.data) {
            const {attributes} = responseJson.data
            const fullName = attributes.full_name?.length > 0 ? attributes.full_name : attributes.email
            const userInfo = { 
              id: attributes.id,
              fullName,
            }
            this.setState({userInfo: {...this.state.userInfo, ...userInfo}})
          } else if(responseJson.errors) {
            this.handleError(responseJson.errors)
          }
    }

    handleError = async (errors: Array<object>) => {
        const list: { [key: string]: string } = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            list[Object.keys(error)[0]] = Object.values(error)[0]
        });
        this.setState({isLoading: false})
        if (this.props.previousPath && list['token']) {
            await setStorageData("name", this.props.previousPath)
            this.props.navigation.navigate("EmailAccountLoginBlock")
        }
    }

    onShowLongText = () => {
        this.setState({showLongText: !this.state.showLongText})
    }

    apiCallRequest = async (params: {endpoint: string}) => {
        const token = await getStorageData('authToken')
        const {endpoint} = params
        const reqMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        reqMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
        );
    
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": 'application/json',
            token
          })
        );
        reqMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.methodGet
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);
        return reqMsg.messageId
    }
    

    getUserInfo = async () => {
        this.getUserInfoId = await this.apiCallRequest({
            endpoint: configJSON.getUserInfoEndpoint
        })
    }

    getUserRole = async () => {
        this.getUserRoleId = await this.apiCallRequest({
            endpoint: configJSON.getUserRoleEndpoint
        })
    }

    toggleOpenDrawer = () =>{
        this.setState({
            isOpenDrawer: !this.state.isOpenDrawer
        })
    }

    gotoDashboard = () => {
        this.props.navigation.navigate("DashBoard")
    }

    setSearch = (value: string) => {
        this.setState({
            search: value
        })
    }

    performSearch =  async (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const test = `${this.state.search}`
            this.props.navigation.navigate("Search", {key: `${test}`})
        }
    }
    // Customizable Area End
}
// Customizable Area End
