import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible } from "./assets";
import { getStorageData, isEmail, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  width: number,
  passwordError: boolean
  emailError: boolean
  errorMessage: string
  checkedAgree: boolean,
  openTermsAndConditions: boolean,
  isAccountActivationSuccess: boolean,
  accountActivationMessage: string
  isUserInGroup: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  accountActivationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      width: window.innerWidth,
      passwordError: false,
      emailError: false, 
      errorMessage: "",
      checkedAgree: false,
      openTermsAndConditions: false,
      isAccountActivationSuccess: false,
      accountActivationMessage: '',
      isUserInGroup: true
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    const urlSearchParams = new URLSearchParams(window.location.search)
    const passcode = urlSearchParams.get('passcode') || '';
    if(passcode) {
      this.callAccountActivationApi(passcode);
    }

    await removeStorageData("authToken")
    await removeStorageData("role")
    window.addEventListener("resize", () => this.changeWidth())
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };
  btnPasswordShowHideProps = {
    onPress: () => {
     this.handleClickShowPassword()
    },
  };
  txtInputEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };
  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };
  btnPasswordShowHideImageProps = {
    source: imgPasswordInVisible,
  };
  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };
  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  changeWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  goToTermAndCondition = () => {
    this.setState({openTermsAndConditions: !this.state.openTermsAndConditions})
  }

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  changeCheckAgree = () => {
    this.setState({checkedAgree: !this.state.checkedAgree})
  } 
    
  handleChangeEmail = (value: string) => {
    this.setState({ email: value, emailError: false, errorMessage: "" })
  }
  handleChangePassword = (value: string) => {
    this.setState({ password: value, passwordError: false })
  }

  validateEmailPassword = () => {
    if(this.state.email.length === 0  || this.state.password.length === 0) {
      this.setState({emailError: true, errorMessage: configJSON.emptyEmailPassword})
      return false;
    }
    if (
      this.state.email === null ||
      this.state.email.length === 0 
    ) {
      this.setState({emailError: true})
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.setState({passwordError: true})
      return false;
    }
    const validEmail = isEmail("", this.state.email)?.status

    if(!validEmail) {
      this.setState({errorMessage: configJSON.invalidAccount, emailError: true});
      return false
    }

    return true
  }

  saveRefreshToken(responseJson: any) {
    if (responseJson?.meta?.refresh_token) {
      setStorageData(configJSON.refreshToken, responseJson.meta.refresh_token)
    }
  }

  goHome = async () => {
    let page = await getStorageData("name")
    let id = await getStorageData("id")
    if (page) {
      removeStorageData("name")
      if (id) {
        removeStorageData("id")
        this.props.navigation.navigate(page, { id })
      }
      else this.props.navigation.navigate(page)
    }
    else this.props.navigation.navigate("DashBoard")
  }

  checkRoleValid = (role: string) => {
    const evermoreUserGroup = ['all access user', 'auditor', 'account manager', 'team leader']
    const clientUserGroup = ['super user', 'regional manager', 'area manager', 'general manager']
   
    return evermoreUserGroup.includes(role) || clientUserGroup.includes(role);
  }

  handleLoginResponse = async (responseJson: any) => {
    if (responseJson?.meta?.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
     
      const role = responseJson.meta.role?.toLowerCase()
      
      if(this.checkRoleValid(role)) {
        this.saveLoggedInUserData(responseJson);
        this.saveRefreshToken(responseJson)
        this.goHome();
        
      }
      else this.setState({isUserInGroup: false})
    } else {
      //Check Error Response
      let errorMessage = this.getErrorFromResponse(responseJson.errors);
      if (errorMessage === "Wrong Password") {
        this.setState({ passwordError: true })
      }
      else this.setState({
        emailError: true,
        errorMessage
      })
      this.sendLoginFailMessage();
    }
  }

  handleAccountActivationResponse = async (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({ isAccountActivationSuccess: true, accountActivationMessage: responseJson?.message })
    } else {
      //Check Error Response
      let errorMessage = this.getErrorFromResponse(responseJson.errors);
      this.setState({
        emailError: true,
        errorMessage
      })
    }
  }

  getErrorFromResponse = (errors: Array<string>) => {
    let errorMessage = '';
    errors.forEach((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (errorMessage.length <= 0) {
            errorMessage = value;
          } else {
            errorMessage = `${errorMessage}{\n}${value}`;
          }
        }
      });
    });
    return errorMessage
  }


  login = () => {
    const isValidLoginValue = this.validateEmailPassword()
    if(isValidLoginValue) this.doLogin()
  } 

  gotoSignUp = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateEmailSignUpMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doLogin = () => {
    const body = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
        },
      },
    };

    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint2
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.loginApiContentType,
      })
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
  }

  callAccountActivationApi(passcode: string) {
    const reqMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountActivationApiCallId = reqMsg.messageId;
    reqMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlAccountActivation + '?token=' + passcode
    );

    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.loginApiContentType,
      })
    );
    reqMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMsg.id, reqMsg);
  }

  handleToggleDialogSuccess = () => {
    this.setState({ isAccountActivationSuccess: !this.state.isAccountActivationSuccess })
    this.gotoLogin()
  }

  gotoLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

     
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          this.handleLoginResponse(responseJson)
        }
        if (apiRequestCallId === this.accountActivationApiCallId) {
          this.handleAccountActivationResponse(responseJson)
        }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
