// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip"
exports.auditApiEndpoint = "bx_block_tasks/audits/audit_list?"
exports.auditDeleteEndpoint ="bx_block_tasks/audits"
exports.auditDeleteMethod = "DELETE";
exports.auditPutMethod = "PUT";
exports.auditEndpoint = "PUT";

exports.statusMenuItems = [
  { value: "in_progress", label: "In Progress" },
  { value: "completed", label: "Completed" },
  { value: "assigned", label: "Assigned" }
];

exports.assignedToMenuItems= [
  { value: "UserA", label: "User A" },
  { value: "UserB", label: "User B" },
  { value: "UserC", label: "User C" }
];
exports.getNameEndpoint = "bx_block_tasks/audits/get_names"
exports.searchApiEndpoint = "bx_block_tasks/audits/search_audits"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.methodGet = "GET"
exports.noAccess = "You don't have permission to access this page. Please contact admin!"
exports.updateItemSuccess = "updated successfully!"
// Customizable Area End
