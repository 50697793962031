import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
const sampleData = {
  audit_id: "#12345",
  account_name: "Mc Donald's",
  location_name: "Lorem Ipsum",
  address: "Lorem Ipsum",
  assigned_to: "Joe Larry",
  date_completed: "03-20-2024",
  in_time: "05:00 PM",
  out_time: "07:00 PM",
  points_given: "50",
  points_applicable: "100",
  pic_name: "Lorem",
  phone: "+123 456 7890",
  email: "billflyn@mail.com"
}
import { curveRec, footerRec, imgBackground } from "./assets";
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{
        background: "#F1F5F9",
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        overflow: 'auto',
        height: '100%',
        padding: 0
      }}>
        <style type="text/css">
          {`
            @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
            td { 
              font-family: 'Montserrat', sans-serif;
              font-size: 16;
              padding: 5px 0
            }
            p, span {
              font-family: 'Montserrat', sans-serif;
            }
          `}
        </style>
        <div style={{ position: 'relative' }}>
          <img style={{ height: 160, width: "100%" }} src={imgBackground} alt="" />
          <img style={{
            height: 160,
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0
          }} src={curveRec} alt="" />
          <img style={{
            width: 150,
            position: "absolute",
            top: 60,
            left: "50%",
            transform: "translateX(-50%)"
          }} src="https://billflynnevermore4-463095-react.b463095.uat.eastus.az.svc.builder.ai/static/media/logo.003509f8.png" alt="avatar-edit-state" />
        </div>
        <div style={{
          margin: "80px 20px",
          boxShadow: "0px 4px 8px 0px #00000033",
          padding: 10,
          borderRadius: 12
        }}>
          <div style={{ border: "1px solid #162A6A", borderRadius: 12 }}>
            <div style={{ background: "linear-gradient(99.09deg, #767BD7 2.64%, #111F50 100%)", borderRadius: 11, padding: 15, textAlign: 'center' }}>
              <span style={{
                fontSize: 30,
                lineHeight: "40px",
                fontWeight: 700,
                color: '#FFFFFF',
              }}>Audit Notification</span>
            </div>
            <div style={{ padding: "20px 10px" }}>
              <div style={{ display: 'table', width: "100%" }}>
                <tr>
                  <td>Audit ID</td>
                  <td>:</td>
                  <td>{sampleData.audit_id}</td>
                </tr>
                <tr>
                  <td>Account Name</td>
                  <td>:</td>
                  <td>{sampleData.account_name}</td>
                </tr>
                <tr>
                  <td>Location Name</td>
                  <td>:</td>
                  <td>{sampleData.location_name}</td>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>:</td>
                  <td>{sampleData.address}</td>
                </tr>
                <tr>
                  <td>Assigned To</td>
                  <td>:</td>
                  <td>{sampleData.assigned_to}</td>
                </tr>
                <tr>
                  <td>Date Completed</td>
                  <td>:</td>
                  <td>{sampleData.date_completed}</td>
                </tr>
                <tr>
                  <td>In-Time</td>
                  <td>:</td>
                  <td>{sampleData.in_time}</td>
                </tr>
                <tr>
                  <td>Out-Time</td>
                  <td>:</td>
                  <td>{sampleData.out_time}</td>
                </tr>
                <tr>
                  <td>PIC Name</td>
                  <td>:</td>
                  <td>{sampleData.pic_name}</td>
                </tr>
              </div>
              <table style={{ border: "1px solid #64748B", boxShadow: "0px 4px 4px 0px #00000040", borderRadius: 50, margin: '20px auto' }}>
                <tr>
                  <td style={{ background: "linear-gradient(99.09deg, #767BD7 2.64%, #111F50 100%)", textAlign: 'center', padding: 10, borderRadius: 50, width: 'fit-content' }}>
                    <span style={{ fontSize: 16, color: 'white', lineHeight: '24px', fontWeight: 700 }}>
                      Points Given: {sampleData.points_given}</span>
                  </td>
                  <td style={{ background: "#E5E5FF", padding: 10, borderRadius: 50, textAlign: 'center', width: 'fit-content', border: "1px solid #A0A2FF" }}>
                    <span style={{ fontSize: 16, color: '#64748B', lineHeight: '24px', fontWeight: 700 }}>
                      Points Applicable: {sampleData.points_applicable}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div style={{ background: `url(${footerRec}) no-repeat top`, backgroundSize: 'cover', alignContent: 'center', minHeight: 130, height: 130, width: "100%" }}>
          <table style={{ margin: '0 auto', padding: "0 20px" }}>
            <tr>
              <td >
                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                  <div style={{ padding: 5, borderRadius: 200, border: '2px solid white' }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 8C13.7956 8 14.5587 8.31607 15.1213 8.87868C15.6839 9.44129 16 10.2044 16 11C16 11.2652 16.1054 11.5196 16.2929 11.7071C16.4804 11.8946 16.7348 12 17 12C17.2652 12 17.5196 11.8946 17.7071 11.7071C17.8946 11.5196 18 11.2652 18 11C18 9.67392 17.4732 8.40215 16.5355 7.46447C15.5979 6.52678 14.3261 6 13 6C12.7348 6 12.4804 6.10536 12.2929 6.29289C12.1054 6.48043 12 6.73478 12 7C12 7.26522 12.1054 7.51957 12.2929 7.70711C12.4804 7.89464 12.7348 8 13 8Z" fill="white" />
                      <path d="M13 4C14.8565 4 16.637 4.7375 17.9497 6.05025C19.2625 7.36301 20 9.14349 20 11C20 11.2652 20.1054 11.5196 20.2929 11.7071C20.4804 11.8946 20.7348 12 21 12C21.2652 12 21.5196 11.8946 21.7071 11.7071C21.8946 11.5196 22 11.2652 22 11C22 8.61305 21.0518 6.32387 19.364 4.63604C17.6761 2.94821 15.3869 2 13 2C12.7348 2 12.4804 2.10536 12.2929 2.29289C12.1054 2.48043 12 2.73478 12 3C12 3.26522 12.1054 3.51957 12.2929 3.70711C12.4804 3.89464 12.7348 4 13 4ZM21.75 15.91C21.695 15.7495 21.6 15.6056 21.4741 15.4919C21.3481 15.3781 21.1953 15.2984 21.03 15.26L15.03 13.89C14.8671 13.8531 14.6976 13.8575 14.5369 13.9029C14.3762 13.9484 14.2294 14.0333 14.11 14.15C13.97 14.28 13.96 14.29 13.31 15.53C11.1532 14.5362 9.42499 12.8009 8.44 10.64C9.71 10 9.72 10 9.85 9.85C9.96671 9.73056 10.0516 9.58379 10.0971 9.42308C10.1425 9.26238 10.1469 9.09286 10.11 8.93L8.74 3C8.70165 2.8347 8.62186 2.68187 8.50815 2.55591C8.39443 2.42995 8.25054 2.335 8.09 2.28C7.85647 2.19659 7.61531 2.1363 7.37 2.1C7.11725 2.0414 6.85933 2.0079 6.6 2C5.38 2 4.20998 2.48464 3.34731 3.34731C2.48464 4.20998 2 5.38 2 6.6C2.00529 10.6827 3.62949 14.5967 6.5164 17.4836C9.40332 20.3705 13.3173 21.9947 17.4 22C18.0041 22 18.6022 21.881 19.1603 21.6498C19.7184 21.4187 20.2255 21.0798 20.6527 20.6527C21.0798 20.2255 21.4187 19.7184 21.6498 19.1603C21.881 18.6022 22 18.0041 22 17.4C22.0003 17.1455 21.9802 16.8913 21.94 16.64C21.898 16.3916 21.8344 16.1474 21.75 15.91ZM17.4 20C13.8469 19.9974 10.4401 18.5847 7.92769 16.0723C5.41528 13.5599 4.00265 10.1531 4 6.6C4.00263 5.91125 4.27741 5.25146 4.76443 4.76443C5.25146 4.27741 5.91125 4.00263 6.6 4H6.93L8 8.64L7.46 8.92C6.6 9.37 5.92 9.73 6.28 10.51C6.86622 12.1696 7.81477 13.6777 9.05674 14.9249C10.2987 16.172 11.8029 17.1269 13.46 17.72C14.3 18.06 14.63 17.43 15.08 16.56L15.37 16.01L20 17.07V17.4C19.9974 18.0888 19.7226 18.7485 19.2356 19.2356C18.7485 19.7226 18.0888 19.9974 17.4 20Z" fill="white" />
                    </svg>
                  </div>
                </div>
                <div style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                  <span style={{ fontSize: 18, color: 'white', lineHeight: '28px', fontWeight: 700 }}>
                    {sampleData.phone}
                  </span>
                </div>
              </td>
              <td style={{ verticalAlign: 'middle' }}>
                <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                  <div style={{ padding: 5, borderRadius: 200, border: '2px solid white' }}>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.66536 2.75H18.332C19.8507 2.75 21.082 3.98131 21.082 5.5V16.5C21.082 18.0187 19.8507 19.25 18.332 19.25H3.66536C2.14668 19.25 0.915363 18.0187 0.915363 16.5V5.5C0.915363 3.98131 2.14668 2.75 3.66536 2.75ZM3.66536 4.58333C3.55949 4.58333 3.45774 4.60121 3.36332 4.63444L10.9987 10.7429L18.6341 4.63444C18.537 4.60043 18.4349 4.58315 18.332 4.58333H3.66536ZM3.66536 17.4167H18.332C18.5751 17.4167 18.8083 17.3201 18.9802 17.1482C19.1521 16.9763 19.2487 16.7431 19.2487 16.5V6.49046L11.5714 12.6324C11.4041 12.7664 11.2015 12.8333 10.9987 12.8333C10.7959 12.8333 10.5933 12.7664 10.426 12.6324L2.7487 6.49046V16.5C2.7487 16.7431 2.84527 16.9763 3.01718 17.1482C3.18909 17.3201 3.42225 17.4167 3.66536 17.4167Z" fill="white" />
                    </svg>
                  </div>
                </div>
                <div style={{ verticalAlign: 'middle', display: 'table-cell', wordBreak: 'break-word' }}>
                  <span style={{ color: 'white', fontSize: 18, lineHeight: '28px', fontWeight: 700 }}>
                    {sampleData.email}</span>
                </div>
              </td>
            </tr>
          </table>

        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
