Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.dashboard = "Dashboard";
exports.pendingAudit = "Pending Audits";
exports.auditCompleted ="Audits Completed this Quarter";
exports.openCaps = "Open CAPs"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.completedAudit = "Completed Audits"
exports.openCorrectiveActionPlan = "Open Corrective Action Plans"
// Customizable Area End