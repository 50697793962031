import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isEmail, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  invalidCapitalLetter: boolean;
  invalidLowercaseLetter: boolean;
  invalidNumber: boolean;
  invalidEmail: boolean;
  invalidLength: boolean;
  invalidName: boolean;
  invalidReTypePassword: boolean;
  invalidPassword: boolean;
  invalidSym: boolean;
  fullName: string
  isSignupSuccess: boolean;
  btnChecked: boolean;
  errorMsg: string;
  showErrorToast: boolean
  btnCheckedPolicy: boolean
  openTermsAndConditions: boolean
  openPrivacyPolicy: boolean
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      fullName: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      invalidCapitalLetter: false,
      invalidLowercaseLetter: false,
      invalidNumber: false,
      invalidEmail: false,
      invalidLength: false,
      invalidName: false,
      invalidReTypePassword: false,
      invalidPassword: false,
      invalidSym: false,
      isSignupSuccess: false,
      btnChecked: false, 
      errorMsg: "",
      showErrorToast: false,
      btnCheckedPolicy: false,
      openTermsAndConditions: false,
      openPrivacyPolicy: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.createAccountApiCallId) {
          this.handleSignUpResponse(responseJson)
        }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    await removeStorageData('authToken')
    await removeStorageData('role')
  }
  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };
  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };
  txtInputEmailPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  }
  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  btnPasswordShowHideProps = {
    onPress: () => {
      this.onShowHidePassword()
    }
  };
  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.btnPasswordShowHideProps.onPress()
    }
  };
  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };
  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };
  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };
  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };
  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  getValidations = () => {
    this.validateData()
  }
  handleSignUpResponse = async (responseJson: any) => {
    if (responseJson?.meta?.token) {
      this.setState({isSignupSuccess: true})
      await setStorageData("email", this.state.email)
    } else {
      //Check Error Response
      let errorMsg = this.getError(responseJson.errors);
       this.setState({
        showErrorToast: true,
        errorMsg
      })
    }
  }

  getError = (listErrors: Array<string>) => {
    let errMsg = '';
    listErrors.forEach((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (errMsg.length <= 0) {
            errMsg = value;
          } else {
            errMsg = `${errMsg}{\n}${value}`;
          }
        }
      });
    });
    return errMsg
  }

  setAgreeTermAndCondition = () => {
    this.setState({ btnChecked: !this.state.btnChecked });
  };
  setAgreePolicy = () => {
    this.setState({ btnCheckedPolicy: !this.state.btnCheckedPolicy });
  }
  handleButtonConfirm = () => {
    this.setState({isSignupSuccess: !this.state.isSignupSuccess})
    this.props.navigation.navigate("AccountActivation")
  }
  goToLogin = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  goToTermsAndCondition = () => {
    this.setState({openTermsAndConditions: !this.state.openTermsAndConditions})
  }
  goToPrivacyPolicy = () => {
    this.setState({openPrivacyPolicy: !this.state.openPrivacyPolicy})
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0 || str === undefined;
  }

  validateData = () => {
    if (this.isStringNullOrBlank(this.state.fullName)) {
      this.setState({ invalidName: true, showErrorToast: true, errorMsg: configJSON.pleaseFill });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.email)) {
      this.setState({ invalidEmail: true, showErrorToast: true, errorMsg: configJSON.pleaseFill });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.password)) {
      this.setState({ invalidPassword: true, showErrorToast: true, errorMsg: configJSON.pleaseFill });
      return false;
    }
    if (this.isStringNullOrBlank(this.state.reTypePassword)) {
      this.setState({ invalidReTypePassword: true, showErrorToast: true, errorMsg: configJSON.pleaseFill });
      return false;
    }
    this.setState({showErrorToast: false})

    if (!isEmail("",this.state.email).status) {
      this.setState({ invalidEmail: true });
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.setState({ invalidReTypePassword: true });
      this.setState({ invalidPassword: true });
      return false;
    }
    return true
  }

  createAccount = () => {
    const isValidData = this.validateData();
    if(!isValidData) return false

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      full_name: this.state.fullName,
      email: this.state.email,
      password: this.state.password,
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: attrs,
      },
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onShowHidePassword = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
  }

  onChangeEmail = (text: string) => {
    this.setState({ email: text, invalidEmail: false });
  }
  onChangeFullName = (text: string) => {
    this.setState({ fullName: text, invalidName: text.length > 0 });
  }

  onChangeConfirmPassword = (text: string) => {
    this.setState({ reTypePassword: text, invalidPassword: false, invalidReTypePassword: false });

  }

  onChangePassword = (text: string) => {
    this.setState({ password: text, invalidPassword: false, invalidReTypePassword: false });

    if (text) {
      const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      const checkLength = text.length >= 8;
      const checkExistNumber = /\d/.test(text);
      const checkOneCapital = /[A-Z]/.test(text);
      const checkOneLowercase = /[a-z]/.test(text);
      const checkSym = regex.test(text);

      this.setState({
        invalidCapitalLetter: !checkOneCapital, 
        invalidLowercaseLetter: !checkOneLowercase, 
        invalidNumber: !checkExistNumber,
        invalidLength: !checkLength, 
        invalidSym: !checkSym
      });
    }
  }
  // Customizable Area End
}
