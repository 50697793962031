import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const config = require("../../../framework/src/config.js")
const statusMapping = {'Assigned': 'assigned', 'Completed': 'completed', 'Under Review': 'under_review'}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  openDrawerFilter: boolean
  reportTableData: Array<any>
  width: number
  fromDate: Date | null
  toDate: Date | null
  listStatus: ['Assigned', 'Under Review', 'Completed']
  listAuditNumber: Array<string>
  listRegionName: Array<string>
  listLocationName: Array<string>
  listDepartmentName: Array<string>
  selectedFormName: Array<string>
  selectedStatus: Array<string>
  selectedAuditNumber: Array<string>
  selectedRegionName: Array<string>
  selectedLocationName: Array<string>
  selectedDepartment: Array<string>
  listAuditor: Array<any>
  listAccountName: Array<any>
  selectedAuditor: any
  selectedAccountName: any
  currentPage: number
  totalPage: number
  isLoading: boolean
  isLoadingFilterAudit: boolean
  canLoadMoreFilterAudit: boolean
  currentFilterAuditPage: number
  disableExportButton: boolean
  user: {
    id: string, 
    role: string,
    group: string
  }
  listFormName: Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      openDrawerFilter: false,
      reportTableData: [],
      width: window.innerWidth,
      fromDate: null,
      toDate: null,
      listStatus: ['Assigned', 'Under Review', 'Completed'],
      listAuditNumber: [],
      listRegionName: [],
      listLocationName: [],
      listDepartmentName: [],
      selectedFormName: [],
      selectedStatus: [],
      selectedAuditNumber: [],
      selectedRegionName: [],
      selectedLocationName: [],
      selectedDepartment: [],
      listAccountName: [],
      listAuditor: [],
      selectedAuditor: [],
      selectedAccountName: [],
      currentPage: 1,
      totalPage: 1,
      isLoading: true,
      isLoadingFilterAudit: false,
      canLoadMoreFilterAudit: true,
      currentFilterAuditPage: 1,
      disableExportButton: false,
      user: {
        id: "",
        role: "",
        group: ""
      },
      listFormName: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson?.errors){
        this.handleError(responseJson.errors)
      } else {
        if(apiCallId === this.getTableDataCallId){
          this.handleTableData(responseJson)
        } 
         else {
          this.handleFilterDataResponse(apiCallId, responseJson)
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    await this.getUserRole()
    this.getTableData(this.state.currentPage)
    window.addEventListener("resize", () => {
      this.setState({width: window.innerWidth})
    })
   
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  getTableDataCallId: string = ""
  getAuditorNameId: string = ""
  getAccountNameId: string = ""
  getAuditNumberId: string = ""
  getRegionNameId: string = ""
  getLocationNameId: string = ""
  getDepartmentNameId: string = ""
  getUserRoleCallId: string = ""
  getFormNameId: string = ""

  async componentWillUnmount(){
    window.removeEventListener("resize", () => {this.setState({width: window.innerWidth})})
  }
  apiCall = async (params: {endpoint: string, method: string}) => {
    const token = await getStorageData('authToken');
    const {endpoint, method} = params

    const header = {
      "Content-Type": 'application/json',
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }
  getTableData = async (currentPage: number) => {
    this.getTableDataCallId = await this.apiCall({
      endpoint: `${configJSON.getReportingEndpoint}?page=${currentPage}`,
      method: configJSON.methodGet
    })
  }
  getFilterData = async () => {
    this.getFormNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: configJSON.getFormNameEndpoint
  })
    this.getAuditorNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.filterAccountEndpoint}?attribute_value=assigned_to`,
    })
    this.getAccountNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.filterAccountEndpoint}?attribute_value=account_name`
    })
    this.getRegionNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.getFilterDataEndpoint}?attribute_value=regions`
    })
    this.getLocationNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.getFilterDataEndpoint}?attribute_value=locations`
    })
    this.getDepartmentNameId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.getFilterDataEndpoint}?attribute_value=departments`
    })
    this.getFilterAudit(1)
  }

  getUserRole = async () => {
    this.getUserRoleCallId = await this.apiCall({
      method: configJSON.methodGet, 
      endpoint: `${configJSON.getUserRoleEndpoint}`
    })
  }

  getDataWithFilter = async (page: number, endpoint: string ) => {
    this.getTableDataCallId = await this.apiCall({
      endpoint: `${configJSON.getReportingEndpoint}?page=${page}${endpoint}`,
      method: configJSON.methodGet
    })
  }

  getFilterAudit = async (currentPage: number) => {
    this.getAuditNumberId = await this.apiCall({
      endpoint: `${configJSON.getReportingEndpoint}?page=${currentPage}`,
      method: configJSON.methodGet
    })
  }

  handleError = (listErrors: Array<object | string>) => {
    const errList: { [key: string]: string } = {};
    listErrors.forEach((errors: Object) => {
      errList[Object.keys(errors)[0]] = Object.values(errors)[0]
    });
    if (errList['token']) this.goLogin()
  }

  goLogin = async () => {
    await setStorageData("name", "Reporting")
    this.props.navigation.navigate("EmailAccountLoginBlock")
}

  getUserGroup = (role: string) => {
    const evermoreUserGroup = ['All Access User', 'Auditor', 'Account Manager', 'Team Leader']
    const clientUserGroup = ['Super User', 'Regional Manager', 'Area Manager', 'General Manager']
    if(evermoreUserGroup.includes(role)) return 'evermore'
    if(clientUserGroup.includes(role)) return 'client'
    return 'regular'
}

  handleFilterDataResponse = (apiCallId: string, responseJson: any) => {
    if (apiCallId === this.getFormNameId) {
      const listFormName = responseJson.data.map((formName: any) => {return formName.name})
      this.setState({ listFormName: listFormName })
    }
    if(apiCallId === this.getUserRoleCallId) {
      this.setState({user: {...responseJson.meta, group: this.getUserGroup(responseJson.meta.role)}}, () => {
       if(this.state.user.group !== 'evermore') this.setState({selectedStatus: ['Completed']})
       this.getFilterData()
      })
    }
    if(apiCallId === this.getAuditorNameId) {
      const listAuditor = responseJson.data.map((auditor: any) => {
        return {id: auditor.id, full_name: auditor.attributes?.full_name, email: auditor.attributes?.email}})
      this.setState({listAuditor: [...listAuditor]})
    }
    if (apiCallId === this.getAccountNameId) {
      const listAccountName = responseJson.data.map((accountName: any) => {
        return { id: accountName.id, full_name: accountName.attributes?.full_name, email: accountName.attributes?.email }
      })
      this.setState({ listAccountName: [...listAccountName] })
    }
    if(apiCallId === this.getAuditNumberId) {
      const listAuditNumber = responseJson.audits.data.map((item: any) => item.id)
      const canLoadMoreFilterAudit = Math.ceil(responseJson.totalRecords/10) > this.state.currentFilterAuditPage
      this.setState({listAuditNumber: [...this.state.listAuditNumber, ...listAuditNumber], 
        isLoadingFilterAudit: false, canLoadMoreFilterAudit })
    }
    if(apiCallId === this.getRegionNameId) {
      this.setState({listRegionName: [...responseJson.data]})
    }
    if(apiCallId === this.getLocationNameId) {
      this.setState({listLocationName: [...responseJson.data]})
    }
    if(apiCallId === this.getDepartmentNameId) {
      this.setState({listDepartmentName: [...responseJson.data]})
    }
  }

  handleTableData = (responseJson: any) => {
    if(responseJson.audits){
      this.setState({
        reportTableData: [...responseJson.audits.data],
        isLoading: false, 
        totalPage: Math.ceil(responseJson.totalRecords/responseJson.recordsPerPage),
        disableExportButton: responseJson.audits.data?.length === 0
      })
    } 
  }
  onScroll = (title: string, event: any) => {
    const bottom = (event.target.scrollHeight - event.target.scrollTop) === event.target.clientHeight;
    if( bottom && this.state.canLoadMoreFilterAudit && title===configJSON.auditNumber && !this.state.isLoadingFilterAudit) {
      this.setState({isLoadingFilterAudit: true, currentFilterAuditPage: this.state.currentFilterAuditPage + 1}, async () => {
        await this.getFilterAudit(this.state.currentFilterAuditPage)
      })
    }
  }

  handleCancelFilter = async () => {
    this.setState({
      selectedAccountName: [],
      selectedAuditNumber: [],
      selectedAuditor: [],
      selectedDepartment: [],
      selectedFormName: [],
      selectedLocationName: [],
      selectedRegionName: [],
      selectedStatus: [],
      currentPage: 1
    })
    await this.getTableData(1)
  }

  getFilterEndpoint = () => {
    const {selectedAccountName,
      selectedAuditNumber,
      selectedAuditor,
      selectedDepartment,
      selectedLocationName,
      selectedRegionName,
      selectedStatus,
      selectedFormName} = this.state
    let endpoint = ""
    for(let item in selectedFormName) endpoint+=`&filter[audit_type][]=${selectedFormName[item]}`

    selectedAuditNumber.forEach((auditNum: string) => endpoint+=`&filter[audit_number][]=${auditNum}`)
    selectedRegionName.forEach((region: string) => endpoint+=`&filter[region_name][]=${region}`)
    selectedLocationName.forEach((location: string) => endpoint+=`&filter[location_name][]=${location}`)
    selectedDepartment.forEach((department: string) => endpoint+=`&filter[department_name][]=${department}`)
    for(let item in selectedStatus) endpoint+=`&filter[status][]=${statusMapping[selectedStatus[item] as keyof typeof statusMapping]}`
    for(let item in selectedAuditor) endpoint+=`&filter[assigned_to][]=${selectedAuditor[item].full_name}`
    for(let item in selectedAccountName) endpoint+=`&filter[account_name][]=${selectedAccountName[item].full_name}`
    return endpoint
  }

  handleFilterData = async (page: number) => {
    const endpoint = this.getFilterEndpoint()
    
    this.setState({currentPage: page, openDrawerFilter: false})
    await this.getDataWithFilter(page, endpoint)
  }
  downloadCsv = async () => {
    const endpoint = this.getFilterEndpoint()
    const token = await getStorageData("authToken")
    window.open(`${config.baseURL}/${configJSON.getReportingEndpoint}/export_csv?token=${token}${endpoint}`)

  }
  removeDateRangePicker = async () => {
    this.setState({fromDate: null, toDate: null})
    await this.handleFilterData(1)
  }
  isDisabledFilterButton = () => {
    const {selectedAccountName,
      selectedAuditNumber,
      selectedAuditor,
      selectedDepartment,
      selectedFormName,
      selectedLocationName,
      selectedRegionName,
      selectedStatus,} = this.state
    return selectedAccountName.length === 0 && selectedAuditNumber.length === 0 && selectedAuditor.length === 0 
      && selectedDepartment.length === 0 && selectedFormName.length === 0 && selectedLocationName.length === 0
      && selectedRegionName.length === 0 && selectedStatus.length === 0 
  }
  onChangePage = (currentPage: number) => {
    this.handleFilterData(currentPage)
    this.setState({currentPage})
  }
  onChangeAuditor = (auditor: any) => {
    this.setState({selectedAuditor: auditor}, () => {if(auditor.length === 0) this.handleFilterData(1)})
  }
  onChangeAccountName = (accountName: any) => {
    this.setState({selectedAccountName: accountName}, () => {if(accountName.length === 0) this.handleFilterData(1)})
  }
  onChangeFormType = (type: string) => {
    let {selectedFormName} = this.state;
    selectedFormName = this.onChangeSelectedValue(selectedFormName, type)
    this.setState({selectedFormName: [...selectedFormName]})
  }
  onChangeStatus = (status: string) => {
    let {selectedStatus} = this.state;
    selectedStatus = this.onChangeSelectedValue(selectedStatus, status)
    this.setState({selectedStatus: [...selectedStatus]})
  }
  onChangeAuditNumber = (selectedItem: string) => {
    let {selectedAuditNumber} = this.state;
    selectedAuditNumber = this.onChangeSelectedValue(selectedAuditNumber, selectedItem)
    this.setState({selectedAuditNumber: [...selectedAuditNumber]})
  }
  onChangeRegionName = (selectedItem: string) => {
    let {selectedRegionName} = this.state;
    selectedRegionName = this.onChangeSelectedValue(selectedRegionName, selectedItem)
    this.setState({selectedRegionName: [...selectedRegionName]})
  }
  onChangeLocationName = (selectedItem: string) => {
    let {selectedLocationName} = this.state;
    selectedLocationName = this.onChangeSelectedValue(selectedLocationName, selectedItem)
    this.setState({selectedLocationName: [...selectedLocationName]})
  }
  onChangeDepartmentName = (selectedItem: string) => {
    let {selectedDepartment} = this.state;
    selectedDepartment = this.onChangeSelectedValue(selectedDepartment, selectedItem)
    this.setState({selectedDepartment: [...selectedDepartment]})
  }
  onChangeSelectedValue = (listSelected: any, selectedItem: any) => {
    if(!listSelected.includes(selectedItem)) listSelected.push(selectedItem)
    else {
      const itemIdx = listSelected.indexOf(selectedItem);
      listSelected.splice(itemIdx, 1)
    }
    return listSelected
  }
  handleChangeFromDate = async (fromDate: Date) => {
    const {toDate} = this.state
    this.setState({fromDate})
    if(toDate) {
      const endpoint = `&from=${fromDate}&to=${toDate}`
      await this.getDataWithFilter(1, endpoint)
    }
  }
  handleChangeToDate = async (toDate: Date) => {
    const {fromDate} = this.state
    this.setState({toDate})
    if(fromDate) {
      const endpoint = `&from=${fromDate}&to=${toDate}`
      await this.getDataWithFilter(1, endpoint)
    }
  }
  findingStatusStyle = (status: string) => {
    let color, backgroundColor, text="no status", smallText;
    if(status === 'assigned'){
      color = "#DC2626";
      backgroundColor = "#FEE2E2"
      text = "Assigned"
      smallText = "A"
    }

    if(status === 'under_review'){
      color = "#D97706";
      backgroundColor = "#FEF3C7"
      text = "Under Review"
      smallText = "P"
    }

    if(status === 'completed'){
      color = "#059669";
      backgroundColor = "#D1FAE5"
      text = "Completed"
      smallText = "C"
    }
    return {color, backgroundColor, text, smallText}
  }
  toggleDrawerFiler = () =>{
    this.setState({openDrawerFilter: !this.state.openDrawerFilter})
  }
  dayRavenue = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userStatisticAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  dayAudience = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.audienceFollowersAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    console.log(
      "@@@ Request user Analytics ===========",
      requestMessage,
      this.apiGetDataCallId
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
