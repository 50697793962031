import React from "react";

// Customizable Area Start
import { CSSProperties } from "react";
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  Dialog,
  DialogContent
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import { backgroundImg, featureImg, logo } from "./assets";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

export const config = require("./config");
const styles = {
  pe0: {
    paddingRight: 0
  },
  ps0: {
    paddingLeft: 0
  },
  container: {
    display: "flex", 
    height: "auto",
    minHeight: "100vh"
  },
  logo: {
    marginTop: 50
  },
  sloganTitle: {
    fontSize: 36, 
    lineHeight: "44px", 
    fontWeight: 700,
    color: "#fff",
    textTransform: "uppercase",
    textAlign: "center",
    fontFamily: "'Montserrat', sans-serif"
  },
  sloganContent: {
    fontSize: 18, 
    fontWeight: 400, 
    lineHeight: "26px", 
    color: "#fff",
    textAlign: "center",
    fontFamily: "'Montserrat', sans-serif"
  },
  labelTitle: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "32px",
    marginBottom: 20
  },
  formLabel: {
    fontWeight: 700,
    lineHeight: "24px", 
    fontSize: 16,
    color: "#334155",
  },
  normalText: {
    fontWeight: 400, 
    fontSize: 16,
    lineHeight: "22px"
  },
  btnLogin: {
    padding: 16,
    borderRadius: 8,
    fontWeight: 700, 
    color: "#fff",
    fontSize: 16
  },
  inputStyle: {
    border: "1px solid #CBD5E1", 
    padding: 10, 
    borderRadius: 8,
    marginTop: 10
  },
  errorText: {
    color: config.colorError,
    fontSize: 12, 
    marginTop: 5,
    fontFamily: "'Montserrat', sans-serif"
  },
  forgotPassword: { 
    fontSize: 14, 
    color: config.colorPrimary, 
    fontWeight: 700, 
    cursor: "pointer"
  },
  errorToastContainer: { 
    backgroundColor: "#FEE2E2", 
    marginTop: 10, 
    position: "relative", 
    borderRadius: 8 
  },
  errorToastContent: { 
    width: 5, 
    height: "100%", 
    background: config.colorError, 
    position: "absolute", 
    borderRadius: "8px 0 0 8px" 
  },
  flexBox: {
    display: "flex",
    alignItems: "center"
  },
  successText: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#0F172A"
  },
  iconClose: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "#334155",
    cursor: "pointer"
  }
}


const newTheme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "'Montserrat', sans-serif"
      },
      body1: {
        fontFamily: "'Montserrat', sans-serif"
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: "'Montserrat', sans-serif !important"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Montserrat', sans-serif"
      }
    }
  }
})

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
  errorToast = () => {
    return this.state.emailError &&
      <Box sx={{ width: "100%" }} style={styles.errorToastContainer as React.CSSProperties}>
        <Box style={styles.errorToastContent as React.CSSProperties}></Box>
        <Typography style={{ ...styles.errorText, marginTop: 0, padding: "12px 16px" }}>
          {this.state.errorMessage}
        </Typography>
      </Box>
  }

  DialogSuccess = () => {
    return <Dialog fullWidth open={this.state.isAccountActivationSuccess} onClose={this.handleToggleDialogSuccess}>
        <DialogContent dividers>
            <CloseIcon onClick={this.handleToggleDialogSuccess} style={styles.iconClose as CSSProperties} />
            <Box style={{ ...styles.flexBox, flexDirection: "column", marginTop: 20, marginBottom: 30 }}>
                <CheckCircleOutlineIcon style={{ color: "#34D399", height: 50, width: 50 }} />
                <Typography align="center" style={{ ...styles.successText, marginTop: 10 }}>
                    {this.state.accountActivationMessage}</Typography>
            </Box>
        </DialogContent>
    </Dialog>
}

  UserNotInGroupUI = () => {
    return <Typography style={{...styles.labelTitle, marginTop: 40, width: '80%'}}>{config.notInGroup}</Typography>
  }

  CommonUi = () => {
    return  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      padding: "10px 0px",
      margin: "0 auto",
      width: this.state.width > 576 ? "52%" : "80%"
    }}
    style={this.state.width < 960 ? {
      position: "relative",
      top: 0,
      transform: "none",
      marginBottom: 20
    } : {
      position: "relative",
    }
    }
  >
    <Typography style={styles.labelTitle}>
      {this.labelTitle}
    </Typography>
    {this.errorToast()}

    <Box sx={{ width: "100%" }}>
      <Box sx={{ padding: "20px 0px" }}>
        <Typography style={styles.formLabel}>{config.placeHolderEmail}</Typography>
        <Input
          data-test-id="txtInputEmail"
          placeholder={"Your email"}
          fullWidth={true}
          value={this.state.email}
          onChange={(e) => {this.handleChangeEmail(e.target.value)}}
          style={{ ...styles.inputStyle }}
          disableUnderline
        />
      </Box>
      <Box sx={{ padding: "5px 0px" }}>
        <Typography style={styles.formLabel}>{config.placeHolderPassword}</Typography>
        <Input
          data-test-id="txtInputPassword"
          type={this.state.enablePasswordField ? "password" : "text"}
          placeholder={"Your password"}
          fullWidth={true}
          value={this.state.password}
          onChange={(e) => this.handleChangePassword(e.target.value)}
          style={{ ...styles.inputStyle, borderColor: this.state.passwordError ? config.colorError : "#CBD5E1" }}
          disableUnderline
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                data-test-id="btn-show-hide-password"
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                edge="end"
              >
                {this.state.enablePasswordField ? (
                  <VisibilityOutlined style={{ color: "#94A3B8" }} />
                ) : (
                  <VisibilityOffOutlined style={{ color: "#94A3B8" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {this.state.passwordError && <Typography style={{ ...styles.errorText }}>{config.wrongPassword}</Typography>}
      </Box>
      <Box sx={{ ...styles.flexBox, justifyContent: "space-between", marginBottom: 40, marginTop: 5 }}>
        <Box sx={{ ...styles.flexBox }}>

        </Box>
        <Box
          data-test-id={"btnForgotPassword"}
          onClick={() => this.goToForgotPassword()}
        >
          <Typography style={{...styles.normalText, ...styles.forgotPassword}}>{config.forgotPassword}</Typography>
        </Box>
      </Box>
      <Box sx={{ ...styles.flexBox, marginBottom: 20 }}>
        <Checkbox
          data-test-id={"btnRememberMe"}
          onClick={this.changeCheckAgree}
          checked={this.state.checkedAgree}
          style={{ padding: "0 5px 0 0", color: this.state.checkedAgree ? config.colorPrimary : "#64748B" }}
          inputProps={{ color: "transparent" }}
        />{" "}
        <Typography component={"span"} style={{ ...styles.normalText, fontSize: 14, color: "#0F172A" }}>
          {config.byLogin}
          <Typography onClick={this.goToTermAndCondition} component={"span"} style={{ ...styles.normalText, cursor: "pointer", fontSize: 14, fontWeight: 700, color: config.colorPrimary }}>
            {config.termsAndCondition}
          </Typography>
        </Typography>

      </Box>

      <Box
        sx={{
          ...styles.flexBox,
          flexDirection: "column",
          padding: "10px 0px",
          marginBottom: 40
        }}
      >
        <Button
          data-test-id={"btnEmailLogIn"}
          variant="contained"
          style={!this.state.checkedAgree ? {
            ...styles.btnLogin,
            backgroundColor: "#94A3B8"
          } : {
            ...styles.btnLogin,
            background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",

          } as React.CSSProperties}
          fullWidth
          onClick={() => this.login()}
          disabled={!this.state.checkedAgree}
        >
          {config.btnLogin}
        </Button>
      </Box>
      <Box sx={{...styles.flexBox, marginBottom: 20}}>
        <Typography component={"span"} style={{ ...styles.normalText, marginRight: 10 }}>
          {config.newMember}
          <Typography
            component={"span"}
            data-test-id="btn-signup"
            onClick={this.gotoSignUp} 
            style={{
              ...styles.normalText,
              cursor: "pointer",
              fontWeight: 700, color: config.colorPrimary
            }}>
            {config.signUp}
          </Typography>
        </Typography>

      </Box>
    </Box>
  </Box>
  }

 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={newTheme}>
        <Container maxWidth={false} style={{ ...styles.pe0, ...styles.ps0, ...styles.container }}>
          <Grid container style={{ ...styles.container }}>
            <Grid item md={6} sm={12} style={{...styles.flexBox, width: "100%", flexDirection: "column"}}>
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <img src={logo} alt="" style={styles.logo as React.CSSProperties} />
              </Box>
              {!this.state.isUserInGroup? 
              this.UserNotInGroupUI() :
              this.CommonUi()}
            </Grid>
            <Grid item md={6} sm={12} style={{ ...styles.container }}>
              <Box
                sx={{
                  ...styles.flexBox,
                  flexDirection: "column",
                  width: "100%"
                }}
              >
                <div
                  style={{
                    ...styles.flexBox,
                    width: "100%", height: "100%", backgroundImage: `url(${backgroundImg})`,
                    backgroundColor: "rgb(55, 107, 174)",
                    backgroundRepeat: " no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    justifyContent: "center",
                  }}>
                  <img style={{maxWidth: "80%"}} src={featureImg} alt="" />
                </div>

              </Box>
            </Grid>
          </Grid>
          {this.DialogSuccess()}
        </Container>
        <TermsConditions
          id="terms-and-condition"
          navigation={this.props.navigation}
          isOpen={this.state.openTermsAndConditions}
          type="termsConds"
          onClose={this.goToTermAndCondition} />
      </ThemeProvider>
       // Customizable Area End
    );
  }
}
