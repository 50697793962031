// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum"; 
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
import { sortArrowAscending, sortArrowDescending, sortArrow } from './assets'

export interface Props {
    // Customizable Area Start
    id: string
    navigation: any;
    
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    isSuccessDialog: boolean
    showSortOptions: boolean;
    dropdownValues: {
    auditNumber: string;
    accountNumber: string;
    status: string;
    assignedTo: string;
    dateCreated: string;
    dateCompleted: string;
  };
  isVisible:boolean;
  tooltipId:null | number ;
  audits: Array<AuditDetails>;
  currentPage: number;
  todosPerPage: number;
  auditNumber:string;
  accountNumber:string;
  auditStatus:string;
  assignedTo:string;
  createdDateSort:string;
  completedDateSort:string;
  fromDateFilter:string;
  fromDateFilter1: Date | null;
  toDateFilter:string;
  toDateFilter1: Date | null;
  isMobile:boolean;
  isLoading:boolean;
  isVisibleModal: boolean;
  token:string;
  listAuditorName: Array<any>;
  paginationObj:any;
  pageStartIndex: number;
  searchTerm: string | undefined;
  auditRecord:any ;
  auditEditStatus:string;
  auditEditAssignTo: any;
  isDialogBox:boolean;
  responseJsonObj:any;
  isSearched: boolean;
  userRole: {id: string, role: string, group: 'client' | 'evermore' | ''}
  isRoleLoading: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
interface AuditDetails  {
  attributes :{
    audit_number: number,
    account_name: string,
    status: string,
    assigned_to: string,
    date_created: string,
    date_completed: string,
  },
  id:string,
  type:string
}
interface AuditorDetails {
  id: string
}
interface AuditListApiResponse {
  audits: Audits
  recordsPerPage: number
  currentPage: number;
  errors?: Array<string>
}
interface Audits {
  data: Array<AuditDetails>
}
// Customizable Area End

export default class AuditListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    newAuditApiCallId :any = ""
    deleteEditAuditApiCallId :any= ""
    getListAuditorId: string = ""
    searchAuditListId: string = ""
    getUserRoleId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);   

        // Customizable Area Start
        this.state = {
          isSuccessDialog:false,
          showSortOptions: false,
          userRole: {
            id: "",
            role: "",
            group: ""
          },
          dropdownValues: {
              auditNumber: "",
              accountNumber: "",
              status: "",
              assignedTo: "",
              dateCreated: "",
              dateCompleted: "",
          },
          isVisible: false,
          tooltipId:null,
          audits:[],
          currentPage: 1,
          todosPerPage: 10,
          auditNumber:"",
          accountNumber:"",
          auditStatus:"",
          assignedTo:"",
          createdDateSort:"",
          completedDateSort:"",
          fromDateFilter:"",
          fromDateFilter1: null,
          toDateFilter:"",
          toDateFilter1: null,
          isMobile: window.innerWidth <= 768,
          isLoading:true,
          isVisibleModal: false,
          token:"",
          listAuditorName: [],
          paginationObj:"",
          pageStartIndex: 0,
          searchTerm: "",
          auditRecord:"",
          auditEditStatus:'',
          auditEditAssignTo:{
            id: '0',
          },
          isDialogBox:false,
          responseJsonObj:"",
          isSearched: false,
          isRoleLoading: true
        };
        this.timeout = null;
        // Customizable Area End
    }
    // Customizable Area Start
    
    async componentDidMount() {
      super.componentDidMount();
      await this.getUserRole()
      window.addEventListener('resize', this.handleResize);
    }

    getAllInformation = async () => {
      const searchTerm = await getStorageData('searchTerm');
      if(searchTerm) {
        this.setState({
          searchTerm: searchTerm
        })
      }

      const pathname = window.location.pathname;
      if(pathname.includes('search')) {
        this.setState({
          isSearched: true,
        })
        this.searchAudits(this.state.searchTerm)
      } else {
        this.handleGetAuditList()
      }

      await this.getDropdownData()
    }

    getDropdownData = async () => {
      this.getListAuditorId = await this.apiCall({
          method: configJSON.validationApiMethodType, 
          endpoint: `${configJSON.getNameEndpoint}?attribute_value=auditors`
      })
    }

    apiCall = async (reqData: {method: string, endpoint: string, body?: {}}) => {
      const token = await getStorageData('authToken')
      const {endpoint, method, body} = reqData
      const reqMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      reqMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endpoint
      );

      reqMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              "Content-Type": 'application/json',
              token
          })
      );
      reqMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
      );
      reqMsg.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      );
      runEngine.sendMessage(reqMsg.id, reqMsg);
      return reqMsg.messageId
    }

    handleResize = () => {
      this.setState({ isMobile: window.innerWidth <= 600 });
    };

    async componentWillUnmount() {
      await setStorageData('searchTerm', '')
    }

    getUserRole = async () => {
      this.getUserRoleId = await this.apiCall({
        endpoint: configJSON.getUserRoleEndpoint, 
        method: configJSON.methodGet
      })
    }
  
    timeout: ReturnType<typeof setTimeout> | null = null;

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
              );
        
              let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
              );
              
              if(apiCallId == this.newAuditApiCallId || apiCallId === this.searchAuditListId)  {
                this.handleAuditListApiResponse(responseJson)
              }
              if(apiCallId == this.deleteEditAuditApiCallId){
                this.handleEditDeleteApiResponse(responseJson)
              }
              if (apiCallId === this.getListAuditorId) {
                if (!responseJson.errors) {
                  this.setState({ listAuditorName: responseJson.data })
                } else {
                    this.handleResponseErr(responseJson.errors)
                }
            }
              if(apiCallId === this.getUserRoleId) {
                this.handleGetUserRoleResponse(responseJson)
              }
        }
    }

    handleAuditListApiResponse = (responseJson: AuditListApiResponse) => {
      if (!responseJson.errors) {
        this.setState({
          isLoading: false,
          paginationObj: responseJson,
          audits: responseJson?.audits?.data,
          todosPerPage: responseJson?.recordsPerPage,
          currentPage: responseJson?.currentPage
        })
      } else {
          this.handleResponseErr(responseJson.errors)
      }
    }

    handleEditDeleteApiResponse = (responseJson: AuditListApiResponse) => {
      if (!responseJson.errors) {
        this.hideModal()
        this.handleGetAuditList()
        this.setState({responseJsonObj: responseJson, isDialogBox: true},()=>{
          this.handleToCloseModel()
        })
      } else {
          this.handleResponseErr(responseJson.errors)
      }
    }

    handleResponseErr = (errors: Array<any>) => {
      const errorList: {[key: string] : string} = {};

      Array.isArray(errors) && errors.forEach((error: Object) => {
          errorList[Object.keys(error)[0]] = Object.values(error)[0]
      });
      
      if(errorList['token']) {
          removeStorageData("authToken"); 
          this.goLogin()
      }
      else {
          this.setState({
              isSuccessDialog: true,
              isLoading: false,
          });
          setTimeout(()=>{this.setState({isSuccessDialog: false})}, 2000)
      }
  }

  
  goLogin = () => {
      this.props.navigation.navigate("EmailAccountLoginBlock")
  }

    handleGetUserRoleResponse = async (responseJson: any) => {
      if(responseJson.meta) {
        const evermoreUserGroup = ['all access user', 'auditor', 'account manager', 'team leader']
        const role = responseJson.meta.role?.toLowerCase();
        const group = evermoreUserGroup.includes(role) ? 'evermore' : 'client'
        this.setState({userRole: {...responseJson.meta, group}, isRoleLoading: false})

        if(group === 'evermore') {
          await this.getAllInformation()
        }

      } else {
        const {errors} = responseJson
        const errorList: {[key: string] : string} = {};
        Array.isArray(errors) && errors.forEach((error: Object) => {
            errorList[Object.keys(error)[0]] = Object.values(error)[0]
        });
        if(errorList['token']) this.props.navigation.navigate("EmailAccountLoginBlock")
      }
    }

    setShowSortOptions() {
      this.setState((prevState) => ({
        showSortOptions: !prevState.showSortOptions,
      }));
    }
    
    handleGetAuditList = () => {
      const requestMessage = this.createRequestMessage();
      const apiEndPoint = "";

      this.sendRequest(requestMessage, configJSON.auditApiEndpoint + apiEndPoint );
    };

    handleSearchAuditList = async () => {
      this.searchAuditListId = await this.apiCall({
        method: configJSON.validationApiMethodType, 
        endpoint: `${configJSON.searchApiEndpoint}?search_query=${this.state.searchTerm}`
      })
    };

    handleSearchList = (value: string, filterType: string) => {
      const requestMessage = this.createRequestMessage();
      let filteredCustomEndpoint = "";
      this.sendRequest(requestMessage, `${configJSON.auditApiEndpoint}${filteredCustomEndpoint}` );
    };

    handleFromGetFilterByDate = (searchType: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const inputValue = event.target.value as string;
      const { toDateFilter } = this.state
      this.setState({
        fromDateFilter:inputValue,
        currentPage: 1
      },()=>{
        if ( toDateFilter ){
          this.handleSendRquest()
        }
      })
    };

    handleToGetFilterByDate = (searchType: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const inputValue = event.target.value as string;
      this.setState({
        toDateFilter:inputValue,
        currentPage: 1
      },()=>{
        this.handleSendRquest()
      }
      )
    };

    handleSendRquest = () => {
      if(this.state.fromDateFilter && this.state.toDateFilter ){
        this.setState({isLoading:true})
      }
      
      const requestMessage = this.createRequestMessage();
  
      this.timeout = setTimeout(() => {
        const filterWithFromAndToDate = `${"from"}=${this.state.fromDateFilter}&${"to"}=${this.state.toDateFilter}`;
        this.sendRequest(requestMessage, `${configJSON.auditApiEndpoint}${filterWithFromAndToDate}` );
      }, 2500);
    }

    handleSortByDate = (value: string,dateType:string) => {
      this.setState({isLoading:true})
      const requestMessage = this.createRequestMessage();

      let paramUrl=""
      if (dateType === "dateCreated") {
        this.setState({
          createdDateSort: value,
          completedDateSort:"",
          assignedTo:"",
          currentPage: 1
        });
        paramUrl = `sort_by=date_created_${value}`;
      } else {
        this.setState({
          completedDateSort: value,
          createdDateSort:"",
          assignedTo:"",
          currentPage: 1
        });
        paramUrl = `sort_by=date_completed_${value}`;
      }
      this.sendRequest(requestMessage, `${configJSON.auditApiEndpoint}${paramUrl}` );
    };
  
    createRequestMessage = () => {
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.newAuditApiCallId = requestMessage.messageId;
      return requestMessage;
    };

    deleteEditRequestMessage = () => {
      const deleteRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.deleteEditAuditApiCallId = deleteRequestMessage.messageId;
      return deleteRequestMessage;
    };

  
    sendRequest = async (requestMessage: Message, endpoint: string) => {
      const token = await getStorageData('authToken')
    
      let multipleSortSearch = ""
      const { auditNumber, accountNumber, auditStatus, assignedTo,
        createdDateSort, completedDateSort, fromDateFilter, toDateFilter, currentPage, todosPerPage } = this.state
      let queryParams: string[] = [];
      let paramMappings = [
        { key: 'from', value: fromDateFilter },
        { key: 'to', value: toDateFilter },
        { key: 'filter[status]', value: auditStatus },
        { key: 'filter[audit_number]', value: auditNumber },
        { key: 'filter[account_name]', value: accountNumber },
        { key: 'per', value: todosPerPage },
        { key: 'page', value: currentPage }

      ];
    
      if (assignedTo) {
        const tempValue = assignedTo == "assigned_to_asc" ? "asc" : "desc"
        paramMappings.push({
            key: 'sort_by', value: `assigned_to_${tempValue}` 
        });
    
      } else if (createdDateSort) {
        const tempValue = createdDateSort == "date_created_asc" ? "asc" : "desc"
        paramMappings.push({
           key: 'sort_by', value: `date_created_${tempValue}` 
        });
    
      } else if (completedDateSort) {
        const tempValue = completedDateSort == "date_completed_asc" ? "asc" : "desc"
        paramMappings.push({
            key: 'sort_by',
            value: `date_completed_${tempValue}`
        });
      }
    
      paramMappings.forEach(param => {
        if (param.value) {
          queryParams.push(`${param.key}=${encodeURIComponent(param.value)}`);
        }
      });
    
      const multipleUrlFlag = queryParams.length > 0 
      multipleSortSearch = multipleUrlFlag ? `${configJSON.auditApiEndpoint + queryParams.join('&')}` : endpoint;
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), multipleSortSearch);
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token
      };
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  
    clearTimeOut = ()=> {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    }
    
    handleTrigerSearchListFuction  = ( value:string ,fieldType:string) =>{
      this.setState({isLoading:true})
      this.timeout = setTimeout(() => {
        
        if (value) {
          this.handleSearchList(value, fieldType);
        }
        else {
          this.handleGetAuditList()
        }
      }, 2500);
    }

    handleChange = (fieldType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value.replace(/\D/g, '');
      this.clearTimeOut()
      this.setState({auditNumber:value, currentPage: 1}, () => {
        this.handleTrigerSearchListFuction(value,fieldType)
      });
    };

    handleTextChange = (fieldType: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value.replace(/[^a-zA-Z]/g, '');
      this.clearTimeOut()
      this.setState({accountNumber:value, currentPage: 1}, () => {
        this.handleTrigerSearchListFuction(value,fieldType)
      });
    }
    handleGetStatusChange = (fieldType: string) => (event: React.ChangeEvent<{ value: unknown; }>) => {
      const value = event.target.value as string;
      this.clearTimeOut()
      this.setState({auditStatus:value, currentPage: 1}, () => {
        this.handleTrigerSearchListFuction(value,fieldType)
      });
    }
    handleGetSortAssignTo =(value: string) => {
      this.setState({isLoading:true ,createdDateSort:"",completedDateSort:""})
      const requestMessage = this.createRequestMessage();
      const assignedToParam = `sort_by=assigned_to_${value}`;
      this.setState({
        assignedTo: value,
        currentPage: 1
      });
      this.sendRequest(requestMessage, `${configJSON.auditApiEndpoint}${assignedToParam}` );
    }

    handleGetSortIcon = () => {
      const { createdDateSort  } = this.state;
      if (createdDateSort === 'date_created_asc' ) {
        return sortArrowAscending;
      }
      if (createdDateSort === 'date_created_desc') {
        return sortArrowDescending;
      }
      return sortArrow;
    };

    handleGetSortIcon2 = () => {
      const { completedDateSort  } = this.state;
      if (completedDateSort === 'date_completed_asc' ) {
        return sortArrowAscending;
      }
      if (completedDateSort === 'date_completed_desc') {
        return sortArrowDescending;
      }
      return sortArrow;
    };

    handleGetSortIconAssingto = () => {
      const { assignedTo  } = this.state;
      if (assignedTo === 'assigned_to_asc' ) {
        return sortArrowAscending;
      }
      if (assignedTo === 'assigned_to_desc') {
        return sortArrowDescending;
      }
      return sortArrow;
    };

    paginationButton = (direction: string,onPage:number) => {
      const { currentPage, paginationObj, todosPerPage } = this.state;
      const totalPages = Math.ceil(paginationObj?.totalRecords / todosPerPage);
    
      let newPage = currentPage;
      let param =""
    
      if (direction === "next" && currentPage < totalPages) {
        newPage += 1;
      } else if (direction === "prev" && currentPage > 1) {
        newPage -= 1;
      } else if( direction == "currentPage") {
        newPage = onPage;
        param = `per=${todosPerPage}&page=${onPage}`
      }
      
      this.setState({ currentPage: newPage, isLoading:true },()=>{
        const requestMessage = this.createRequestMessage();
         param = `per=${todosPerPage}&page=${newPage}`
        this.sendRequest(requestMessage, `${configJSON.auditApiEndpoint}${param}` );
      });
    };

    capitalizeFirstCharacter = (str:string) =>  {
      if (!str) {
        return str; 
      }
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    formatDateToDDMMYYYY(dateString:string) {
      if(dateString){
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }
      
    }
    hideModal = () => {
      this.setState({ isVisibleModal: !this.state.isVisibleModal });
    };
    
    showAddModal = (event: React.MouseEvent<HTMLElement, MouseEvent>, auditData:AuditDetails) => {
      event?.stopPropagation()
      this.setState({
         auditRecord:auditData,
         auditEditAssignTo: {...auditData.attributes, full_name: auditData.attributes.assigned_to},
         auditEditStatus: auditData.attributes.status
      },()=>{
        this.setState({ isVisibleModal: !this.state.isVisibleModal})
      });
    };

    searchAudits = (search: string | undefined) => {
      this.setState({
        searchTerm: search
      }, () => {
        this.handleSearchAuditList()
      })

    }

    gotoAuditInfo = (id: string) => {
      this.props.navigation.navigate("AuditInfo", {id})
    }
    
    onChangSelectEdit = ( value: string) => {
      this.setState({auditEditStatus:value })
   }

   onChangSelectEdit1 = ( value: any) => {
        this.setState({auditEditAssignTo:value })

    }

    sendAuditRequest = async (endpoint: string, method: any, type: 'edit'|'delete', body?: Object)=>  {
      const token = await getStorageData('authToken')
      const requestMessage = this.deleteEditRequestMessage();
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token,
      };
  
      requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
      requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

      if(type === 'edit') {
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      );
      }
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleEditSave = () => {
      const { auditRecord, auditEditStatus, auditEditAssignTo } = this.state;
      const editSaveParam = `${configJSON.auditDeleteEndpoint}/${auditRecord.id}`;
      const body = {
        audit: {
            auditor_id: auditEditAssignTo?.id,
            status: auditEditStatus
        }
    }
      this.sendAuditRequest(editSaveParam, configJSON.auditPutMethod, 'edit', body);
    }
  
    handleDeleteAudit = () => {
      const { auditRecord } = this.state;
      const deleteAuditParam = `${configJSON.auditDeleteEndpoint}/${auditRecord.id}`;
      this.sendAuditRequest(deleteAuditParam, configJSON.auditDeleteMethod, 'delete', {});
    }

    
  addOneDay = (date:string) =>  {
    if(date){
      let result = new Date(date);
      if(!isNaN(result.getTime())){
        result.setDate(result.getDate() + 1);
        return result.toISOString().split('T')[0]; 
      }
    }
    return date;
  };                    

  removeOneDay = (date:string) => {
    if(date){
      let result = new Date(date);
      if(!isNaN(result.getTime())){
        result.setDate(result.getDate() - 1);
        return result.toISOString().split('T')[0]; 
      }
    }
    return date;
  };

  handleToCloseModel=()=>{
    const timeout = 2000
    setTimeout(() => {
      this.setState({isDialogBox:false})
    }, timeout);
  }

  goToAuditInfo = (auditNo: number) => {
    this.props.navigation.navigate('AuditInfo', {
      id: auditNo
    })
  }

  handleDateFilterChange = (date: Date | null, field: 'to' | 'from') => {
    const year = date ? date.getFullYear() : ''
    const month = date ? date.getMonth() + 1 : ''
    const day = date ? date.getDate() : ''
    const newDate = `${year}-${month}-${day}`;

    if(field === 'to') {
      this.setState({
        toDateFilter1: date,
        toDateFilter: newDate,
        currentPage: 1
      },()=>{
          this.handleSendRquest()
      })
    } else {
      this.setState({
        fromDateFilter1: date,
        fromDateFilter: newDate,
        currentPage: 1
      },()=>{
          this.handleSendRquest()
      })
    }
  }
    // Customizable Area End
}
// Customizable Area End
