// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: '8px 8px 32px 8px'
            },
            root: {
                fontFamily: "'Montserrat', sans-serif !important"
            },
        },
        MuiTypography: {
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            },
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'start',
                padding: "18px 18px 30px 18px"
            }
        }
    }
});

import PrivacyPolicyController, {
    Props,
    configJSON,
} from "./PrivacyPolicyController";

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
    }

    SettingItem = (key: string, title: string, subTitle: string) => {
        return <Box key={key}>
            <CustomTypo className="title">{title}</CustomTypo>
            <CustomTypo className="sub-title">{subTitle}</CustomTypo>
        </Box>
    }

    render() {
        return (
            <Header navigation={this.props.navigation} >
                <ThemeProvider theme={theme}>
                    <PrivacyPolicyContainer maxWidth={false}>
                        <Box className="content-container">
                            <Typography className="heading">{configJSON.PrivacyPolicy}</Typography>
                            <Typography className="heading" style={{ marginTop: 10 }}>{configJSON.security}</Typography>
                            <Box style={{ ...styles.flexBox, flexDirection: 'column', gap: 20 }}>
                                {this.SettingItem('two-factor', configJSON.twoFactor, configJSON.sampleText)}
                                {this.SettingItem('secondary-verify', configJSON.secondaryVerification, configJSON.sampleText)}
                                {this.SettingItem('backup-code', configJSON.backupCode, configJSON.sampleText)}
                                {this.SettingItem('purchase-noti', configJSON.purchaseNotif, configJSON.sampleText)}
                                {this.SettingItem('delete-account', configJSON.deleteAccount, configJSON.sampleText)}
                            </Box>
                           
                        </Box>
                    </PrivacyPolicyContainer>
                </ThemeProvider>
            </Header>
        );
    }
}

const styles = {
    flexBox: {
        display: 'flex',
        marginBottom: 20
    },
    dlgContent: {
        flexDirection: "column",
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    successMsg: {
        lineHeight: "32px",
        fontWeight: 700,
        fontSize: 24,
        color: "#0F172A",

    },
    closeIcon: {
        color: "#334155",
        cursor: "pointer",
        top: 10,
        right: 10,
        position: "absolute",
    }
}

const CustomTypo = styled(Typography)({
    color: '#0F172A',

    "&.title": {
        fontSize: 20,
        lineHeight: '28px',
        fontWeight: 700,
    },
    "&.sub-title": {
        fontSize: 18,
        lineHeight: '30px',
        fontWeight: 400,
    },
    "@media (max-width: 600px)": {
        "&.sub-title": {
            lineHeight: '28px',
            fontSize: 16,
        },
        "&.title": {
            fontSize: 18,
            lineHeight: '26px'
        },
    },
    "@media (max-width: 475px)": {
        "&.sub-title": {
            lineHeight: '24px',
            fontSize: 14,
        },
        "&.title": {
            fontSize: 16,
            lineHeight: '22px'
        },
    }
})

const PrivacyPolicyContainer = styled(Container)({
    background: "#F1F5F9",
    height: '100%',
    paddingTop: 30,
    paddingBottom: 30,
    overflowY: 'auto',

    "& .content-container": {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        padding: "25px 20px",
        boxSizing: 'border-box',
        background: '#FFFFFF',
        borderRadius: 8,
        border: "1px solid #CBD5E1",
        minHeight: '100%',
        height: 'auto',
    },
    "& .heading": {
        fontSize: 24,
        fontWeight: 700,
        color: '#0F172A',
        lineHeight: "32px",
    },
    "@media (max-width: 600px)": {
        "& .content-container": {
            flexDirection: 'column',
            display: 'flex',
        },
    },
    "@media (max-width: 475px)": {
        "& .heading": {
            fontSize: 20,
        }
    }
})
// Customizable Area End
