// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ForgotPasswordConfirmation from "../../blocks/forgot-password/src/ForgotPasswordConfirmation.web"
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Contactus from "../../blocks/contactus/src/CustomContactUs.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emaildeliverability from "../../blocks/emaildeliverability/src/Emaildeliverability";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Report from "../../blocks/customform/src/Report.web"
import Header from "../../blocks/navigationmenu/src/Header.web"
import DashBoard from "../../blocks/dashboard/src/Dashboard.web"
import AuditAllocator from "../../blocks/tasks/src/AuditAllocator.web"
import AuditList1  from "../../blocks/formapprovalworkflow/src/AuditList.web"
import StatusTracker from "../../blocks/tasks/src/StatusTracker.web"
import CorrectiveActionPlan from "../../blocks/tasks/src/CorrectiveActionPlan.web"
import AuditInfo from "../../blocks/tasks/src/AuditInfo.web"
import NotificationSettings from "../../blocks/settings2/src/NotificationSettings.web"
import TermsConditionSettings from "../../blocks/settings2/src/TermsCondition.web"
import PrivacyPolicy from "../../blocks/settings2/src/PrivacyPolicy.web"
import ConductAudit from "../../blocks/tasks/src/ConductAudit.web"
import ChangePassword from "../../blocks/settings2/src/ChangePassword.web"
import AccountActivation from "../../blocks/email-account-registration/src/AccountActivation.web"
import AuditList from "../../blocks/tasks/src/AuditList.web";

const routeMap = {
  AccountActivation: {
    component: AccountActivation, 
    path: '/account-activation'
  },
  ConductAudit:{
    component: ConductAudit,
   path:"/audit-allocator/conduct-audit/:id"},
   AuditReportRules: {
    component: ConductAudit,
    path:"/audit-allocator/audit-report-rules/:id"
   },
  Notification:{
    component: NotificationSettings,
   path:"/settings/notification"},
  ChangePassword:{
    component:ChangePassword,
   path:"/settings/change-password"},
  PrivacyPolicy:{
    component:PrivacyPolicy,
   path:"/settings/privacy-policy"},
  TermsConditionSettings:{
    component:TermsConditionSettings,
   path:"/settings/terms-condition"},
  AuditList1: {
    component: AuditList1,
    path: '/audit-list-1'
  },
  AuditList: {
    component: AuditList,
    path: '/audit-list',
    exact: true
  },
  Search: {
    component: AuditList,
    path: '/audit-list?search=:key',
    exact: true
  },
  StatusTracker: {
    component: StatusTracker,
    path: '/audit-allocator/status-tracker/:id',
    exact: true
  },
  AuditInfo: {
    component: AuditInfo,
    path: '/audit-allocator/audit-info/:id',
    exact: true
  },
  CAP: {
    component: CorrectiveActionPlan,
    path: '/audit-allocator/corrective-action-plan/:id',
    exact: true
  },
  AuditAllocator: {
    component: AuditAllocator, 
    path: '/audit-allocator',
    exact: true
  },
  AuditorAssignments: {
    component: Header,
    path: '/auditor-assignments'
  },
  Reporting: {
    component: VisualAnalytics,
    path: '/reporting'
  },
  DashBoard: {
    component: DashBoard,
    path: '/dashboard'
  },
CriticalForm: {
component: Report, 
path: "/form/:id"
},
NonCriticalForm: {
  component: Report, 
path: "/food-safety-inspection-report/non-critical"
},
SafetyCulture: {
  component: Report, 
path: "/food-safety-inspection-report/safety-culture"
},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/forgotten-password"},
ForgotPasswordConfirmation: {
  component:ForgotPasswordConfirmation,
  path:"/password-reset-link"
},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/update-password"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/email-notification"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings:{
 component:Settings2,
path:"/settings/account"},
Contactus:{
 component:Contactus,
path:"/contact-us"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emaildeliverability:{
 component:Emaildeliverability,
path:"/Emaildeliverability"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  SearchAudits: {
    component: AuditList,
    path: '/search',
    exact: true
  },
  AccountActivation:{
    component: EmailAccountLoginBlock,
    path:"/login/:passcode"
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;