// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    // Customizable Area Start
    email: string,
    isLoading: boolean,
    isSent: boolean
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordConfirmationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    resendCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
            // Customizable Area End
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start


        this.state = {
            email: "",
            isLoading: false,
            isSent: false
        };
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        const email = await getStorageData("email")
        email && this.setState({ email }) 
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.resendCallId) {
                if (responseJson.message) {
                    this.setState({ isLoading: false, isSent: true })
                }
            }
        }
    }

    gotoLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    resend = () => {
        this.setState({isLoading: true, isSent: false})
        const body = {
            data: {
                type: "email_account",
                attributes: {
                    email: this.state.email,
                },
            },
        };

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.resendCallId = reqMsg.messageId;
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.resendEndpoint
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.resendContentType,
            })
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.resendMethod
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );

        runEngine.sendMessage(reqMsg.id, reqMsg);
    }
    // Customizable Area End
}
// Customizable Area End
