// Customizable Area Start
import React from "react";

import {
    ThemeProvider,
    Typography,
    createTheme,
    styled,
    Container,
    Box,
    Drawer,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from "@material-ui/core";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import CloseIcon from '@material-ui/icons/Close';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';

const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiPaper: {
            rounded: {
                borderRadius: "8px 8px 32px 8px"
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative"
            }
        },
        MuiDrawer: {
            root: {
                "@media (min-width: 960px)": {
                    display: "none",
                }
            }
        },
        MuiContainer: {
            root: {
                '&#main': {
                    "@media (max-width: 959px)": {
                        display: "none !important",
                    }
                },
                '&#small': {
                    borderRadius: "0 !important",
                    height: '100%'
                }
            }
        }
    }
})


import SidebarController, {
    Props,
    configJSON,
} from "./SidebarController";
import { icHome, icSettings } from "./assets";
import CustomContactUs from "../../contactus/src/CustomContactUs.web";

export default class Sidebar extends SidebarController {
    constructor(props: Props) {
        super(props);
    }

    isActiveTab = (activeTab: number) => {
        return this.state.activeTab === activeTab ? 'active' : ''
    }

    SettingSubmenu = () => {
        const role = this.props.userInfo.role?.toLowerCase()
        const isShowNotification = role === 'all access user' || role === 'super user'

        return this.state.activeTab === 3 &&
            <Box style={{ borderLeft: '1px solid #bfc8d3', margin: '0 10px' }}>
                {isShowNotification && 
                <AuditBox data-test-id="tab-noti" onClick={this.gotoNotification} className={this.checkActiveSubmenu(this.state.activeSubSetting, "notification")}>
                    <TypographyContent>{configJSON.notificationSettings}</TypographyContent>
                </AuditBox>}
                <AuditBox data-test-id="tab-account" onClick={this.gotoSettings} className={this.checkActiveSubmenu(this.state.activeSubSetting, 'account', 'change-password')}>
                    <TypographyContent>{configJSON.accountSettings}</TypographyContent>
                </AuditBox>
                {(this.state.activeSubSetting === "account" || this.state.activeSubSetting === 'change-password')
                 && <AuditBox style={{paddingLeft: 20}} data-test-id="tab-change-password" onClick={this.gotoChangePassword} className={this.checkActiveSubmenu(this.state.activeSubSetting, 'change-password')}>
                    <TypographyContent>{configJSON.changePassword}</TypographyContent>
                </AuditBox>}
                
                <AuditBox data-test-id="tab-privacy" onClick={this.gotoPrivacy} className={this.checkActiveSubmenu(this.state.activeSubSetting, "privacy")}>
                    <TypographyContent>{configJSON.privacy}</TypographyContent>
                </AuditBox>
                <AuditBox data-test-id="tab-terms" onClick={this.gotoTermsCondition} className={this.checkActiveSubmenu(this.state.activeSubSetting, "terms")}>
                    <TypographyContent>{configJSON.terms}</TypographyContent>
                </AuditBox>
            </Box>
    }

    Content = (itemId?: string) => {
        const role = this.props.userInfo.role?.toLowerCase()
        const name = this.props.userInfo.fullName
        const evermore = ['all access user', 'account manager', 'team leader', 'auditor']
        const group = evermore.includes(role) ? 'evermore' : 'client'
        return <Container id={itemId} maxWidth={false} style={styles.mainContainer as React.CSSProperties}>
            <TabGroup>
                <Tab data-test-id="tab-dashboard" onClick={this.gotoDashboard} className={this.isActiveTab(0)}>
                    <img src={icHome} style={{ marginRight: 10 }} />
                    <TypographyContent>{configJSON.dashboard}</TypographyContent>
                </Tab>
                
                <Tab data-test-id="tab-auditor-assignment" onClick={this.openAuditor} className={this.isActiveTab(1)}>
                    <DescriptionOutlinedIcon style={{ marginRight: 10 }} />
                    <TypographyContent>{configJSON.auditorAssignments}</TypographyContent>
                    {(!this.state.openAuditor)? <ArrowRightRoundedIcon /> : <ArrowDropDownRoundedIcon /> }
                </Tab>
                {(this.state.openAuditor) &&
                    <Box style={{ borderLeft: '1px solid #FFFFFF', margin: '0 10px' }}>
                        {role === 'all access user' &&
                        <AuditBox data-test-id="tab-allocator" onClick={this.gotoAuditAllocator} className={this.state.activeSubAuditor === 'audit-allocator' ? "active" : ''}>
                            <TypographyContent>{configJSON.auditAllocator}</TypographyContent>
                        </AuditBox>}
                        <AuditBox data-test-id="tab-list" onClick={this.gotoAuditList} className={this.state.activeSubAuditor === 'audit-list' ? "active" : ''}>
                            <TypographyContent>{configJSON.auditList}</TypographyContent>
                        </AuditBox>
                    </Box>}
                <Tab data-test-id="tab-reporting" onClick={this.gotoReporting} className={this.isActiveTab(2)}>
                    <ListAltOutlinedIcon style={{ marginRight: 10 }} />
                    <TypographyContent>Reporting & Analytics</TypographyContent>
                </Tab>
                <Tab style={{display: 'flex', justifyContent: 'space-between'}} data-test-id="tab-settings" onClick={this.gotoSettings} className={this.isActiveTab(3)}>
                    <Box style={styles.flexBox}>
                        <img src={icSettings} style={{ marginRight: 10 }} />
                        <TypographyContent>{configJSON.settings}</TypographyContent>
                    </Box>
                    {this.state.activeTab !== 3? <ArrowRightRoundedIcon /> : <ArrowDropDownRoundedIcon /> }
                </Tab>
                {this.SettingSubmenu()}
                <Tab data-test-id="tab-logout" className={this.isActiveTab(4)} onClick={this.onToggleConfirmDialog}>
                    <ExitToAppOutlinedIcon style={{ marginRight: 10 }} />
                    <TypographyContent>{configJSON.logout}</TypographyContent>
                </Tab>
                <ContactUs onClick={this.toggleContactUs}>
                    <ContactSupportOutlinedIcon />
                    <TypographyContent style={{ fontWeight: 700 }}>{configJSON.contactUs}</TypographyContent>
                </ContactUs>
            </TabGroup>

            <UserBox style={{ ...styles.flexBox }}>
                <PermIdentityIcon style={{ marginRight: 10, color: '#FFFFFF' }} />
                <TypographyContent style={{ fontWeight: 700 }}>{name}</TypographyContent>
            </UserBox>
        </Container>
    }

    DialogConfirm = () => {
        return <Dialog fullWidth onClose={this.onToggleConfirmDialog} open={this.state.isOpenConfirmDialog} >
            <DialogContent>
                <CloseIcon style={styles.closeIcon as React.CSSProperties} onClick={this.onToggleConfirmDialog} />
                <Box style={{...styles.dlgContent, marginBottom: 20} as React.CSSProperties}>
                    <Typography align="center" style={{ ...styles.successMsg, marginTop: 10, textAlign: 'start' }}>
                        {configJSON.confirmLogoutMessage}</Typography>
                </Box>
            </DialogContent>
            <StyledDialogActions>
                <CustomButton data-test-id="btn-logout" onClick={this.onLogout}>
                    <Typography>{configJSON.logout}</Typography>
                </CustomButton>
                <CancelButton data-test-id="btn-cancel" onClick={this.onToggleConfirmDialog}>
                    <Typography>{configJSON.cancel}</Typography>
                </CancelButton>
            </StyledDialogActions>
        </Dialog>
    }

    render() {
        return <ThemeProvider theme={theme}>
            <Drawer anchor="right" open={this.props.isOpenDrawer} onClose={this.props.toggleOpenDrawer}>
                {this.Content('small')}
            </Drawer>
            {this.Content('main')}
            {this.DialogConfirm()}
            <CustomContactUs navigation={this.props.navigation} openContactUs={this.state.openContactUs} toggleContactUs={this.toggleContactUs} />
        </ThemeProvider>

    }
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    logo: {
        width: 63
    },
    mainContainer: {
        position: 'relative',
        background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
        width: 212,
        minWidth: 212,
        borderRadius: '0 0 64px 0',
        padding:"50px 10px",
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        overflowY: 'auto',
        overflowX: 'hidden',
        boxSizing: 'content-box'
    },
    dlgContent: {
        flexDirection: "column",
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 24
    },
    successMsg: {
        lineHeight: "32px",
        fontWeight: 700,
        fontSize: 24,
        color: "#0F172A",
    },
    closeIcon: {
        color: "#334155",
        cursor: "pointer",
        top: 10,
        right: 10,
        position: "absolute",
    }
}

const UserBox = styled(Box)({
    justifyContent: 'center',
    position: 'absolute',
    bottom: 20,
    alignSelf: 'center',
    '& p' : {
        color: '#FFFFFF',
    },
    "@media (min-width: 960px)": {
        display: 'none !important'
    },
})

const TabGroup = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    overflowY: 'auto',
    overflowX: 'hidden'
})

const Tab = styled(Box)({
    background: "#E5E5FF",
    border: '1px solid #CBD5E1',
    padding: '10px 8px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 8,
    color: '#64748B',
    "&.active": {
        background: '#FFFFFF',
        '& p': {
            fontWeight: 700,
            color: '#0F182A',
        }
    }
})

const AuditBox = styled(Box)({
    padding: "3px 10px",
    cursor: 'pointer',
    marginTop: 10, 
    marginBottom: 10,
    whiteSpace: 'nowrap',
    '& p': {
        color: '#FFFFFF', 
        fontSize: 14, 
        lineHeight: "22px"
    },
    "&.active": {
        borderLeft: '1px solid #FFFFFF',
        '& p': {
            fontWeight: 700
        }
    }
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    color: '#0F172A',
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography

const CancelButton = styled(Button)({
    padding: 16,
    backgroundColor: '#E5E5FF',
    borderRadius: 8,
    minWidth: 113,
    "& p": {
        color: "#000000",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: 16,
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})

const CustomButton = styled(Button)({
    background: "linear-gradient(90deg, rgba(118,123,215,1) 0%, rgba(17,31,80,1) 100%)",
    padding: 16,
    borderRadius: 8,
    width: 'fit-content',
    minWidth: 113,

    "& p": {
        color: "#FFFFFF",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: 16,
    },
    "@media (max-width: 600px)": {
        alignSelf: 'center',
        "& p": {
            fontSize: 14
        }
    }
})

const StyledDialogActions = styled(DialogActions)({
    display: 'flex',
    justifyContent: 'start',
    margin: '0px 0px 40px 40px'
})

const ContactUs = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    color: '#FFFFFF',
    cursor: 'pointer',
    padding: 10, 
    '& p' : {
        color: '#FFFFFF',
    },
})
// Customizable Area End
