import React from "react";
// Customizable Area Start
import { Box, ThemeProvider, createTheme, Container, styled, Typography, CircularProgress } from "@material-ui/core";
import Header from "../../../blocks/navigationmenu/src/Header.web";
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const theme = createTheme({
  overrides: {
      MuiTypography: {
          root: {
              fontFamily: "'Montserrat', sans-serif"
          },
          body1: {
              fontFamily: "'Montserrat', sans-serif"
          }
      },
      MuiInputBase: {
          input: {
              fontFamily: "'Montserrat', sans-serif !important"
          }
      },
      MuiDialogContent: {
          root: {
              position: "relative"
          }
      }
  }
})
import { icDocument } from "./assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  EvermoreDashboard = () => {
    return <DashboardContainer maxWidth={false}>
      <Heading>{webConfigJSON.dashboard}</Heading>
      <Tab data-test-id="btn-go-to-pending-audit" onClick={() => this.gotoAuditListWithStatus(["Assigned", "Under Review"])}>
        <img src={icDocument} alt="" />
        <Box>
          <Typography className="title">{webConfigJSON.pendingAudit}</Typography>
          <Typography className="content">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolorem</Typography>
        </Box>
      </Tab>
      <Tab data-test-id="btn-go-to-completed-audit" onClick={() => this.gotoAuditListWithStatus(["Completed"])}>
        <ListAltRoundedIcon style={{ height: 65, width: 65 }} />
        <Box>
          <Typography className="title">{webConfigJSON.auditCompleted}</Typography>
          <Typography className="content">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolorem</Typography>
        </Box>
      </Tab>
      <Tab onClick={this.gotoAuditListWithCaps}>
        <DescriptionOutlinedIcon style={{ height: 65, width: 65 }} />
        <Box>
          <Typography className="title">{webConfigJSON.openCaps}</Typography>
          <Typography className="content">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolorem</Typography>
        </Box>
      </Tab>
    </DashboardContainer>
  }
  ClientDashboard = () => {
    return <DashboardContainer maxWidth={false}>
      <Heading>{webConfigJSON.dashboard}</Heading>
      <Tab data-test-id="btn-go-to-completed-audit" onClick={() => this.gotoAuditListWithStatus(["Completed"])}>
        <img src={icDocument} alt="" />
        <Box>
          <Typography className="title">{webConfigJSON.completedAudit}</Typography>
          <Typography className="content">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolorem</Typography>
        </Box>
      </Tab>
      <Tab onClick={this.gotoAuditListWithCaps}>
        <ListAltRoundedIcon style={{ height: 65, width: 65 }} />
        <Box>
          <Typography className="title">{webConfigJSON.openCorrectiveActionPlan}</Typography>
          <Typography className="content">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolorem</Typography>
        </Box>
      </Tab>
    </DashboardContainer>
  }
  DisplayUI = () => {
    return this.getUserGroup() === 'evermore' ? this.EvermoreDashboard() : this.ClientDashboard()
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Header navigation={this.props.navigation} >
         {this.state.isLoading ? <CircularProgress style={{margin: 30}} /> : this.DisplayUI()}

        </Header>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const Tab = styled(Box)({
  boxShadow: "0px 4px 4px 0px #00000040",
  background: '#FFFFFF',
  borderRadius: 8,
  padding: '38px 20px 38px 20px',
  display: 'flex',
  cursor: 'pointer',
  "&:hover": {
    background: '#E5E5FF'
  },
  '& img, svg' : {
    marginRight: 10,
    width: 53,
    height: 53
  },
  "& .title" : {
    fontWeight: 700, 
    fontSize: 18, 
    lineHeight: "26px", 
    color: '#0F172A',
    marginBottom: 8
  },
  "& .content": {
    fontSize: 14, 
    fontWeight: 400, 
    lineHeight: '20px',
    color: "#334155"
  }

})
const DashboardContainer = styled(Container)({
  background: "#F1F5F9", 
  paddingTop: 30,
  paddingBottom: 30, 
  display: 'flex',
  flexDirection: 'column',
  gap: 20,
  overflow: 'auto'
})

const Heading = styled(Typography)({
  fontSize: 24,
  lineHeight: "32px",
  fontWeight: 700, 
  color: '#0F172A',
})
// Customizable Area End
