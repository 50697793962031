// Customizable Area Start
import React from "react";

import {
    ThemeProvider,
    Typography,
    createTheme,
    styled,
    Container,
    Grid,
    Box,
    Input,
    InputAdornment,
    IconButton,
    ButtonBase,
    CircularProgress
} from "@material-ui/core";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SearchIcon from '@material-ui/icons/Search';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
const theme = createTheme({
    overrides: {
        MuiTypography: {
            root: {
                fontFamily: "'Montserrat', sans-serif"
            },
            body1: {
                fontFamily: "'Montserrat', sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "'Montserrat', sans-serif !important"
            }
        },
        MuiDialogContent: {
            root: {
                position: "relative"
            }
        }
    }
})


import HeaderController, {
    Props,
} from "./HeaderController";
import { logo } from "./assets";
import Sidebar from "./Sidebar.web";

export default class Header extends HeaderController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return <ThemeProvider theme={theme}>
            <Container maxWidth={false} style={{borderBottom: "1px solid #E2E8F0", height: 80}}>
                <ContainerGrid container>
                    <Grid item md={6} sm={4} xs={3}>
                        <ButtonBase data-test-id="btn-logo" onClick={this.gotoDashboard}>
                            <img className="logo" src={logo} alt="" style={styles.logo} />
                        </ButtonBase>
                    </Grid>
                    <Grid item md={4} sm={5} xs={7} style={{...styles.flexBox, justifyContent: 'end'}} >
                        <CustomInput
                            data-test-id="txtSearch"
                            placeholder={"Search"}
                            disableUnderline
                            startAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        data-test-id="btn-show-hide-password"
                                        aria-label="toggle password visibility"
                                        edge="start"
                                    >
                                        <SearchIcon style={{ color: "#94A3B8" }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            value={this.state.search}
                            onChange={(event) => this.setSearch(event.target.value)}
                            type="text"
                            onKeyDown={this.performSearch}
                              
                        />
                    </Grid>
                    <Grid item md={2} sm={3} xs={2} style={{ ...styles.flexBox, justifyContent: 'end' }}>
                        <UserBox
                            data-test-id="full-name-box"
                            onMouseOver={this.onShowLongText}
                            onMouseOut={this.onShowLongText} style={{ ...styles.flexBox, width: '90%', justifyContent: 'end', position: 'relative' }}>
                            <PermIdentityIcon style={{ marginRight: 10 }} />
                            <TypographyContent
                                className="fullname"
                                style={styles.fullName}>{this.state.userInfo.fullName}</TypographyContent>
                            <FullNameBox className={!this.state.showLongText ?
                                "fullname mini-fullname-none" : "fullname mini-fullname-show"}>
                                <TypographyContent >{this.state.userInfo.fullName}</TypographyContent>
                            </FullNameBox>

                        </UserBox>
                        <CustomMenuRounded data-test-id="btn-menu" onClick={this.toggleOpenDrawer} style={{ marginLeft: 10 }} />
                    </Grid>
                </ContainerGrid>
            </Container>
            {this.state.isLoading ?
                <CircularProgress style={{alignSelf:'center', marginTop: 30}} /> : 
                <MoboBox style={{ display: "flex", }}>
                    <Sidebar userInfo={this.state.userInfo} navigation={this.props.navigation} isOpenDrawer={this.state.isOpenDrawer} toggleOpenDrawer={this.toggleOpenDrawer} />
                    {this.props.children}
                </MoboBox>}
        </ThemeProvider>

    }
}

const styles = {
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    logo:{
        width: 63
    },
    fullName: {
        fontWeight: 700,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    } as React.CSSProperties
}

const UserBox = styled(Box)({
    "@media (max-width: 960px)": {
        display: 'none !important'
    },
})
const MoboBox = styled(Box)({
    flexDirection:'row',
    height: "calc(100vh - 80px)",
    overflow: 'hidden', 
    backgroundColor: '#F1F5F9',
    "@media (max-width: 959px)": {
        flexDirection:'column'
    },
})
const CustomMenuRounded = styled(MenuRoundedIcon)({
    cursor: "pointer",
    "@media (min-width: 960px)": {
        display: 'none'
    },
})

const CustomInput = styled(Input)({
    border: "1px solid #CBD5E1",
    padding: "5px 0",
    borderRadius: 98,
    width: 360, 
    "@media (max-width: 960px)": {
        width: "100%"
    },
})

const ContainerGrid = styled(Grid)({
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    "& .logo": {
        "@media (max-width: 425px)": {
            width: "45px !important",
        }
    }
})

const FullNameBox = styled(Box)({
    '&.mini-fullname-none': {
        display: 'none'
    },

    '&.mini-fullname-show': {
        display: 'block',
        position: 'absolute',
        top: 30,
        right: 10,
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        padding: '5px 10px',
        background: 'white'
    }
})

const TypographyContent = styled(Typography)({
    fontSize: 16,
    lineHeight: "24px",
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 400,
    color: '#0F172A',
    "@media (max-width: 960px)": {
        fontSize: 14,
    }
}) as typeof Typography
// Customizable Area End
