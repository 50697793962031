Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.loginAPiEndPoint2 = "bx_block_login/login";
exports.urlAccountActivation = "account_block/accounts/activate_account"
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.emptyEmailPassword = "Please fill out all required fields before submitting the form"
exports.colorPrimary="#1C3485"
exports.colorError="#DC2626"
exports.btnLogin = "Login"
exports.newMember= "Don’t have an account yet! "
exports.signUp="Create Account"
exports.forgotPassword="Forgot password?"
exports.byLogin = "By login, you agree our "
exports.termsAndCondition = "Terms and Conditions"
exports.wrongPassword="Wrong password"
exports.noAccount = "No account registered with this email"
exports.invalidAccount = "Email is invalid"
exports.isRemember = "remember_me"
exports.refreshToken = "refresh_token"
exports.emptyEmailPassword = "Please fill out all required fields before submitting the form"
exports.notInGroup = "Your account is not associated to a group. Please contact support@evermore.com"
// Customizable Area End