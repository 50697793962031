// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
}

interface S {
    email: string,
    isLoading: boolean,
    isSent: boolean
}

interface SS {
    navigation: any;
}


export default class AccountActivationController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage),
        ];

        this.receive = this.receive.bind(this);

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


        this.state = {
            email: "",
            isLoading: false,
            isSent: false
        };
    }

    async componentDidMount() {
        super.componentDidMount();
        const email = await getStorageData("email")
        email && this.setState({ email }) 
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.send(apiRequestCallId + responseJson)
        }
    }

    gotoLogin = () => {
        this.props.navigation.navigate("EmailAccountLoginBlock")
    }
}
// Customizable Area End
